/*----- Range slider -------*/

.jsr, .jsr_bar, .jsr_label, .jsr_rail, .jsr_rail-outer, .jsr_slider {
	box-sizing: border-box;
}
.jsr {
	position: relative;
	z-index: 1;
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin-inline: 0;
	margin-block-start: 12px;
	margin-block-end: 25px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	font: 14px sans-serif;
}
.jsr_rail-outer {
	position: relative;
	padding: 10px 0;
	cursor: pointer;
}
.jsr_rail {
	height: 12px;
	background: #f9f9f9;
	z-index: 1;
}
.jsr_bar {
	position: absolute;
	height: 12px;
	z-index: 2;
	cursor: move;
}
.jsr_bar--limit {
	background-color: #d9dadc;
	z-index: 1;
	cursor: auto;
}
.jsr_slider {
	position: absolute;
	inset-block-start: calc(12px / 2 + 10px);
	inset-inline-start: 0;
	transform: translate(-50%, -50%);
	width: 25px;
	height: 25px;
	cursor: col-resize;
	transition: background 0.1s ease-in-out;
	outline: 0;
	z-index: 3;
	&::before {
		content: '';
		width: 25px;
		height: 25px;
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		transform: translate(-50%, -50%);
		border-radius: 50%;
	}
}
.jsr_label {
	position: absolute;
	inset-block-start: calc(18px + 5px + 15px / 1.5);
	padding: 0.2em 0.4em;
	background: $secondary;
	color: $white;
	font-size: 0.9em;
	white-space: nowrap;
	border-radius: 0.3em;
	z-index: 2;
}
.jsr_label--minmax {
	z-index: 1;
	color: $white;
	background: $secondary;
	transition: opacity 0.2s ease-in-out;
}
.jsr_label .jsr_label {
	position: static;
	display: inline-block;
	font-size: 1em;
	padding-block: 0;
	padding-inline-end: 0;
	&::before {
		content: ' - ';
	}
}
.jsr_canvas {
	margin-block-start: 5px;
}
.jsr_lockscreen {
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.range {
	display: table;
	position: relative;
	height: 25px;
	margin-block-start: 20px;
	background-color: rgb(245, 245, 245);
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 2px $black-1;
	box-shadow: inset 0 1px 2px $black-1;
	cursor: pointer;
	input[type="range"] {
		-webkit-appearance: none !important;
		-moz-appearance: none !important;
		-ms-appearance: none !important;
		-o-appearance: none !important;
		appearance: none !important;
		display: table-cell;
		width: 100%;
		background-color: transparent;
		height: 25px;
		cursor: pointer;
		outline: none;
		&::-webkit-slider-thumb, &::-moz-slider-thumb {
			-webkit-appearance: none !important;
			-moz-appearance: none !important;
			-ms-appearance: none !important;
			-o-appearance: none !important;
			appearance: none !important;
			width: 11px;
			height: 25px;
			color: rgb(255, 255, 255);
			text-align: center;
			white-space: nowrap;
			vertical-align: baseline;
			border-radius: 0px;
			background-color: rgb(153, 153, 153);
		}
	}
	output {
		display: table-cell;
		padding-inline: 5px;
		padding-block-start: 3px;
		padding-block-end: 2px;
		min-width: 40px;
		color: rgb(255, 255, 255);
		background-color: rgb(153, 153, 153);
		text-align: center;
		text-decoration: none;
		border-radius: 4px;
		border-end-start-radius: 0;
		border-start-start-radius: 0;
		width: 1%;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		transition: all 0.5s ease;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: -moz-none;
		-o-user-select: none;
		user-select: none;
	}
	&.range-success {
		input[type="range"] {
			&::-webkit-slider-thumb, &::-moz-slider-thumb {
				background-color: $green;
			}
		}
		output {
			background-color: $green;
		}
		input[type="range"] {
			outline-color: $green;
		}
	}
	&.range-info {
		input[type="range"] {
			&::-webkit-slider-thumb, &::-moz-slider-thumb {
				background-color: $azure;
			}
		}
		output {
			background-color: $azure;
		}
		input[type="range"] {
			outline-color: $azure;
		}
	}
	&.range-warning {
		input[type="range"] {
			&::-webkit-slider-thumb, &::-moz-slider-thumb {
				background-color: $yellow;
			}
		}
		output {
			background-color: $yellow;
		}
		input[type="range"] {
			outline-color: $yellow;
		}
	}
	&.range-danger {
		input[type="range"] {
			&::-webkit-slider-thumb, &::-moz-slider-thumb {
				background-color: $danger;
			}
		}
		output {
			background-color: $danger;
		}
		input[type="range"] {
			outline-color: $danger;
		}
	}
}
/*----- Range slider -------*/