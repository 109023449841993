@media (min-width: 992px) {
    .horizontal-hover.horizontal {
        .slide {
            &:hover {
                ul.slide-menu {
                    display: block !important;
                }
            }
        }

        .sub-slide {
            &:hover {
                .sub-slide-menu {
                    display: block !important;
                }
            }
        }

        .sub-slide2 {
            &:hover {
                .sub-slide-menu2 {
                    display: block !important;
                }
            }
        }

        .sub-slide.is-expanded .sub-angle {
            transform: none;
        }

    }

    .horizontal {
        .news-ticker {
            padding-inline: 8%;
        }

        &.dark-theme {
            .horizontal-main .slide .slide-menu,
            .horizontal-main .slide .sub-slide-menu {
                background-color: $dark-theme !important;
                border: 1px solid #404353;
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
            }
        }

        .horizontal-main {
            z-index: 7 !important;
            width: 100%;
        }

        &.dark-theme {
            .logo-1 {
                display: none !important;
            }

            .dark-logo-1 {
                display: block !important;
            }
        }

        .hor-header .container,
        .horizontal-main .container,
        .horizontal-content .container {
            max-width: 85% !important;
            padding: 0;
        }

        .header.fixed-header.hor-header {
            position: relative;
        }

        &.scrollable-layout .horizontal-main {
            position: relative;
        }

        .app-sidebar.horizontal-main .side-menu .sub-category {
            display: none;
        }

        .app-sidebar.horizontal-main .side-menu .side-badge {
            display: none;
        }

        .side-menu {
            display: flex;
            overflow: hidden;
            padding: 0;
        }

        .main-sidemenu {
            margin-block-start: 0;
        }

        .main-sidemenu {
            overflow: hidden;
        }

        .side-header {
            display: none;
        }

        .stickyClass .horizontal-main {
            width: 100%;
        }

        .horizontal-main {
            position: relative;
            inset-block-end: initial;

            &.ps {
                overflow: visible !important;
            }

            .slide {
                .slide-menu,
                .sub-slide-menu,
                .sub-slide-menu2 {
                    width: 190px;
                    position: absolute;
                    background-color: $white;
                    z-index: 9999;
                    padding: 5px;
                    min-width: fit-content;
                    box-shadow: 0px 16px 18px rgba(135, 135, 182, 0.01) !important;
                    border: 1px solid #e7eaf3;

                    .slide-item:before {
                        inset-inline-start: -15px;
                        margin-inline-end: 0px;
                    }
                }

                .slide-menu {
                    border-start-start-radius: 0;
                    border-start-end-radius: 0;
                    border-end-end-radius: 5px;
                    border-end-start-radius: 5px;
                }
            }
        }

        .slide-item {
            padding: 8px 35px;
        }

        .fixed-header {
            position: fixed;
        }

        .sub-slide-menu .sub-slide-item2 {
            padding: 8px 20px !important;
        }

        .sub-slide .sub-angle {
            inset-inline-end: -20px;
            inset-inline-start: auto;
            margin-inline-end: 0 !important;
            position: relative;
        }

        .sub-angle2 {
            margin-inline-end: 11px;
        }

        .app-sidebar {
            transition: none;
        }

        .sub-slide .sub-slide-menu {
            position: absolute;
            background-color: $white;
            z-index: 9999;
            box-shadow: 5px 5px 5px #b9b9b9;
            border-radius: 5px;
            padding: 0px;
            min-width: fit-content;
            border: 1px solid #f0f0f8;
            inset-inline-start: 180px;
            inset-block-start: 13px;
        }

        .sub-slide .sub-slide-menu2 {
            position: absolute;
            background-color: $white;
            z-index: 9999;
            box-shadow: 5px 5px 5px #b9b9b9;
            border-radius: 5px;
            padding: 0px;
            min-width: fit-content;
            border: 1px solid #f0f0f8;
            inset-inline-start: 180px;
            inset-block-start: 13px;
        }

        .sub-side-menu__item {
            padding: 8px 35px;
        }

        .side-menu > li > a {
            display: flex;
            margin: -3px -5px !important;
            text-decoration: none;
            position: relative;
            color: #7b8191;
            padding: 16px 16px !important;
        }

        .side-menu > li > a.active {
            color: $primary;
        }

        .main-sidemenu i.angle {
            position: relative;
            inset-block-start: -1px;
            inset-inline-end: -4px;
            transform: rotate(90deg);
            font-size: 15px;
        }

        .main-sidemenu .slide.is-expanded i.angle {
            transform: rotate(-90deg);
            position: relative;
            inset-block-start: 0px;
            font-size: 15px;
        }

        .breadcrumb-header {
            margin-block-start: 20px;
        }

        .side-menu .slide .side-menu__item.active {
            border-inline-end: none;
        }

        .slide {
            margin: 0 3px;
        }

        .logo-horizontal {
            display: block;
        }

        .app-sidebar__toggle {
            display: none;
        }

        .logo-horizontal .header-brand-img.darklogo {
            display: none;
        }

        .logo-horizontal .header-brand-img.main-logo {
            display: block;
            margin: 0 auto;
        }
    }

    .mobile-logo.dark-logo-1 {
        display: none;
    }

    .main-sidemenu .slide-right {
        right: 20px;
    }

    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
        position: absolute;
        inset-block-start: 9px;
        padding: 6px;
        color: $default-color2;
        fill: $default-color2;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid $border;
        border-radius: 50px;
    }

    .main-sidemenu .slide-left {
        left: 23px;
    }

    .horizontal-main {
        position: relative;
        margin: 0 auto;
    }

    .sticky-pin {
        position: fixed;
        inset-block-start: 0;
        width: 100%;
        z-index: 1;
    }
}

.app-sidebar.horizontal-main {
    padding-block-start: 0px;
}

.logo-horizontal {
    display: none;
}

.horizontal {
    @media (max-width: 991.98px) {
        .main-header.hor-header {
            box-shadow: 0px 7px 26px rgba(154, 154, 204, 0.1);
        }

        .main-sidemenu {
            padding: 0;
        }

        .horizontalMenucontainer .main-header.hor-header {
            position: fixed;
        }

        &.app {
            .main-sidemenu {
                .slide-left,
                .slide-right {
                    display: none;
                }
            }
        }

        .app-sidebar {
            width: 250px;
        }
    }

    &.transparent-mode .horizontal-main.fixed-header {
        background-color: $primary;
    }

    @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
        &.transparent-mode .horizontal-main.fixed-header {
            background-color: transparent !important;
            backdrop-filter: blur(20px);
            -webkit-backdrop-filter: blur(20px);
        }
    }

    .leftmenu-styles {
        display: block;
    }
}

.app.sidebar-mini {
    .main-sidemenu {
        .slide-left,
        .slide-right {
            display: none;
        }
    }
}

@media (max-width: 991.98px) {
    .transparent-mode.horizontal {
        .app-sidebar.horizontal-main {
            background: $primary;
        }
    }

    @supports (-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px)) {
        .transparent-mode.horizontal {
            .app-sidebar.horizontal-main {
                background: $black-1 !important;
                -webkit-backdrop-filter: blur(20px);
                backdrop-filter: blur(20px);
            }
        }
    }
}

@media (max-width: 991px) {
    .logo-horizontal {
        display: block;
        inset-inline: 0;
        position: absolute;
        width: inherit;
    }
}

@media (max-width: 991px) {
    .horizontal.app .app-sidebar {
        inset-block-start: 74px;

        .side-menu {
            margin-block-start: 36px !important;
        }
    }

    .hor-angle {
        display: none !important;
    }
}

@media (max-width: 991.98px) {
    .main-content {
        &.horizontal-content {
            margin-block-start: 62px;
        }
    }
}

@media (max-width: 991.98px) {
    .horizontal {
        .hor-header {
            .demo-icon.nav-link.icon {
                margin-block: 0 !important;
            }
        }
        .news-ticker {
            inset-block-start: 73px !important;
            z-index: 98;
        }
    }
}

@media (min-width: 992px) {
    .horizontal .horizontal-main .slide .slide-menu .sub-side-menu__item:before {
        inset-inline-start: -15px;
        margin-inline-end: 0px;
    }

    .horizontal {
        .news-ticker {
            position: inherit;
        }
        .header {
            border-block-end: none;
        }
        .mega-menu {
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            ul {
                display: block !important;
            }
        }

        .mega-slide-menu {
            width: auto !important;
            inset-inline-start: 8%;
            inset-inline-end: 0px;
            max-width: 84% !important;
        }
    }
}

.mega-menu {
    grid-template-columns: 1fr;

    ul {
        display: block !important;
    }
}

.slide.is-expanded .slide-menu.mega-slide-menu {
    display: block !important;
}

@media (min-width: 992px) {
    .horizontal-hover .side-menu__item:not(.has-link),
    .horizontal-hover .sub-side-menu__item:not(.has-link),
    .horizontal-hover .sub-side-menu__item2:not(.has-link) {
        pointer-events: none;
        cursor: pointer;
    }
}

@media (min-width: 1400px) {
    .horizontal.layout-boxed .stickyClass .app-sidebar {
        max-width: 1400px;
    }
}
@media (min-width: 1400px) {
    // .horizontal.layout-boxed .news-ticker {
    //     padding-inline: 3%;
    // }
    .horizontal.layout-boxed.side-shadow {
        .news-ticker {
            padding-inline: 3%;
        }
        .horizontal-content {
            margin-block-start: 0px;
        }
        .stickyClass {
            .app-sidebar {
                margin-block-start: 0;
            }
        }
    }
}

@media (min-width: 992px) {
    .horizontal .horizontal-switcher {
        display: block;
    }
}

.swichermainleft {
    width: 100%;
    float: left;
}

.horizontal-switcher {
    display: none;
}

@media (min-width: 992px) {
    .horizontal.center-logo {
        .logo-horizontal {
            display: block;
            position: absolute;
            inset-inline: 0;
            margin: 0 auto;
            text-align: center;
            .header-brand-img {
                .main-logo {
                    margin: 0 auto;
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .horizontal .stickyClass {
        .horizontal-main {
            margin-block-start: 0;
            border-block-start: 1px solid $border;
            width: 100%;
        }
    }
}

@media (max-width: 991.98px) {
    .horizontal .main-content .side-app {
        padding: 0 0.75rem;
    }

    .horizontal.scrollable-layout {
        .header.hor-header {
            position: relative !important;
            inset-block-start: 1px;
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1150px) {
    .horizontal.center-logo {
        .header-right-icons .nav-link.icon {
            margin: 5px 0px !important;
        }
    }
}

.horizontal {
    &.layout-boxed.scrollable-layout {
        .news-ticker {
            inset-block-start: 0;
        }
    }

    &.scrollable-layout.side-shadow {
        .news-ticker {
            inset-block-start: 0;
        }
    }
}

@media (max-width: 991.98px) {
    .horizontal {
        &.scrollable-layout {
            .main-content.horizontal-content {
                margin-block-start: 0;
            }

            .news-ticker {
                inset-block-start: 0 !important;
            }

            .page-header {
                margin: 0 !important;
            }
        }

        &.layout-boxed.scrollable-layout {
            .main-content.horizontal-content {
                margin-block-start: 0 !important;
            }

            .news-ticker {
                inset-block-start: 0 !important;
            }

            .page-header {
                margin: 0 !important;
            }
        }
    }
}