/*-----Rating Stars-----*/

.rating-stars {
	width: 100%;
	text-align: center;

	.rating-stars-container {
		font-size: 0px;

		.rating-star {
			display: inline-block;
			font-size: 32px;
			cursor: pointer;
			padding: 5px 10px;
			color: #f9f9f9;

			&.sm {
				display: inline-block;
				font-size: 14px;
				color: rgba(107, 122, 144, 0.3);
				cursor: pointer;
				padding: 2px;
			}

			&.is--no-hover,
			.fa-heart .is--no-hover {
				color: #7a5cab;
			}
		}
	}

	input {
		display: block;
		margin: 0 auto;
		text-align: center;
		padding: 0.375rem 0.75rem;
		font-size: 0.9375rem;
		line-height: 1.6;
		color: #495057;
		background-color: $white;
		background-clip: padding-box;
		border: 1px solid rgba(107, 122, 144, 0.3);
		border-radius: 3px;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
}

.br-theme-bars-horizontal .br-widget {
	a {
		background-color: #e5e9f2;

		&.br-active,
		&.br-selected {
			background-color: #5e2dd8;
		}
	}

	.br-current-rating {
		color: #5e2dd8;
	}
}

.br-theme-bars-pill .br-widget a {
	background-color: #e5e9f2;

	&.br-active,
	&.br-selected {
		background-color: #5e2dd8;
		color: white;
	}

	color: #5e2dd8;
}

.br-theme-bars-movie .br-widget {
	a {

		&.br-active,
		&.br-selected {
			background-color: #5e2dd8;
		}

		background-color: #e5e9f2;
	}

	.br-current-rating {
		color: #5e2dd8;
	}
}

.br-theme-bars-1to10 .br-widget {
	a {
		background-color: #e5e9f2;

		&.br-active,
		&.br-selected {
			background-color: #5e2dd8;
		}
	}

	.br-current-rating {
		color: #5e2dd8;
	}
}

.br-theme-fontawesome-stars .br-widget a {

	&.br-active:after,
	&.br-selected:after {
		color: #f1c40f;
	}
}

.br-theme-fontawesome-stars-o .br-widget a {

	&.br-active:after,
	&.br-selected:after {
		content: '\f005';
		color: #f1c40f;
	}

	&.br-fractional:after {
		content: '\f123';
		color: #f1c40f;
	}
}

.br-theme-fontawesome-stars .br-widget a:after {
	content: '\f005';
	color: #e5e9f2;
}

.br-theme-fontawesome-stars-o .br-widget a:after {
	content: '\f006';
	color: #e5e9f2;
}

.br-theme-fontawesome-stars .br-widget a {
	font: normal normal normal 20px/1 FontAwesome;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	text-decoration: none;
	margin-inline-end: 2px;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
	color: $primary  !important;
}

.br-theme-bars-square .br-widget a {
	border: 2px solid $primary;
	background-color: $white;
	color: #e5e9f2;
}

.br-theme-bars-square .br-widget a.br-active,
.br-theme-bars-square .br-widget a.br-selected {
	color: $primary  !important;
}

.rating-stars i {
	font-size: 20px;
	padding: 2px;
}

.rating-star.sm.is--active {
	i {
		font-size: 15px;
	}
}

.rating-star.sm {
	i {
		font-size: 15px;
	}
}

.rating-stars .rating-stars-container .rating-star {

	&.is--active,
	&.is--hover,
	&.is--active .fa-heart,
	&.is--hover .fa-heart {
		color: $warning;
	}
}


/*-----Rating Stars-----*/