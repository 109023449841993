/*------Height-------*/

h1, h2, h3, h4, h5, h6 {
	margin-block-start: 0;
	margin-block-end: 0.66em;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
	margin-block-end: 0.66em;
	font-weight: 400;
	line-height: 1.1;
	color: inherit;
}
h1, .h1 {
	font-size: 2rem;
}
h2, .h2 {
	font-size: 1.75rem;
}
h3, .h3 {
	font-size: 1.5rem;
}
h4, .h4 {
	font-size: 1.125rem;
}
h5, .h5 {
	font-size: 1rem;
}
h6, .h6 {
	font-size: 0.875rem;
}
.h-25 {
	height: 25% !important;
}
.h-50 {
	height: 50% !important;
}
.h-75 {
	height: 75% !important;
}
.h-100 {
	height: 100% !important;
}
.h-auto {
	height: auto !important;
}
.h-0 {
	height: 0 !important;
}
.h-1 {
	height: 0.25rem !important;
}
.h-2 {
	height: 0.5rem !important;
}
.h-3 {
	height: 0.75rem !important;
}
.h-4 {
	height: 1rem !important;
}
.h-5 {
	height: 1.5rem !important;
}
.h-6 {
	height: 2rem !important;
}
.h-7 {
	height: 3rem !important;
}
.h-8 {
	height: 4rem !important;
}
.h-9 {
	height: 6rem !important;
}
.h-400 {
	height: 400px !important;
}
.h-320 {
	height: 320px !important;
}
.h-330 {
	height: 330px !important;
}
.h-350 {
	height: 350px !important;
}
.h-150 {
	height: 150px !important;
}
.h-200 {
	height: 200px !important;
}
.h-210 {
	height: 210px !important;
}
.h-250 {
	height: 250px !important;
}
.h-220 {
	height: 220px !important;
}
.h-225 {
	height: 225px !important;
}
.h-230 {
	height: 230px !important;
}
.wh100 {
	width: 100px;
	height: 100px;
}
.h-300 {
	height: 300px !important;
}
.mh-100 {
	max-height: 100% !important;
}
.mn-auto {
	min-height: auto;
}
.mapheight {
	height: 400px;
}
/*------Height-------*/