/*------Border Radius -------*/

.br-2 {
	border-radius: 2px;
}
.br-7 {
	border-radius: 4px;
}

.br-10 {
	border-radius: 10px;
}

.br-100 {
	border-radius: 100% !important;
}

.br-tl-0 {
	border-start-start-radius: 0px !important;
}

.br-tr-0 {
	border-start-end-radius: 0px !important;
}

.br-bl-0 {
	border-end-start-radius: 0px !important;
}

.br-br-0 {
	border-end-end-radius: 0px !important;
}
.br-tl-2 {
	border-start-start-radius: 2px !important;
}

.br-tr-2 {
	border-start-end-radius: 2px !important;
}

.br-bl-2 {
	border-end-start-radius: 2px !important;
}

.br-br-2 {
	border-end-end-radius: 2px !important;
}
.br-tl-5 {
	border-start-start-radius: 5px !important;
}

.br-tr-5 {
	border-start-end-radius: 5px !important;
}

.br-bl-5 {
	border-end-start-radius: 5px !important;
}

.br-br-5 {
	border-end-end-radius: 5px !important;
}

.br-tl-7 {
	border-start-start-radius: 4px !important;
}

.br-bl-7 {
	border-end-start-radius: 4px !important;
}

.br-tr-7 {
	border-start-end-radius: 4px !important;
}

.br-br-7 {
	border-end-end-radius: 4px !important;
}

.rounded-top {
	border-start-start-radius: 3px !important;
	border-start-end-radius: 3px !important;
}

.rounded-end {
	border-start-end-radius: 3px !important;
	border-end-end-radius: 3px !important;
}

.rounded-bottom {
	border-end-end-radius: 3px !important;
	border-end-start-radius: 3px !important;
}

.rounded-start {
	border-start-start-radius: 3px !important;
	border-end-start-radius: 3px !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.noborder {
	border-radius: 0;
}

.brround {
	border-radius: 50%;
}

.bradius {
	border-radius: 25%;
}

/*------Border Radius -------*/

/*------Borders -------*/

.border {
	border: 1px solid $border  !important;
}

.border-top {
	border-block-start: 1px solid $border  !important;
}

.border-bottom {
	border-block-end: 1px solid $border  !important;
}

.border-start {
	border-inline-start: 1px solid $border  !important;
}

.border-end {
	border-inline-end: 1px solid $border  !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-block-start: 0 !important;
}

.border-right-0 {
	border-inline-end: 0 !important;
}

.border-bottom-0 {
	border-block-end: 0px solid #fff0 !important;
}

.border-left-0 {
	border-inline-start: 0 !important;
}

.border-success {
	border-color: $green  !important;
}

.border-info {
	border-color: $azure  !important;
}

.border-warning {
	border-color: $yellow  !important;
}

.border-danger {
	border-color: $danger  !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: $dark  !important;
}

.border-white {
	border-color: $white  !important;
}

.border-primary {
	border-color: $primary  !important;
}

.border-secondary {
	border-color: $secondary  !important;
}

/*------Borders -------*/