/*------Input Group-------*/

.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
}

.input-group-addon {
	padding: .5rem .75rem;
	margin-block-end: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25;
	text-align: center;
	border: 1px solid $border;
	border-inline-end: 0;
	border-start-start-radius: 0.25rem;
	border-start-end-radius: 0;
	border-end-end-radius: 0;
	border-end-start-radius: 0.25rem;
}

.input-group> {

	.form-control,
	.form-select,
	.form-file {
		position: relative;
		-ms-flex: 1 1 auto;
		flex: 1 1 auto;
		width: 1%;
		margin-block-end: 0;
	}

	.form-control:focus,
	.form-select:focus,
	.form-file:focus {
		z-index: 3;
	}

	.form-control+ {

		.form-control,
		.form-select,
		.form-file {
			margin-inline-start: -1px;
		}
	}

	.form-select+ {

		.form-control,
		.form-select,
		.form-file {
			margin-inline-start: -1px;
		}
	}

	.form-file+ {

		.form-control,
		.form-select,
		.form-file {
			margin-inline-start: -1px;
		}
	}

	.form-control:not(:last-child),
	.form-select:not(:last-child) {
		border-start-end-radius: 0;
		border-end-end-radius: 0;
	}

	.form-control:not(:first-child),
	.form-select:not(:first-child) {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
	}

	.form-file {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-align: center;
		align-items: center;

		&:not(:last-child) .form-file-label {
			border-start-end-radius: 0;
			border-end-end-radius: 0;

			&::after {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}
		}

		&:not(:first-child) .form-file-label {
			border-start-start-radius: 0;
			border-end-start-radius: 0;

			&::after {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}
	}
}

.input-group-text,
.input-group-text {
	display: -ms-flexbox;
	display: flex;
}

.input-group-text .btn,
.input-group-text .btn {
	position: relative;
	z-index: 2;
}

.input-group-text {
	.btn+ {

		.btn,
		.input-group-text {
			margin-inline-start: -1px;
		}
	}

	.input-group-text+ {

		.input-group-text,
		.btn {
			margin-inline-start: -1px;
		}
	}
}

.input-group-text {
	.btn+ {

		.btn,
		.input-group-text {
			margin-inline-start: -1px;
		}
	}

	.input-group-text+ {

		.input-group-text,
		.btn {
			margin-inline-start: -1px;
		}
	}
}

.input-group-text {
	margin-block-start: -1px;
}

.input-group-text {
	margin-inline-start: -1px;
}

.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-block-end: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 1.6;
	color: #86909a;
	text-align: center;
	white-space: nowrap;
	background-color: #f9fafb;
	border: 1px solid $border;

	input {

		&[type="radio"],
		&[type="checkbox"] {
			margin-block-start: 0;
		}
	}
}

.input-group> {
	.input-group-text> {

		.btn,
		.input-group-text {
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}
	}

	.input-group-text {
		&:not(:last-child)> {

			.btn,
			.input-group-text {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}
		}

		&:last-child> {

			.btn:not(:last-child):not(.dropdown-toggle),
			.input-group-text:not(:last-child) {
				border-start-end-radius: 0;
				border-end-end-radius: 0;
			}
		}

		> {

			.btn,
			.input-group-text {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}
	}

	.input-group-text {
		&:not(:first-child)> {

			.btn,
			.input-group-text {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}

		&:first-child> {

			.btn:not(:first-child),
			.input-group-text:not(:first-child) {
				border-start-start-radius: 0;
				border-end-start-radius: 0;
			}
		}
	}
}

.input-group-text,
.input-group-text,
.input-group-btn {
	font-size: 0.9375rem;
}

.input-group-text>.btn,
.input-group-text>.btn,
.input-group-btn>.btn {
	height: 100%;
	border-color: rgba(0, 40, 100, 0.12);
}

.input-group-text>.input-group-text {
	border-inline-end: 0;
}

.input-group-text>.input-group-text {
	border-inline-start: 0;
}

.input-group-sm> {

	.form-control-plaintext.form-control,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.btn,
	.input-group-text>.form-control-plaintext.btn {
		padding-inline-start: 0;
	}

	select.form-control:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}

	.form-control,
	.input-group-text>.input-group-text,
	.input-group-text>.input-group-text,
	.input-group-text>.btn,
	.input-group-text>.btn {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.14285714;
	}
}

.input-group-lg> {

	.form-control-plaintext.form-control,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.btn,
	.input-group-text>.form-control-plaintext.btn {
		padding-inline: 0;
	}

	.form-control,
	.input-group-text>.input-group-text,
	.input-group-text>.input-group-text,
	.input-group-text>.btn,
	.input-group-text>.btn {
		padding: 0.5rem 1rem;
		font-size: 1.125rem;
		line-height: 1.44444444;
	}

	select.form-control:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]) {
		height: calc(2.6875rem + 2px);
	}
}

/*------Input Group-------*/