/*-----Lists-----*/

.list-unstyled {
	padding-inline-start: 0;
	list-style: none;

	li {
		border-block-end: 1px solid $border;
	}
}

.list-inline {
	padding-inline-start: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-inline-end: 0.5rem;
	}
}

.list-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-inline-start: 0;
	margin-block-end: 0;
}

.list-group-item-action {
	width: 100%;
	color: #6b6f80;
	text-align: inherit;

	&:hover,
	&:focus {
		color: #6b6f80;
		text-decoration: none;
		background-color: #f9f9f9;
	}

	&:active {
		color: #6b6f80;
		background-color: #f9f9f9;
	}
}

.list-group-transparent.file-manager.file-manager-border .list-group-item {
	border: 1px solid $border  !important;
	border-radius: 3px !important;
}

.list-group-item {
	position: relative;
	display: block;
	padding: 0.75rem;
	margin-block-end: -1px;
	color: $default-color;
	background-color: $white;
	border: 1px solid $border;
}

.listorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: decimal;
	list-style-position: inside;
}

.listorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: upper-alpha;
	list-style-position: inside;
}

.listunorder {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: circle;
	list-style-position: inside;
}

.listunorder1 {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-block-end: -1px;
	background-color: $white;
	border: 1px solid $border;
	list-style-type: square;
	list-style-position: inside;
}

.list-group-item {
	&:last-child {
		margin-block-end: 0;
	}

	&:hover,
	&:focus {
		// z-index: 1;
		text-decoration: none;
	}

	&.disabled,
	&:disabled {
		color: #7c90b1;
		background-color: $white;
	}

	&.active {
		z-index: 2;
		background-color: $background;
		color: #6b6f80;
		border: 1px solid $border;
	}
}

.list-group-flush {
	.list-group-item {
		border-inline: 0 !important;
		border-radius: 0;
	}

	&:first-child .list-group-item:first-child {
		border-block-start: 0;
	}

	&:last-child .list-group-item:last-child {
		border-block-end: 0;
	}
}

.list-group-item-primary {
	color: $primary;
	background-color: #cbdbf2;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #24426c;
			background-color: #b7cded;
		}

		&.active {
			color: $white;
			background-color: #24426c;
			border-color: #24426c;
		}
	}
}

.list-group-item-secondary {
	color: $secondary;
	background-color: #dddfe2;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #464a4e;
			background-color: #cfd2d6;
		}

		&.active {
			color: $white;
			background-color: #464a4e;
			border-color: #464a4e;
		}
	}
}

.list-group-item-success {
	color: $success !important;
	background-color: #b6f1ce !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #316100;
			background-color: #c5e7a4;
		}

		&.active {
			color: $white;
			background-color: #316100;
			border-color: #316100;
		}
	}
}

.list-group-item-info {
	color: $info !important;
	background-color: #b6ecf9 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #24587e;
			background-color: #b3dcf9;
		}

		&.active {
			color: $white;
			background-color: #24587e;
			border-color: #24587e;
		}
	}
}

.list-group-item-warning {
	color: $warning !important;
	background-color: #fbeebc !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #7d6608;
			background-color: #fae8a4;
		}

		&.active {
			color: $white;
			background-color: #7d6608;
			border-color: #7d6608;
		}
	}
}

.list-group-item-danger {
	color: $danger !important;
	background-color: #ffbec1 !important;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #6b1110;
			background-color: #ecacab;
		}

		&.active {
			color: $white;
			background-color: #6b1110;
			border-color: #6b1110;
		}
	}
}

.list-group-item-light {
	color: #818182;
	background-color: #fdfdfe;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #818182;
			background-color: #ececf6;
		}

		&.active {
			color: $white;
			background-color: #818182;
			border-color: #818182;
		}
	}
}

.list-group-item-dark {
	color: $default-color;
	background-color: #c6c8ca;

	&.list-group-item-action {

		&:hover,
		&:focus {
			color: #1b1e21;
			background-color: #b9bbbe;
		}

		&.active {
			color: $white;
			background-color: #1b1e21;
			border-color: #1b1e21;
		}
	}
}

.list-inline-dots .list-inline-item+.list-inline-item:before {
	content: '· ';
	margin-inline-start: -2px;
	margin-inline-end: 3px;
}

.list-separated-item {
	padding: 1rem 0;

	&:first-child {
		padding-block-start: 0;
	}
}

.list-group-item {
	&.active .icon {
		color: inherit !important;
	}

	.icon {
		color: #a7a8c6 !important;
	}
}

.list-group-transparent .list-group-item {
	background: none;
	border: 0;
	padding: .5rem 1rem;
	border-radius: 5px;

	&.active {
		background: rgba(70, 127, 207, 0.1);
		font-weight: 600;
		color: #212529;
		border: 0px solid $border;
	}
}

.list-group.lg-alt .list-group-item {
	border: 0;
}

.list-group-flush>.list-group-item {
	border-width: 1px;
}

.projects-list .list-group-item {
	border-block-start-width: 1px !important;
	border-inline-start-width: 0;
	border-inline-end: 0;
}

.projects-list .list-group-item:last-child {
	border-block-end-width: 0;
}

.list-group-item+.list-group-item {
	border-block-start-width: 1px;
}

/*-----Lists-----*/