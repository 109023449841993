/*------ Buttons ------*/
button {
	border-radius: 0;

	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid transparent;
	padding: 0.185rem 0.65rem;
	font-size: 0.9375rem;
	line-height: 1.84615385;
	box-shadow: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	letter-spacing: .03em;
	font-size: 0.9125rem;
	border-radius: 0.3125rem;
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}

	&:focus {
		text-decoration: none;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
	}

	&.focus {
		outline: 0;
		box-shadow: 0 0 0 2px rgba(194, 197, 199, 0.25);
	}

	&.disabled,
	&:disabled {
		opacity: 0.65;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;

		&:active,
		&.active {
			background-image: none;
		}
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

a.btn.disabled,
fieldset:disabled a.btn {
	pointer-events: none;
}

.btn-default {
	background-color: $background;
	border-color: $background;
	color: $default-color;

	&:hover {
		background-color: #f9f8fd;
		border-color: $background;
		color: $default-color;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(96, 82, 159, 0.5);
	}

	&.disabled,
	&:disabled {
		background-color: $background;
		border-color: $background;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			background-color: $background;
			border-color: $background;
		}
	}
}

.show>.btn-default.dropdown-toggle {
	background-color: $background;
	border-color: $background;
}

.btn-default:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(96, 82, 159, 0.1);
	}
}

.show>.btn-default.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(96, 82, 159, 0.1);
}

.btn-primary {
	color: $white !important;
	background-color: $primary  !important;
	border-color: $primary;

	&:hover {
		color: $white;
		background-color: #2d6cc5 !important;
		border-color: $primary-04;
	}

	&:focus,
	&.focus {
		border-color: $primary-04;
		box-shadow: 0 0 0 2px rgba(59, 175, 253, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: #397cdb;
		}
	}
}

.btn-outline-primary {
	color: $primary;
	background-color: $white;
	background-image: none;
	border-color: $primary  !important;

	&:hover {
		color: $white !important;
		background-color: $primary;
		border-color: $primary;

		.bg-primary {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $primary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $primary;
			border-color: $primary;
		}
	}

	&.dropdown-toggle {
		&::after {
			color: $primary;
		}
		&:focus {
			color: $white;
		}
	}
}

.btn-secondary {
	color: $white !important;
	background-color: $secondary  !important;
	border-color: $secondary;

	&:hover {
		color: $white;
		background-color: #55a206 !important;
		border-color: #55a206;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #55a206;
		border-color: #55a206;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #55a206;
			border-color: #55a206;
		}
	}
}

.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary;

	&:hover {
		color: $white !important;
		background-color: $secondary;
		border-color: $secondary;

		.bg-secondary {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $secondary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
	&.dropdown-toggle {
		&::after {
			color: $secondary;
		}
	}
}

.btn-success {
	color: $white  !important;
	background-color: $success  !important;
	border-color: $success;

	&:hover {
		color: $white;
		background-color: #0e9946 !important;
		border-color: #0a9943 !important;
	}

	&:focus,
	&.focus {
		color: $white;
		background-color: #22c865;
		border-color: #22c865;
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $green;
		border-color: $green;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #05883a;
			border-color: #05883a;
		}
	}
}

.btn-secondary.focus,
.btn-secondary:focus {
	color: $white;
	background-color: #55a206;
	border-color: #55a206;
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.show>.btn-success.dropdown-toggle {
	color: $white;
	background-color: #05883a;
	border-color: #05883a;
}

.btn-success:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-info {
	color: $white !important;
	background-color: $info  !important;
	border-color: $info;

	&:hover {
		color: $white;
		background-color: #14a5c9 !important;
		border-color: #14a5c9 !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(40, 175, 208, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $info;
		border-color: $info;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $info;
			border-color: $info;
		}
	}
}

.show>.btn-info.dropdown-toggle {
	color: $white;
	background-color: $info;
	border-color: $info;
}

.btn-info:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(40, 175, 208, 0.1);
	}
}

.show>.btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(40, 175, 208, 0.1);
}

.btn-warning {
	color: $white;
	background-color: $warning  !important;
	border-color: $warning  !important;

	&:hover {
		color: $white;
		background-color: #d5a939 !important;
		border-color: #d5a939 !important;
	}

	&:focus,
	&.focus {
		color: $white;
		box-shadow: 0 0 0 2px rgba(255, 204, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $yellow;
		border-color: $yellow;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $warning;
			border-color: $warning;
		}
	}
}

.show>.btn-warning.dropdown-toggle {
	color: $white;
	background-color: $warning;
	border-color: $warning;
}

.btn-warning:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 204, 0, 0.1);
	}
}

.show>.btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 204, 0, 0.1);
}

.btn-danger {
	color: $white;
	background-color: $danger  !important;
	border-color: $danger  !important;

	&:hover {
		color: $white;
		background-color: #b63039 !important;
		border-color: #b32d36 !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #d6434c;
		border-color: #d6434c;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #d6434c;
			border-color: #d6434c;
		}
	}
}

.show>.btn-danger.dropdown-toggle {
	color: $white;
	background-color: #d6434c;
	border-color: #d6434c;
}

.btn-danger:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
	}
}

.show>.btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(250, 98, 107, 0.1);
}

.btn-light {
	color: $default-color;
	background-color: $background;
	border-color: $background;

	&:hover {
		color: $default-color;
		background-color: #e2e6ea;
		border-color: #dae0e5;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $default-color;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $default-color;
			background-color: $background;
			border-color: #d3d9df;
		}
	}
}

.show>.btn-light.dropdown-toggle {
	color: $default-color;
	background-color: $background;
	border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}

.show>.btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.btn-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;

	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}

.show>.btn-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}
}

.show>.btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
}

.btn-gradient-success {
	color: $white;
	background-image: linear-gradient(to bottom right, #63d457 0, #3cbf2d 100%);
	border-color: $green;

	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #448700;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $green;
		border-color: $green;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $success;
			border-color: $success;
		}
	}
}

.show>.btn-gradient-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.btn-gradient-success:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-gradient-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-outline-purple {
	color: $purple;
	background-color: $white;
	background-image: none;
	border-color: #fcfbff;

	&:hover {
		color: $white;
		background-color: $purple;
		border-color: $purple;
	}
}

.btn-outline-default {
	color: $default-color;
	background-color: $white;
	background-image: none;
	border-color: $border;

	&:hover {
		background-color: #eff0f6;
		border-color: #eff0f6;
	}

	&.disabled,
	&:disabled {
		color: $default-color;
		background-color: transparent;
		border: 1px solid #d2d7dd;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			background-color: $white;
			border-color: $background;
		}
	}
}

.show>.btn-outline-default.dropdown-toggle {
	background-color: $white;
	border-color: $background;
}

.btn-outline-success {
	color: $success;
	background-color: transparent;
	background-image: none;
	border-color: $success;

	&:hover {
		color: $white !important;
		background-color: $success;
		border-color: $success;

		.bg-success {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $success;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $success;
			border-color: $success;
		}
	}
	&.dropdown-toggle {
		&::after {
			color: $success;
		}
		&:focus {
			color: $white;
		}
	}
}

.show>.btn-outline-success.dropdown-toggle {
	color: $white;
	background-color: $success;
	border-color: $success;
}

.btn-outline-success:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-outline-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-outline-orange {
	border: 1px solid $orange  !important;
	color: $orange  !important;

	&:hover {
		color: $white !important;
		background-color: $orange  !important;
	}
}

.btn-outline-cyan {
	border: 1px solid $cyan  !important;
	color: $cyan  !important;

	&:hover {
		color: $white !important;
		background-color: $cyan  !important;
	}
}

.btn-outline-info {
	color: $azure;
	background-color: transparent;
	background-image: none;
	border-color: $azure;

	&:hover {
		color: $white !important;
		background-color: $azure;
		border-color: $azure;

		.bg-info {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $azure;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $azure;
			border-color: $azure;
		}
	}
	&.dropdown-toggle {
		&::after {
			color: $info;
		}
		&:focus {
			color: $white;
		}
	}
}

.show>.btn-outline-info.dropdown-toggle {
	color: $white;
	background-color: $azure;
	border-color: $azure;
}

.btn-outline-info:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
	}
}

.show>.btn-outline-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.1);
}

.btn-outline-warning {
	color: $yellow;
	background-color: transparent;
	background-image: none;
	border-color: $yellow;

	&:hover {
		color: $white !important;
		background-color: $yellow;
		border-color: $yellow;

		.bg-warning {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $yellow;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $yellow;
			border-color: $yellow;
		}
	}
	&.dropdown-toggle {
		&::after {
			color: $warning;
		}
		&:focus {
			color: $white;
		}
	}
}

.show>.btn-outline-warning.dropdown-toggle {
	color: $white;
	background-color: $yellow;
	border-color: $yellow;
}

.btn-outline-warning:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
	}
}

.show>.btn-outline-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.btn-outline-danger {
	color: $danger;
	background-color: transparent;
	background-image: none;
	border-color: $danger;

	&:hover {
		color: $white !important;
		background-color: $danger;
		border-color: $danger;

		.bg-danger {
			background-color: $white !important;
			color: $black !important;
		}
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $danger;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $danger;
			border-color: $danger;
		}
	}
	&.dropdown-toggle {
		&::after {
			color: $danger;
		}
		&:focus {
			color: $white;
		}
	}
}

.show>.btn-outline-danger.dropdown-toggle {
	color: $white;
	background-color: $danger;
	border-color: $danger;
}

.btn-outline-danger:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
	}
}

.show>.btn-outline-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.1);
}

.btn-outline-light {
	color: $default-color;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;

	&:hover {
		color: $default-color;
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}

	&.disabled,
	&:disabled {
		color: #f8f9fa;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $default-color;
			background-color: #f8f9fa;
			border-color: #f8f9fa;
		}
	}
}

.show>.btn-outline-light.dropdown-toggle {
	color: $default-color;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
	}
}

.show>.btn-outline-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.btn-outline-dark {
	color: $dark;
	background-color: transparent;
	background-image: none;
	border-color: $dark;

	&:hover {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $dark;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $dark;
			border-color: $dark;
		}
	}
}

.show>.btn-outline-dark.dropdown-toggle {
	color: $white;
	background-color: $dark;
	border-color: $dark;
}

.btn-outline-white {
	color: #4e4c73;
	background-color: transparent;
	background-image: none;
	border-color: $white;

	&:hover {
		color: #4e4c73;
		background-color: $white;
		border-color: $white;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.1);
	}

	&.disabled,
	&:disabled {
		color: #4e4c73;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: #4e4c73;
			background-color: $white;
			border-color: $white;
		}
	}
}

.show>.btn-outline-white.dropdown-toggle {
	color: #4e4c73;
	background-color: $white;
	border-color: $white;
}

.btn-outline-white:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(225, 225, 225, 0.1);
	}
}

.show>.btn-outline-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(225, 225, 225, 0.1);
}

.btn-link {
	font-weight: 400;
	background-color: transparent;

	&:hover {
		color: #295a9f;
		text-decoration: underline;
		background-color: transparent;
		border-color: transparent;
	}

	&:focus,
	&.focus {
		text-decoration: underline;
		border-color: transparent;
		box-shadow: none;
	}

	&:disabled,
	&.disabled {
		color: $gray;
		pointer-events: none;
	}
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 0.5rem 1rem;
	font-size: 1rem;
	line-height: 1.625;
	min-width: 2.75rem;
	font-weight: 400;
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 0.45rem 0.5rem !important;
	font-size: 0.711rem !important;
	min-width: 1.625rem !important;
	line-height: 1;
	border-radius: 0.21rem;
}

.btn-block {
	display: block;
	width: 100%;

	+.btn-block {
		margin-block-start: 0.5rem;
	}
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
	position: relative;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
}

.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
	z-index: 1;
}

.btn-group>.btn {

	&:focus,
	&:active,
	&.active {
		z-index: 1;
	}
}

.btn-group-vertical>.btn {

	&:focus,
	&:active,
	&.active {
		z-index: 1;
	}
}

.btn-group {
	.btn+ {

		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}

	.btn-group+ {

		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
}

.btn-group-vertical {
	.btn+ {

		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}

	.btn-group+ {

		.btn,
		.btn-group {
			margin-inline-start: -1px;
		}
	}
}

.btn-toolbar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: 10px;

	.input-group {
		width: auto;
	}
}

.btn-group> {
	.btn {
		&:first-child {
			margin-inline-start: 0;
		}

		&:not(:last-child):not(.dropdown-toggle) {
			border-start-end-radius: 0;
			border-end-end-radius: 0;
		}
	}

	.btn-group:not(:last-child)>.btn {
		border-start-end-radius: 0;
		border-end-end-radius: 0;
	}

	.btn:not(:first-child),
	.btn-group:not(:first-child)>.btn {
		border-start-start-radius: 0;
		border-end-start-radius: 0;
	}
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
	padding-inline: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
	padding-inline: 0.75rem;
}

.btn-group-vertical {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-align: start;
	align-items: flex-start;
	-ms-flex-pack: center;
	justify-content: center;

	.btn,
	.btn-group {
		width: 100%;
	}

	> {
		.btn+ {

			.btn,
			.btn-group {
				margin-block-start: -1px;
				margin-inline-start: 0;
			}
		}

		.btn-group+ {

			.btn,
			.btn-group {
				margin-block-start: -1px;
				margin-inline-start: 0;
			}
		}

		.btn:not(:last-child):not(.dropdown-toggle),
		.btn-group:not(:last-child)>.btn {
			border-end-end-radius: 0;
			border-end-start-radius: 0;
		}

		.btn:not(:first-child),
		.btn-group:not(:first-child)>.btn {
			border-start-start-radius: 0;
			border-start-end-radius: 0;
		}
	}
}

.btn-group-toggle> {

	.btn,
	.btn-group>.btn {
		margin-block-end: 0;
	}

	.btn input {

		&[type="radio"],
		&[type="checkbox"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}

	.btn-group>.btn input {

		&[type="radio"],
		&[type="checkbox"] {
			position: absolute;
			clip: rect(0, 0, 0, 0);
			pointer-events: none;
		}
	}
}

.btn-send {
	background: none repeat scroll 0 0 #00a8b3;
	color: $white;

	&:hover {
		background: none repeat scroll 0 0 #00a8b3;
		color: $white;
		background: none repeat scroll 0 0 #009da7;
	}
}

button.btn-close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
}

.btn {
	i {
		font-size: 0.8125rem;
	}

	&.btn-sm i {
		font-size: 11px;
	}

	&.btn-lg i {
		font-size: 13px;
	}
}

.btn-icon {
	// padding-inline: .5rem;
	text-align: center;
}

.btn-pill {
	border-radius: 10rem;
	padding-inline: 0.7em;

	&:active {
		border-radius: 10rem;
	}

	&.dropdown-toggle {
		&:active {
			border-start-end-radius: 10rem !important;
			border-end-end-radius: 10rem !important;
		}
	}
}

.btn-square {
	border-radius: 2px;
}

.btn-facebook {
	color: $white;
	background-color: #3a48a9;
	border-color: #3a48a9;

	&:hover, &:focus {
		color: $white;
		background-color: #2b378a;
		border-color: #2b378a;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #3a48a9;
		border-color: #3a48a9;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #2d4373;
			border-color: #293e6a;
		}
	}
}

.show>.btn-facebook.dropdown-toggle {
	color: $white;
	background-color: #2d4373;
	border-color: #293e6a;
}

.btn-facebook:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
	}
}

.show>.btn-facebook.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
}

.btn-twitter {
	color: $white;
	background-color: #41a4f7;
	border-color: #41a4f7;

	&:hover, &:focus {
		color: $white;
		background-color: #0d8ddc;
		border-color: #0c85d0;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #41a4f7;
		border-color: #41a4f7;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #0c85d0;
			border-color: #0b7ec4;
		}
	}
}

.show>.btn-twitter.dropdown-toggle {
	color: $white;
	background-color: #0c85d0;
	border-color: #0b7ec4;
}

.btn-twitter:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
	}
}

.show>.btn-twitter.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(29, 161, 242, 0.5);
}

.btn-google {
	color: $white;
	background-color: #d73f16;
	border-color: #d73f16;

	&:hover {
		color: $white;
		background-color: #c43628;
		border-color: #c43628;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #d73f16;
		border-color: #d73f16;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #c63224;
			border-color: #bb2f22;
		}
	}
}

.show>.btn-google.dropdown-toggle {
	color: $white;
	background-color: #c63224;
	border-color: #bb2f22;
}

.btn-google:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
	}
}

.show>.btn-google.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(220, 78, 65, 0.5);
}

.btn-youtube {
	color: $white;
	background-color: #f00;
	border-color: #f00;

	&:hover, &:focus {
		color: $white;
		background-color: #d90000;
		border-color: #cc0000;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #f00;
		border-color: #f00;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #cc0000;
			border-color: #bf0000;
		}
	}
}

.show>.btn-youtube.dropdown-toggle {
	color: $white;
	background-color: #cc0000;
	border-color: #bf0000;
}

.btn-youtube:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
	}
}

.show>.btn-youtube.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.5);
}

.btn-vimeo {
	color: $white;
	background-color: #1ab7ea;
	border-color: #1ab7ea;

	&:hover {
		color: $white;
		background-color: #139ecb;
		border-color: #1295bf;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #1ab7ea;
		border-color: #1ab7ea;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #1295bf;
			border-color: #108cb4;
		}
	}
}

.show>.btn-vimeo.dropdown-toggle {
	color: $white;
	background-color: #1295bf;
	border-color: #108cb4;
}

.btn-vimeo:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
	}
}

.show>.btn-vimeo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(26, 183, 234, 0.5);
}

.btn-dribbble {
	color: $white;
	background-color: #f35e90;
	border-color: #f35e90;

	&:hover {
		color: $white;
		background-color: #ce567e;
		border-color: #ce567e;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #41a6f6;
		border-color: #41a6f6;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #e51e6b;
			border-color: #dc1a65;
		}
	}
}

.show>.btn-dribbble.dropdown-toggle {
	color: $white;
	background-color: #e51e6b;
	border-color: #dc1a65;
}

.btn-dribbble:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
	}
}

.show>.btn-dribbble.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(234, 76, 137, 0.5);
}

.btn-github {
	color: $white;
	background-color: #181717;
	border-color: #181717;

	&:hover, &:focus {
		color: $white;
		background-color: #040404;
		border-color: black;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #181717;
		border-color: #181717;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: black;
			border-color: black;
		}
	}
}

.show>.btn-github.dropdown-toggle {
	color: $white;
	background-color: black;
	border-color: black;
}

.btn-github:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
	}
}

.show>.btn-github.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(24, 23, 23, 0.5);
}

.btn-instagram {
	color: $white;
	background-color: #e4405f;
	border-color: #e4405f;

	&:hover, &:focus {
		color: $white;
		background-color: #de1f44;
		border-color: #d31e40;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #e4405f;
		border-color: #e4405f;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #d31e40;
			border-color: #c81c3d;
		}
	}
}

.show>.btn-instagram.dropdown-toggle {
	color: $white;
	background-color: #d31e40;
	border-color: #c81c3d;
}

.btn-instagram:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
	}
}

.show>.btn-instagram.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(228, 64, 95, 0.5);
}

.btn-pinterest {
	color: $white;
	background-color: #bd081c;
	border-color: #bd081c;

	&:hover {
		color: $white;
		background-color: #980617;
		border-color: #8c0615;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #bd081c;
		border-color: #bd081c;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #8c0615;
			border-color: #800513;
		}
	}
}

.show>.btn-pinterest.dropdown-toggle {
	color: $white;
	background-color: #8c0615;
	border-color: #800513;
}

.btn-pinterest:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
	}
}

.show>.btn-pinterest.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(189, 8, 28, 0.5);
}

.btn-vk {
	color: $white;
	background-color: #6383a8;
	border-color: #6383a8;

	&:hover {
		color: $white;
		background-color: #527093;
		border-color: #4d6a8b;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #6383a8;
		border-color: #6383a8;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #4d6a8b;
			border-color: #496482;
		}
	}
}

.show>.btn-vk.dropdown-toggle {
	color: $white;
	background-color: #4d6a8b;
	border-color: #496482;
}

.btn-vk:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
	}
}

.show>.btn-vk.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(99, 131, 168, 0.5);
}

.btn-rss {
	color: $white;
	background-color: #ffa500;
	border-color: #ffa500;

	&:hover {
		color: $white;
		background-color: #d98c00;
		border-color: #cc8400;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #ffa500;
		border-color: #ffa500;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #cc8400;
			border-color: #bf7c00;
		}
	}
}

.show>.btn-rss.dropdown-toggle {
	color: $white;
	background-color: #cc8400;
	border-color: #bf7c00;
}

.btn-rss:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
	}
}

.show>.btn-rss.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.5);
}

.btn-flickr {
	color: $white;
	background-color: #0063dc;
	border-color: #0063dc;

	&:hover {
		color: $white;
		background-color: #0052b6;
		border-color: #004ca9;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #0063dc;
		border-color: #0063dc;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #004ca9;
			border-color: #00469c;
		}
	}
}

.show>.btn-flickr.dropdown-toggle {
	color: $white;
	background-color: #004ca9;
	border-color: #00469c;
}

.btn-flickr:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
	}
}

.show>.btn-flickr.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 99, 220, 0.5);
}

.btn-bitbucket {
	color: $white;
	background-color: #0052cc;
	border-color: #0052cc;

	&:hover {
		color: $white;
		background-color: #0043a6;
		border-color: #003e99;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #0052cc;
		border-color: #0052cc;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #003e99;
			border-color: #00388c;
		}
	}
}

.show>.btn-bitbucket.dropdown-toggle {
	color: $white;
	background-color: #003e99;
	border-color: #00388c;
}

.btn-bitbucket:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
	}
}

.show>.btn-bitbucket.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(0, 82, 204, 0.5);
}

.btn-blue {
	color: $white;
	background-color: $blue;
	border-color: $blue;

	&:hover,
	&.disabled,
	&:disabled {
		color: $white;
		background-color: #18c0f9;
		border-color: #18c0f9;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #18c0f9;
			border-color: #18c0f9;
		}
	}
}

.show>.btn-blue.dropdown-toggle {
	color: $white;
	background-color: #18c0f9;
	border-color: #18c0f9;
}

.btn-indigo {
	color: $white;
	background-color: $indigo;
	border-color: $indigo;

	&:hover {
		color: $white;
		background-color: #485ac4;
		border-color: #3f51c1;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $indigo;
		border-color: $indigo;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #3f51c1;
			border-color: #3b4db7;
		}
	}
}

.show>.btn-indigo.dropdown-toggle {
	color: $white;
	background-color: #3f51c1;
	border-color: #3b4db7;
}

.btn-indigo:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
	}
}

.show>.btn-indigo.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(101, 116, 205, 0.5);
}

.btn-purple {
	color: $white;
	background-color: $purple;
	border-color: #50477e;

	&:hover {
		color: $white;
		background-color: #43377a;
		border-color: #5849a1;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $purple;
		border-color: $purple;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #8c31e4;
			border-color: #8526e3;
		}
	}
}

.show>.btn-purple.dropdown-toggle {
	color: $white;
	background-color: #8c31e4;
	border-color: #8526e3;
}

.btn-purple:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
	}
}

.show>.btn-purple.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(165, 94, 234, 0.5);
}

.btn-pink {
	color: $white;
	background-color: $pink;
	border-color: $pink;

	&:hover {
		color: $white;
		background-color: #db4577;
		border-color: #db4577;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #ec82ef;
		border-color: #ec82ef;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #f33d7a;
			border-color: #f23172;
		}
	}
}

.show>.btn-pink.dropdown-toggle {
	color: $white;
	background-color: #f33d7a;
	border-color: #f23172;
}

.btn-pink:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
	}
}

.show>.btn-pink.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(246, 109, 155, 0.5);
}

.btn-red {
	color: $white;
	background-color: $danger;
	border-color: $danger;

	&:hover {
		color: $white;
		background-color: #ac1b1a;
		border-color: #a11918;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $danger;
		border-color: $danger;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #a11918;
			border-color: #961717;
		}
	}
}

.show>.btn-red.dropdown-toggle {
	color: $white;
	background-color: #a11918;
	border-color: #961717;
}

.btn-red:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
	}
}

.show>.btn-red.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5);
}

.btn-orange {
	color: $white;
	background-color: $orange  !important;
	border-color: $orange;

	&:hover {
		color: $white;
		background-color: #b16628 !important;
		border-color: #b56321 !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $orange;
		border-color: $orange;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #fc7a12;
			border-color: #fc7305;
		}
	}
}

.show>.btn-orange.dropdown-toggle {
	color: $white;
	background-color: #fc7a12;
	border-color: #fc7305;
}

.btn-orange:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
	}
}

.show>.btn-orange.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(253, 150, 68, 0.5);
}

.btn-yellow {
	color: $white;
	background-color: $yellow;
	border-color: $yellow;

	&:hover {
		color: $white;
		background-color: #cea70c;
		border-color: #c29d0b;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $yellow;
		border-color: $yellow;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #c29d0b;
			border-color: #b6940b;
		}
	}
}

.show>.btn-yellow.dropdown-toggle {
	color: $white;
	background-color: #c29d0b;
	border-color: #b6940b;
}

.btn-yellow:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
	}
}

.show>.btn-yellow.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5);
}

.btn-green {
	color: $white;
	background-color: $green;
	border-color: $green;

	&:hover {
		color: $white;
		background-color: #4b9400;
		border-color: #448700;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $green;
		border-color: $green;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #448700;
			border-color: #3e7a00;
		}
	}
}

.show>.btn-green.dropdown-toggle {
	color: $white;
	background-color: #448700;
	border-color: #3e7a00;
}

.btn-green:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
	}
}

.show>.btn-green.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5);
}

.btn-teal {
	color: $white;
	background-color: $teal;
	border-color: $teal;

	&:hover {
		color: $white;
		background-color: #0b9788;
		border-color: #22a193;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $teal;
		border-color: $teal;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #22a193;
			border-color: #20968a;
		}
	}
}

.show>.btn-teal.dropdown-toggle {
	color: $white;
	background-color: #22a193;
	border-color: #20968a;
}

.btn-teal:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
	}
}

.show>.btn-teal.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(43, 203, 186, 0.5);
}

.btn-cyan {
	color: $white;
	background-color: $cyan  !important;
	border-color: $cyan;

	&:hover {
		color: $white;
		background-color: #308e9d !important;
		border-color: #308e9d !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $cyan;
		border-color: $cyan;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #117a8b;
			border-color: #10707f;
		}
	}
}

.show>.btn-cyan.dropdown-toggle {
	color: $white;
	background-color: #117a8b;
	border-color: #10707f;
}

.btn-cyan:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
	}
}

.show>.btn-cyan.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(23, 162, 184, 0.5);
}

.btn-white {
	color: $default-color;
	background-color: $white;
	border-color: $white;

	&:hover {
		color: $default-color;
		background-color: #ececec;
		border-color: #e6e5e5;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px $white-5;
	}

	&.disabled,
	&:disabled {
		color: $default-color;
		background-color: $white;
		border-color: $white;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $default-color;
			background-color: #e6e5e5;
			border-color: #dfdfdf;
		}
	}
}

.show>.btn-white.dropdown-toggle {
	color: $default-color;
	background-color: #e6e5e5;
	border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px $white-5;
	}
}

.show>.btn-white.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $white-5;
}

.btn-gray {
	color: $white;
	background-color: $gray;
	border-color: $gray;

	&:hover {
		color: $white;
		background-color: #727b84;
		border-color: #727b84;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $gray;
		border-color: $gray;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $default-color;
			border-color: #666e76;
		}
	}
}

.show>.btn-gray.dropdown-toggle {
	color: $white;
	background-color: $default-color;
	border-color: #666e76;
}

.btn-gray:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
	}
}

.show>.btn-gray.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5);
}

.btn-gray-dark {
	color: $white;
	background-color: $dark;
	border-color: $dark;

	&:hover {
		color: $white;
		background-color: #23272b;
		border-color: #1d2124;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $dark;
		border-color: $dark;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #1d2124;
			border-color: #171a1d;
		}
	}
}

.show>.btn-gray-dark.dropdown-toggle {
	color: $white;
	background-color: #1d2124;
	border-color: #171a1d;
}

.btn-gray-dark:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
	}
}

.show>.btn-gray-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5);
}

.btn-azure {
	color: $white;
	background-color: $azure;
	border-color: $azure;

	&:hover {
		color: $white;
		background-color: #219af0;
		border-color: #1594ef;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $azure;
		border-color: $azure;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #1594ef;
			border-color: #108ee7;
		}
	}
}

.show>.btn-azure.dropdown-toggle {
	color: $white;
	background-color: #1594ef;
	border-color: #108ee7;
}

.btn-azure:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
	}
}

.show>.btn-azure.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5);
}

.btn-lime {
	color: $white;
	background-color: $lime;
	border-color: $lime;

	&:hover {
		color: $white;
		background-color: #69b829;
		border-color: #63ad27;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $lime;
		border-color: $lime;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #63ad27;
			border-color: #5da324;
		}
	}
}

.show>.btn-lime.dropdown-toggle {
	color: $white;
	background-color: #63ad27;
	border-color: #5da324;
}

.btn-lime:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
	}
}

.show>.btn-lime.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(123, 210, 53, 0.5);
}

.btn-option {
	background: transparent;
	color: #cbd2dc;

	&:hover {
		color: #6e7687;
	}

	&:focus {
		box-shadow: none;
		color: #6e7687;
	}
}

.btn-list {
	margin-block-end: -.5rem;
	font-size: 0;

	> {

		.btn,
		.dropdown {
			margin-block-end: .5rem;
		}

		.btn:not(:last-child),
		.dropdown:not(:last-child) {
			margin-inline-end: .5rem;
		}
	}
}

.btn-loading {
	color: transparent !important;
	pointer-events: none;
	position: relative;

	&:after {
		content: '';
		-webkit-animation: loader 500ms infinite linear;
		animation: loader 500ms infinite linear;
		border: 2px solid $white;
		border-radius: 50%;
		border-right-color: transparent !important;
		border-top-color: transparent !important;
		display: block;
		height: 1.4em;
		width: 1.4em;
		position: absolute;
		inset-inline-start: calc(50% - (1.4em / 2));
		inset-block-start: calc(50% - (1.4em / 2));
		-webkit-transform-origin: center;
		transform-origin: center;
		position: absolute !important;
	}

	&.btn-sm:after {
		height: 1em;
		width: 1em;
		inset-inline-start: calc(50% - (1em / 2));
		inset-block-start: calc(50% - (1em / 2));
	}
}

.btn-group-sm>.btn-loading.btn:after {
	height: 1em;
	width: 1em;
	inset-inline-start: calc(50% - (1em / 2));
	inset-block-start: calc(50% - (1em / 2));
}

.btn-loading.btn-secondary:after {
	border-color: $default-color;
}

.btn.btn-app {
	position: relative;
	box-shadow: none;
	text-align: center;
	border: 1px solid rgba(173, 89, 255, 0.1);
	font-size: 12px;
	color: $white  !important;
}

.btn.dropdown-toggle~.dropdown-menu,
ul.dropdown-menu li.dropdown ul.dropdown-menu {
	border: 1px solid $border;
	box-shadow: 0 3px 9px 0 rgb(104 113 123 / 1%);
	inset-block-start: 0px;
	margin: 0px !important;
	padding: 0px !important;
}

.btn {
	&.dropdown-toggle {
		&.btn-primary~.dropdown-menu .dropdown-plus-title {
			border-color: $primary !important;
		}

		&.btn-success~.dropdown-menu .dropdown-plus-title {
			border-color: $success !important;
		}

		&.btn-info~.dropdown-menu .dropdown-plus-title {
			border-color: $info !important;
		}

		&.btn-warning~.dropdown-menu .dropdown-plus-title {
			border-color: $warning !important;
		}

		&.btn-danger~.dropdown-menu .dropdown-plus-title {
			border-color: $danger !important;
		}
	}

	.badge {
		position: relative;
		inset-block-start: -1px;
	}

	.icon,
	.fa {
		font-size: 0.9rem;
		margin-inline-end: 1px;
		vertical-align: initial;
	}
}

.btn-circle {
	width: 30px;
	height: 30px;
	padding: 6px 0;
	text-align: center;
	font-size: 12px;
	line-height: 1.428571429;
}

.btn-absolute {
	position: absolute;
	display: block;
	inset-inline-start: 0;
	background: linear-gradient(to bottom, transparent 0%, $black-9 100%);
	inset-inline-end: 0;
	inset-block-end: 0px;
	padding: 20px;
}

.btn-group,
.btn-group-vertical {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
}

.tag-attachments .btn {
	display: flex;
	align-items: center;
	justify-content: center;

	&.btn-close {
		font-weight: 600;
	}

	&.btn-pill.attachment-pill {
		&:active {
			border-start-end-radius: 0 !important;
			border-end-end-radius: 0 !important;
		}
		&.btn-close {
			&:active {
				border-start-start-radius: 0 !important;
				border-start-end-radius: 10rem !important;
				border-end-end-radius: 10rem !important;
				border-end-start-radius: 0 !important;
			}
		}
	}
}

.btn-group .btn-close {
	padding: 15px 6px;
	border-start-start-radius: 0;
	border-start-end-radius: 4px;
	border-end-end-radius: 4px;
	border-end-start-radius: 0;
	opacity: 1;
	font-weight: 300;
	background-image: none;
}

.btn-group .btn-close.btn-pill {
	border-start-start-radius: 0;
	border-start-end-radius: 50px;
	border-end-end-radius: 50px;
	border-end-start-radius: 0;
}

.btn-group .btn-close {
	&.btn-outline-primary {
		border: 1px solid $primary  !important;
		color: $primary  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-info {
		border: 1px solid $info  !important;
		color: $info  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-secondary {
		border: 1px solid $secondary  !important;
		color: $secondary  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-success {
		border: 1px solid $success  !important;
		color: $success  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-warning {
		border: 1px solid $warning  !important;
		color: $warning  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-danger {
		border: 1px solid $danger  !important;
		color: $danger  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-orange {
		border: 1px solid $orange  !important;
		color: $orange  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-outline-cyan {
		border: 1px solid $cyan  !important;
		color: $cyan  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-primary-light {
		border: 1px solid $primary-03 !important;
		color: $primary  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-secondary-light {
		border: 1px solid rgba($secondary, 0.3) !important;
		color: $blue  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-info-light {
		border: 1px solid rgba($info, 0.3) !important;
		color: $azure  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-warning-light {
		border: 1px solid rgba($warning, 0.3) !important;
		color: $warning  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-success-light {
		border: 1px solid rgba($success, 0.3) !important;
		color: $success  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-danger-light {
		border: 1px solid rgba($danger, 0.3) !important;
		color: $danger  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-orange-light {
		border: 1px solid rgba($orange, 0.3) !important;
		color: $orange  !important;

		&:hover {
			color: $white !important;
		}
	}

	&.btn-cyan-light {
		border: 1px solid rgba($cyan, 0.3) !important;
		color: $cyan  !important;

		&:hover {
			color: $white !important;
		}
	}
}

.btn-primary-light {
	color: $primary !important;
	background: $primary-03 !important;
	border-color: $primary-01 !important;

	&:hover {
		color: $white !important;
		background-color: $primary !important;
		border-color: $primary  !important;
	}
}

.btn-secondary-light {
	color: $secondary !important;
	background: rgba($secondary, 0.3) !important;
    border-color: rgba($secondary, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $secondary  !important;
		border-color: $secondary  !important;
	}
}

.btn-info-light {
	color: $info !important;
	background: rgba($info, 0.3) !important;
	border-color: rgba($info, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $info  !important;
		border-color: $info  !important;
	}
}

.btn-warning-light {
	color: $warning !important;
	background: rgba($warning, 0.3) !important;
	border-color: rgba($warning, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $warning  !important;
		border-color: $warning  !important;
	}
}

.btn-success-light {
	color: $success !important;
	background: rgba($success, 0.3) !important;
	border-color: rgba($success, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $success  !important;
		border-color: $success  !important;
	}
}

.btn-danger-light {
	color: $danger !important;
	background: rgba($danger, 0.3) !important;
	border-color: rgba($danger, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $danger  !important;
		border-color: $danger  !important;
	}
}

.btn-orange-light {
	color: $orange !important;
	background: rgba($orange, 0.3) !important;
	border-color: rgba($orange, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $orange  !important;
		border-color: $orange  !important;
	}
}

.btn-cyan-light {
	color:$cyan !important;
	background: rgba($cyan, 0.3) !important;
	border-color: rgba($cyan, 0.1) !important;

	&:hover {
		color: $white !important;
		background-color: $cyan  !important;
		border-color: $cyan  !important;
	}
}

.btn-group .btn-close.btn-sm {
	padding: 10px !important;
	font-size: 1rem !important;
	background-image: none;
}

.btn-group .btn-close.btn-lg {
	padding: 15px;
	background-image: none;
	font-size: 31px;
}

.btn-outline-primary.dropdown-toggle.show {
	background-color: $primary;
}
.dropdown-toggle { 
	&.btn-outline-primary, &.btn-outline-secondary, &.btn-outline-info, &.btn-outline-warning, &.btn-outline-danger, &.btn-outline-success {
		&:active, &:focus, &:hover {
			&::after {
				color: $white;
			}
		}
	}
	
}

.btn-outline-info.dropdown-toggle.show, .btn-outline-warning.dropdown-toggle.show {
	color: $white;
}

a {
	color: $primary;

	&:hover {
		color: $primary;
	}
}

.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(59, 175, 253, 0.1);
	}
}

.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(59, 175, 253, 0.1);
}

.btn-secondary {
	color: $white;
	background-color: $secondary  !important;
	border-color: $secondary;

	&:hover {
		color: $white;
		background-color: #55a206 !important;
		border-color: #55a206 !important;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: #55a206;
		border-color: #55a206;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: #55a206;
			border-color: #55a206;
		}
	}
}

.show>.btn-secondary.dropdown-toggle {
	color: $white;
	background-color: #55a206;
	border-color: #55a206;
}

.btn-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
	}
}

.show>.btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.1);
}

.btn-gradient-primary {
	color: $white;
	background: linear-gradient(to bottom right, $primary, #4f4da5);
	border-color: #554d97;

	&:hover {
		color: $white;
		border-color: #4f4da5;
		background-color: #4f4da5;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(140, 95, 197, 0.5);
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: $primary;
		border-color: $primary;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: ($primary, );
			border-color: #4f4da5;
		}
	}
}

.show>.btn-gradient-primary.dropdown-toggle {
	color: $white;
	background-color: ($primary, );
	border-color: #4f4da5;
}

.btn-gradient-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(140, 95, 197, 0.1);
	}
}

.show>.btn-gradient-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(140, 95, 197, 0.1);
}

.show>.btn-outline-primary.dropdown-toggle {
	color: $white;
	background-color: $primary;
	border-color: $primary;
}

.btn-outline-primary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
	}
}

.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
}

.btn-outline-secondary {
	color: $secondary;
	background-color: transparent;
	background-image: none;
	border-color: $secondary;

	&:hover {
		color: $white;
		background-color: $secondary;
		border-color: $secondary;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $secondary;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
}

.btn-outline-pink {
	color: $pink;
	background-color: transparent;
	background-image: none;
	border-color: $pink;

	&:hover {
		color: $white;
		background-color: $pink;
		border-color: $pink;
	}

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}

	&.disabled,
	&:disabled {
		color: $pink;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled) {

		&:active,
		&.active {
			color: $white;
			background-color: $pink;
			border-color: $pink;
		}
	}
}

.show>.btn-outline-secondary.dropdown-toggle {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.btn-outline-secondary:not(:disabled):not(.disabled) {

	&:active:focus,
	&.active:focus {
		box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
	}
}

.show>.btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.1);
}

.btn-link {
	color: $primary;
}

.input-group-addon {
	color: $primary;
}
/*------ Buttons ------*/