/*------ Accordion -------*/

.accordion .card {
    &:not(:first-of-type) {
        &:not(:last-of-type) {
            border-block-end: 0;
            border-radius: 0;
        }

        .card-header:first-child {
            border-radius: 0;
        }
    }

    &:first-of-type {
        border-block-end: 0;
        border-end-start-radius: 0;
        border-end-end-radius: 0;
    }

    &:last-of-type {
        border-start-start-radius: 0;
        border-start-end-radius: 0;
    }
}

#accordion .panel-heading1 {
    a:before {
        content: "\f01a";
        font-size: 24px;
        position: absolute;
        font-family: 'FontAwesome';
        inset-inline-end: 5px;
        inset-block-start: 10px;
        transform: rotate(180deg);
        transition: all 0.5s;
    }

    &.active a:before {
        transform: rotate(0deg);
        transition: all 0.5s;
    }
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
    margin-block-end: 0;
}

.panel-default>.panel-heading {
	color: $primary;
}

.accordion-item {
    border: 1px solid $border !important;
    
}

.accordion {
    display: inline-block;
    width: 100%;
    margin-block-end: 10px;


    .accordion-body {
        padding: 10px 15px;
    }

    .accordion-header {
        background-color: $white;
        border-radius: 3px;
        cursor: pointer;
        -webkit-transition: all .5s;
        -o-transition: all .5s;
        transition: all .5s;

        h4 {
            margin: 0;
            font-size: 14px;
        }

        &:hover {
            background-color: #f0f0f5;
        }

        &[aria-expanded="true"] {
            -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            color: $white;
        }
    }

    .accordion-body {
        line-height: 24px;
    }
}

/* style-1--*/

.acc-1 .accordion-header,
.accordion-body {
    background: $white;
}

.acc-1 {
    .accordion__item {
        margin-block-end: 3px;
        display: inline-block;
        width: 100%;
    }

    .accordion-header {
        padding: 10px 14px;
        background: #f9f9f9;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 14px;
        transition: all .3s;
        border-radius: .25rem;
    }

    .active .accordion-header {
        border-end-start-radius: 0;
        border-end-end-radius: 0;
    }

    .accordion-header:hover {
        color: $white;
        position: relative;
        z-index: 5;
    }

    .accordion-body {
        background: $white;
        color: #3f3c3c;
        display: none;
    }

    .accordion-body__contents {
        padding: 10px 15px;
        font-size: 14px;
        border: 1px solid #e6eaea;
        background: $white;
    }

    .accordion__item.active:last-child .accordion-header {
        border-radius: none;
    }

    .accordion:first-child>.accordion__item>.accordion-header {
        border-block-end: 1px solid transparent;
    }

    .accordion__item {
        >.accordion-header:after {
            content: "\f107";
            font-family: IonIcons;
            font-size: 1.2em;
            float: right;
            position: relative;
            inset-block-start: -2px;
            transition: .3s all;
            transform: rotate(0deg);
            font-family: 'FontAwesome' !important;
        }

        &.active {
            >.accordion-header:after {
                transform: rotate(-180deg);
                inset-inline-start: 3px;
                inset-block-start: -3px;
            }

            .accordion-header {
                border-radius: .25rem;
            }
        }
    }
}

.accor h5 a {
    padding: .75rem 1.25rem;
    display: block;
    border-block-end: 1px solid #c7d5e2;
}

#bs-collapse .panel-heading1 {
    a:after {
        content: "\f01a";
        font-size: 24px;
        position: absolute;
        font-family: 'FontAwesome';
        inset-inline-end: 5px;
        inset-block-start: 10px;
        transform: scale(0);
        transition: all 0.5s;
    }

    &.active a:after {
        content: "\f01a";
        transform: scale(1);
        transition: all 0.5s;
    }
}

.accordion-button::after {
    height: 14px;
    background-image: none;
    content: "\e92d";
    font-family: feather !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
    margin-inline-end: 5px;
}

.panel-group1 {
    .panel-heading1 {
        .accordion-toggle {
            &.collapsed {
                border-radius: 4px;
            }

            border-start-start-radius: 4px;
            border-start-end-radius: 4px;
        }
    }
}

/*------ Accordion -------*/