/*-----ERRORPAGES-----*/

@media (min-width: 321px) {
	.construction .font {
		font-size: 3.5rem !important;
	}
}
@media (min-width: 320px) {
	.construction .input-group {
		width: 70% !important;
	}
}
.construction {
	display: inline-block;
	.countdown span:first-child {
		font-size: 24px;
		color: $white;
	}
}
.countdown span:last-child {
	padding-block-start: 20px;
	color: #212529;
}
.construction {
	.countdown {
		padding: 11px !important;
	}
	.form-control {
		padding: 0.375rem 1rem;
	}
}
.cover-image {
	background-size: cover !important;
}
.about-img {
	background: linear-gradient($black-3, $black-3), url(../images/profile-bg.jpg);
	background-size: cover;
	background-position: center;
	margin: 0px -30px;
}
.login-img {
	background-size: cover;
	background: linear-gradient(rgba(140, 95, 197, 0.9), rgba(79, 77, 165, 0.9));
	background-repeat: no;
}
.construction-img {
	background: url('../images/construction.jpg');
	background-size: cover;
}
.login-transparent:before {
	content: '';
	display: block;
	position: absolute;
	background: -webkit-gradient(linear, left top, right top, from(rgba(129, 94, 174, 0.9)), to(rgba(75, 73, 146, 0.9)));
	width: 100%;
	height: 100%;
	inset-inline-end: 0;
	inset-block-start: 0;
	border-radius: 0px;
}
/*-----ERRORPAGES-----*/