/*------ Breadcrumb ------*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-block-end: 0;
	list-style: none;
	border-radius: 3px;
	background: none;
}

.breadcrumb-item {
	a {
		color: $default-color;
	}

	+.breadcrumb-item::before {
		display: inline-block;
		content: "\f178";
		font-family: "FontAwesome";
		font-style: normal;
		font-weight: normal;
		text-decoration: inherit;
		font-size: 10px;
		color: $black-3;
	}
	&.active {
		color: $primary;
	}
}

.page-header .breadcrumb {
	padding: 0;
}

.breadcrumb-item {
	+.breadcrumb-item:hover::before {
		text-decoration: none;
	}

	&.active {
		display: flex;
		align-items: center;
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-block-end: 1rem;
	list-style: none;
	background-color: $background;
}

.breadcrumb-item1 {
	a:hover {
		color: #123787;
		text-decoration: underline;
	}

	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-inline: 0.5rem;
			content: ">";
		}

		&:hover::before {
			text-decoration: none;
		}
	}
}

.breadcrumb-3 {
	li {
		display: contents;
		text-align: center;
	}

	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
}

.breadcrumb-4 li,
.breadcrumb-5 li {
	display: contents;
	text-align: center;
}

.breadcrumb-4 ol,
.breadcrumb-5 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: center;
}

.breadcrumb-1 li,
.breadcrumb-2 li,
.breadcrumb-6 li {
	text-align: end;
	display: contents;
}

.breadcrumb-1 ol,
.breadcrumb-2 ol,
.breadcrumb-6 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: end;
}

.breadcrumb-arrow {
	height: 36px;
	padding: 0;
	line-height: 36px;
	list-style: none;
	background-color: #f9f9f9;
	font-size: 14px !important;

	li {
		&:first-child a {
			border-start-start-radius: 4px;
			border-start-end-radius: 0;
			border-end-end-radius: 0;
			border-end-start-radius: 4px;
			-webkit-border-radius: 4px 0 0 4px;
			-moz-border-radius: 4px 0 0 4px;
			padding: 0 10px;
			padding-inline-start: 10px;
		}

		display: inline-block;
		vertical-align: top;

		a,
		span {
			display: inline-block;
			vertical-align: top;
		}

		&:not(:first-child) {
			margin-inline-start: -5px;
		}

		+li:before {
			padding: 0;
			content: "";
		}

		span {
			padding: 0 10px;
			color: #434a54;
		}

		a,
		&:not(:first-child) span {
			height: 36px;
			padding-block: 0;
			padding-inline-end: 10px;
			padding-inline-start: 20px;
			line-height: 36px;
		}

		a {
			position: relative;
			color: $white;
			text-decoration: none;

			&:after {
				position: absolute;
				top: -1px;
				width: 0;
				height: 0;
				content: '';
				border-block: 18px solid transparent;
				
			}

			&:before {
				position: absolute;
				top: -1px;
				width: 0;
				height: 0;
				content: '';
				border-block: 18px solid transparent;
				right: -10px;
				z-index: 3;
				border-inline-start-style: solid;
				border-inline-start-width: 11px;
			}

			&:after {
				inset-inline-end: -11px;
				z-index: 2;
				border-inline-start: 11px solid $white-2;
			}
		}
	}
}

/*------ Breadcrumb ------*/