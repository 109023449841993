/*----Labels-----*/

label {
	display: inline-block;
	margin-block-end: 0.5rem;
}
.label-default {
	background: $gray;
}
.label-success {
	background: $green;
}
.label-danger {
	background: $danger;
}
.label-warning {
	background: $yellow;
}
.label-info {
	background: $azure;
}
ul.label-inbox {
	display: inline-block;
	margin: 0;
	padding: 0;
	width: 100%;
	li {
		display: inline-block;
		width: 100%;
		a {
			display: inline-block;
			padding: 10px 15px;
			width: 100%;
			color: #5c6287;
			text-transform: capitalize;
			-webkit-transition: 0.3s ease;
			-moz-transition: 0.3s ease;
			transition: 0.3s ease;
			text-decoration: none;
			i {
				font-size: 16px;
				padding-inline-end: 10px;
				padding-inline-start: 3px;
				color: #5c6287;
			}
		}
		// &.active a, a:focus {
		// 	background: #f9f9f9;
		// }
	}
}
.label {
	display: inline-block;
	margin-block-end: .5rem;
}
/*----Labels-----*/
