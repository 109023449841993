/*---- Tags-----*/

.tag {
	font-size: 0.75rem;
	background-color: $primary-01;
	color: $primary;
	border-radius: 3px;
	padding: 0 .5rem;
	line-height: 2em;
	display: -ms-inline-flexbox;
	display: inline-flex;
	cursor: default;
	font-weight: 600;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
a.tag {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background-color: $primary-01;
		color: $primary;
	}
}
.tag-primary {
	background-color: $primary;
	color: $white;
}

.tag-secondary {
	background-color: $gray;
	color: $white;
}
.tag-addon {
	display: inline-block;
	padding: 0 .5rem;
	color: inherit;
	text-decoration: none;
	margin-block: 0;
	margin-inline-end: -0.5rem;
	margin-inline-start: 0.5rem;
	text-align: center;
	min-width: 1.5rem;
	&:last-child {
		border-start-end-radius: 3px;
		border-end-end-radius: 3px;
	}
	i {
		vertical-align: middle;
		margin: 0 -.25rem;
	}
}
a.tag-addon {
	text-decoration: none;
	cursor: pointer;
	transition: .3s color, .3s background;
	&:hover {
		background: #6fabff2e;
		color: inherit;
	}
}
.tag-avatar {
	width: 1.5rem;
	height: 1.5rem;
	border-start-start-radius: 3px;
	border-start-end-radius: 0;
	border-end-end-radius: 0;
	border-end-start-radius: 3px;
	margin-block: 0;
	margin-inline-end: 0.5rem;
	margin-inline-start: -0.5rem;
}
.tag-blue {
	background-color: $info !important;
	color: $white;
}
.tag-indigo {
	background-color: $indigo !important;
	color: $white;
}
.tag-purple {
	background-color: $purple !important;
	color: $white;
}
.tag-pink {
	background-color: #ec82ef !important;
	color: $white;
}
.tag-red {
	background-color: $danger !important;
	color: $white;
}
.tag-orange {
	background-color: $orange !important;
	color: $white;
}
.tag-yellow {
	background-color: $yellow !important;
	color: $white;
}
.tag-green {
	background-color: $green !important;
	color: $white;
}
.tag-teal {
	background-color: $teal !important;
	color: $white;
}
.tag-cyan {
	background-color: $cyan !important;
	color: $white;
}
.tag-white {
	background-color: $white !important;
	color: $white;
}
.tag-gray {
	background-color: $gray !important;
	color: $white;
}
.tag-gray-dark {
	background-color: $dark !important;
	color: $white;
}
.tag-azure {
	background-color: $azure !important;
	color: $white;
}
.tag-lime {
	background-color: $lime !important;
	color: $white;
}
.tag-success {
	background-color: $green !important;
	color: $white;
}
.tag-info {
	background-color: $azure !important;
	color: $white;
}
.tag-warning {
	background-color: $yellow !important;
	color: $white;
}
.tag-danger {
	background-color: $danger !important;
	color: $white;
}
.tag-light {
	background-color: #f8f9fa !important;
	color: $white;
}
.tag-dark {
	background-color: $dark !important;
	color: $white;
}
.tag-rounded {
	border-radius: 50px;
	.tag-avatar {
		border-radius: 50px;
	}
}
.tags {
	margin-block-end: -.5rem;
	font-size: 0;
	>.tag {
		margin-block-end: .5rem;
		&:not(:last-child) {
			margin-inline-end: .5rem;
		}
	}
}
/*---- Tags-----*/