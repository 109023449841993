/*------ Pagination -------*/

.example .pagination {
	margin-block-end: 0;
}

.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-inline-start: 0;
	list-style: none;
	border-radius: 3px;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.7rem;
	margin-inline-start: -1px;
	line-height: 1.25;
	background-color: $white;
	border: 1px solid $border;
	color: $default-color;

	&:hover {
		z-index: 2;
		text-decoration: none;
		background-color: rgb(235, 233, 247);
		box-shadow: none;
	}

	&:focus {
		z-index: 2;
		outline: 0;
		box-shadow: none;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

.page-item {
	&.active .page-link {
		z-index: 1;
		color: $white;
	}

	&.disabled .page-link {
		color: #ced4da;
		pointer-events: none;
		cursor: auto;
		background-color: $white;
		border-color: $border;
	}
}

.pagination-lg {
	.page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.125rem;
		line-height: 1.5;
	}

	.page-item {
		&:first-child .page-link {
			border-start-start-radius: 3px;
			border-end-start-radius: 3px;
		}

		&:last-child .page-link {
			border-start-end-radius: 3px;
			border-end-end-radius: 3px;
		}
	}
}

.pagination-sm {
	.page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
	}

	.page-item {
		&:first-child .page-link {
			border-start-start-radius: 3px;
			border-end-start-radius: 3px;
		}

		&:last-child .page-link {
			border-start-end-radius: 3px;
			border-end-end-radius: 3px;
		}
	}
}

ul.inbox-pagination {
	float: right;

	li {
		float: left;
	}
}

.page-content {
	margin: .75rem 0;
}

@media (min-width: 768px) {
	.page-content {
		margin: 1.5rem 0;
	}
}

.pagination-simple .page-item {
	.page-link {
		background: none;
		border: none;
	}

	&.active .page-link {
		color: #495057;
		font-weight: 700;
	}
}

.pagination-pager {
	.page-prev {
		margin-inline-end: auto;
	}

	.page-next {
		margin-inline-start: auto;
	}
}

.page-total-text {
	margin-inline-end: 1rem;
	-ms-flex-item-align: center;
	align-self: center;
	color: #6e7687;
}

/*------ Pagination -------*/