/*------- Gutters -------*/
.g-0 {
	margin-inline: 0;
	> {
		.col, [class*="col-"] {
			padding-inline: 0;
		}
	}
}
.gutters-0 {
	margin-inline: 0;
	> {
		.col, [class*="col-"] {
			padding-inline: 0;
		}
	}
	.card {
		margin-block-end: 0;
	}
}
.gutters-xs {
	margin-inline: -0.25rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.25rem;
		}
	}
	.card {
		margin-block-end: 0.5rem;
	}
}
.gutters-sm {
	margin-block-end: -0.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline-end: 0.5rem;
		}
	}
	.card {
		margin-block-end: 1rem;
	}
}
.gutters-lg {
	margin-inline: -1rem;
	> {
		.col, [class*="col-"] {
			padding-inline: 1rem;
		}
	}
	.card {
		margin-block-end: 2rem;
	}
}
.gutters-xl {
	margin-inline: -1.5rem;
	> {
		.col, [class*="col-"] {
			padding-inline: 1.5rem;
		}
	}
	.card {
		margin-block-end: 3rem;
	}
}
/*------- Gutters -------*/