/*----- Custom-styles -----*/

/*-----Media-----*/

.media {
	display: flex;
	align-items: flex-start;
	margin-block-start: 15px;
	display: -ms-flexbox;
	-ms-flex-align: start;
}
.mediaicon {
	font-size: 18px;
	color: $white;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 5px 0px;
	border-radius: 20px;
}
.mediafont {
	font-size: 16px;
	font-weight: 600;
}
.media-body {
	-ms-flex: 1;
	flex: 1;
}
.list-media {
	.media-img {
		position: relative;
		float: left;
		width: 48px;
		margin-inline-end: 20px;
	}
	.info {
		padding-inline-start: 55px;
		min-height: 40px;
		height: auto;
		position: relative;
		h4 a {
			font-size: 16px;
			margin-block-start: 10px;
		}
		p {
			font-size: 13px;
		}
		.text-end {
			inset-inline-end: 0;
			color: #8a8a8a;
			inset-block-start: 50%;
			font-size: 12px;
			transform: translateY(-50%);
			-webkit-transform: translateY(-50%);
			-moz-transform: translateY(-50%);
			-o-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			margin-block-start: -28px;
		}
	}
}
.media, .media-body {
	zoom: 1;
	overflow: hidden;
}
.media-object {
	display: block;
}
.media {
	img {
		width: 40px;
		height: 40px;
	}
	&.media-lg img {
		width: 70px;
		height: 70px;
	}
}
.media-right, .media>.pull-right {
	padding-inline-start: 10px;
}
.media-body, .media-left, .media-right {
	display: table-cell;
	vertical-align: top;
}
.media-left, .media>.pull-left {
	padding-right: 10px;
}
.media-heading {
	margin-block-start: 0;
	margin-block-end: 5px;
}
.media-body h4 {
	font-weight: 500;
	font-size: 16px;
}
.media-middle {
	vertical-align: middle;
}
.media-bottom {
	vertical-align: bottom;
}
.media-icon {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	border-radius: 100%;
}
.media-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
.media-body .dropdown-toggle::after {
	margin-inline-start: 0 !important;
}
/*-----Media-----*/

/*-----Item-Card -----*/

.item-card {
	.cardbody {
		position: relative;
		padding: 30px 15px;
		border-start-start-radius: 0;
		border-start-end-radius: 0;
		border-end-end-radius: 0.2rem;
		border-end-start-radius: 0.2rem;
	}
	.cardtitle {
		span {
			display: block;
			font-size: .75rem;
		}
		a {
			color: #707070;
			font-weight: 500;
			text-decoration: none;
		}
	}
	.cardprice {
		position: absolute;
		inset-block-start: 30px;
		inset-inline-end: 15px;
		span {
			&.type--strikethrough {
				opacity: .7;
				text-decoration: line-through;
			}
			display: block;
			color: #707070;
		}
	}
}
.item7-card-text span {
	position: absolute;
	inset-block-end: 10px;
	inset-inline-start: 10px;
}
.item7-card-desc a:first-child {
	margin-inline-end: 1rem;
}
.item-card {
	.product-info {
		padding: 0;
		margin: 0;
		opacity: 0;
		inset-inline-start: 35%;
		text-align: center;
		position: absolute;
		inset-block-end: -30px;
		z-index: 1;
		transition: all .5s ease 0s;
	}
	&:hover .product-info {
		opacity: 1;
		inset-block-end: 25%;
	}
}
.item .card {
	box-shadow: 0 0 5px 0 rgba(115, 77, 191, 0.2);
}

.ribbon4 {
	position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
  
	span {
		font-size: 10px;
		font-weight: bold;
		color: $white;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		width: 100px;
		display: block;
		background: #79A70A;
		background: linear-gradient(#f8463f 0%, #f8463f 100%);
		box-shadow: 0 3px 10px -5px rgb(0 0 0);
		position: absolute;
		top: 19px;
    	right: -21px;

		&::before {
			content: "";
			position: absolute;
			left: 0px;
    		top: 100%;
			z-index: -1;
			border-inline-start: 3px solid #f8463f;
			border-inline-end: 3px solid transparent;
			border-block-end: 3px solid transparent;
			border-block-start: 3px solid #f8463f;
		}
  
		&::after {
			content: "";
			position: absolute;
			right: 0px;
    		top: 100%;
			z-index: -1;
			border-inline-start: 3px solid transparent;
			border-inline-end: 3px solid #f8463f;
			border-block-end: 3px solid transparent;
			border-block-start: 3px solid #f8463f;
		}
	}
}

.product:hover {
	.products-content {
		opacity: 0;
	}
}
/*-----Item-Card -----*/

/*----Profile----*/

.mediaicon {
	border: 1px solid $primary;
	background: $primary;
}

a.chip:hover {
	background-color: $primary;
}
.page-link:hover {
	color: $primary;
}

.page-item.active .page-link {
	background-color: $primary;
	border: 1px solid $primary;
}

.table-inbox tr td .fa-star {

	&.inbox-started,
	&:hover {
		color: $primary;
	}
}

.progress-bar {
	background-color: $primary;
}

.bg-primary {
	background-color: $primary  !important;
	color: $white  !important;
}

a.bg-primary {

	&:hover,
	&:focus {
		background-color: $primary !important;
		color: $white  !important;
	}
}

button.bg-primary {

	&:hover,
	&:focus {
		background-color: $primary !important;
		color: $white  !important;
	}
}



button.bg-secondary {

	&:hover,
	&:focus {
		background-color: #2e384d !important;
	}
}

.header-bg {
	/* fallback for old browsers */
	background: -webkit-linear-gradient(to right, $blue, $primary);

	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(linear, left top, right top, from($primary), to($primary));
}

a.icon:hover {
	color: $primary  !important;
}

.nav-tabs .nav-link {

	&:hover:not(.disabled),
	&.active {
		color: $white;
		background: $primary;
	}
}

.admin-navbar {
	.nav-link {

		&:hover,
		&:focus,
		&.active {
			color: $primary;
		}
	}

	.mega-dropdown .sub-item .section-label {
		color: $primary;
	}

	.sub-item ul a {
		&:active {
			background-color: $primary;
		}

		&:hover,
		&:focus {
			color: $primary;
		}
	}
}

@media (max-width: 992px) {
	.nav-item.with-sub .sub-item {
		border-block-start: 1px solid $primary;
	}
}

.nav-tabs .nav-submenu .nav-item.active {
	color: $primary;
}



.table-calendar-link {

	&:before,
	&:hover {
		background: $primary;
	}
}

.jsr_slider {

	&:focus::before,
	&::before,
	&:focus::before {
		background: $primary;
	}
}

.range.range-primary {
	input[type="range"] {

		&::-webkit-slider-thumb,
		&::-moz-slider-thumb {
			background-color: $primary;
		}
	}

	output {
		background-color: $primary;
	}

	input[type="range"] {
		outline-color: $primary;
	}
}

.panel.price>.panel-heading {
	background: $primary;
	color: $white;
}

.user-button i {
	border: 1px solid $primary;

	&:hover {
		background: $primary;
		color: $white;
	}
}

.nav-tabs {
	.nav-item1 .nav-link {

		&:hover:not(.disabled),
		&.active {
			color: $white;
			background: $primary;
		}
	}

	.nav-submenu .nav-item1.active {
		color: $primary;
	}
}

.breadcrumb-item1 a {
	color: $primary;
}

.breadcrumb-arrow li a {
	background-color: $primary;
	border: 1px solid $primary;

	&:before {
		border-inline-start-color: $primary;
	}
}


.panel-title1 a {
	background: $primary;
}

.dropdown-menu>li>a {

	&:hover,
	&:focus {
		color: $primary;
	}
}

.bg-primary {
	color: $white;
	background-color: $primary;

	&[href] {

		&:hover,
		&:focus {
			color: $white;
			text-decoration: none;
			background-color: #24987E;
		}
	}
}

.dot1,
.dot2,
.spinner4>div,
.sk-circle .sk-child:before,
.sk-cube-grid .sk-cube {
	background: $primary;
}

/*--Loaders--*/

.sk-folding-cube .sk-cube:before {
	background-color: $primary;
}

.spinner5>div,
.spinner {
	background: $primary;
}

.spinner-lg {
	background-color: $primary;
}

.double-bounce1,
.double-bounce2 {
	background: $primary;
}

.cube1,
.cube2 {
	background-color: $primary;
}

.lds-heart div {
	background: $primary;

	&:after,
	&:before {
		background: $primary;
	}
}

.lds-ring div {
	border: 6px solid $primary;
	border-color: $primary transparent transparent transparent;
}

.lds-hourglass:after {
	border: 26px solid $primary;
	border-color: $primary transparent;
}

/*--Loaders--*/

.tabs-menu ul li .active,
.sidebar .tabs-menu ul li .active {
	background: $primary;
	color: $white;
}

.tabs-menu1 ul li .active {
	border-block-end: 3px solid $primary;
}

.br-theme-bars-horizontal .br-widget {
	a {

		&.br-active,
		&.br-selected {
			background-color: $primary;
		}
	}

	.br-current-rating {
		color: $primary;
	}
}

.br-theme-bars-pill .br-widget a {

	&.br-active,
	&.br-selected {
		background-color: $primary;
		color: white;
	}

	color: $primary;
}

.br-theme-bars-square .br-widget a {

	&.br-active,
	&.br-selected {
		color: $primary;
	}
}

.br-theme-bars-movie .br-widget {
	a {

		&.br-active,
		&.br-selected {
			background-color: $primary;
		}
	}

	.br-current-rating {
		color: $primary;
	}
}

.br-theme-bars-1to10 .br-widget {
	a {

		&.br-active,
		&.br-selected {
			background-color: $primary;
		}
	}

	.br-current-rating {
		color: $primary;
	}
}

.br-theme-fontawesome-stars .br-widget a {

	&.br-active:after,
	&.br-selected:after {
		color: $primary;
	}
}

.br-theme-fontawesome-stars-o .br-widget a {

	&.br-active:after,
	&.br-selected:after {
		content: '\f005';
		color: $primary;
	}

	&.br-fractional:after {
		content: '\f123';
		color: $primary;
	}
}

.message-feed:not(.right) .mf-content,
.msb-reply button,
.action-header {
	background: $primary;
}

.wizard-card .moving-tab {
	background-color: $primary  !important;
}

.form-group label.control-label {
	color: $primary;
}

.wizard-card .choice {

	&:hover .icon,
	&.active .icon {
		border-color: $primary  !important;
		color: $primary  !important;
	}
}

.addui-slider .addui-slider-track {
	.addui-slider-range {
		display: block;
		background: $primary;
	}

	.addui-slider-handle:after {
		background: $primary;
	}
}


div.conv-form-wrapper div.options div.option {
	border: 1px solid $primary;
	background: $white;
	color: $primary;
}

form.convFormDynamic button.submit {
	color: $primary;
	border: 1px solid $primary;
	background: $white;
}

div.conv-form-wrapper div.options div.option.selected {
	background: $primary;
	color: $white;

	&:hover {
		background: $primary;
	}
}

form.convFormDynamic button.submit:hover {
	background: $primary;
	color: $white;
}

div.conv-form-wrapper div#messages div.message.from {
	background: $primary;
	color: $white;
	border-start-end-radius: 0;
}

#sidebar ul li.active>a {
	color: $primary;
}

.accordionjs .acc_section.acc_active>.acc_head {
	background: $primary  !important;
}

.tab_wrapper {
	>ul li.active {
		border-color: $primary  !important;
		background: $primary  !important;
		color: $white;
	}

	&.right_side>ul li.active:after {
		background: $primary  !important;
	}
}

.sweet-alert button {
	background-color: $primary  !important;
}

.timeline__item:after {
	border: 6px solid $primary;
}

.job-box-filter a.filtsec i {
	color: $primary;
	margin-block-end: 5px;
}

.timelineleft> {
	.timeleft-label>span {
		background-color: $primary;
	}

	li> {

		.fa,
		.glyphicon,
		.ion {
			background: $primary;
		}
	}
}

.chat .search_btn {
	background-color: $primary  !important;
	border-start-start-radius: 0 !important;
	border-start-end-radius: 2px !important;
	border-end-end-radius: 2px !important;
	border-end-start-radius: 0 !important;
}

.msg_head,
.chat .action-header {
	background: $primary;
}

.accordion .accordion-header[aria-expanded="true"] {
	background-color: $primary;
}

.acc-1 {
	.accordion-header:hover {
		background: $primary;
	}

	.accordion__item {

		.accordion-header[aria-expanded="true"],
		&.active .accordion-header {
			background: $primary;
			color: $white;
		}
	}
}

.statistics-info .counter-icon {
	i {
		color: $primary;
	}

	border: 1px solid $primary;
}

.card-pricing {
	border: 2px solid $primary;
}

.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
	background-color: $primary  !important;
}

.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	color: $primary;
	background: transparent;
}

#myTab .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background: $primary;
}

.latest-timeline ul.timeline>li:before {
	border: 3px solid $primary;
}

.card-bgimg:before {
	background: $primary;
}

.header-form .form-inline .btn {
	border: 2px solid $primary;
}

@media (max-width: 992px) {
	.search-show .search-element {
		background: $primary;
	}
}

.slide-item.active {
	color: $primary;
}

.btn-absolute .btn-primary:hover {
	background: $primary  !important;
}

.search-show .search-element.search-element1 {
	background: $primary;
}

.jsr_bar {
	background-color: $primary;
}

.cal1 .clndr {
	.clndr-table tr .day {

		&.today,
		&.my-today {
			color: $primary  !important;
			background: none;
		}
	}

	.clndr-controls .clndr-control-button {

		.clndr-previous-button,
		.clndr-next-button {
			color: $white;
			background: $primary
		}
	}
}

/*----pulgins---*/

/*accrodion*/

.resp-tab-active {
	border: 1px solid $primary  !important;
	border-block-start: 4px solid $primary  !important;
}

.resp-tab-content {
	border-block-start: $primary;
}


/*Date Range Picker*/

.daterangepicker {
	td.active {
		background-color: $primary;

		&:hover {
			background-color: $primary;
		}
	}

	.input-mini.active {
		border: 1px solid $primary;
	}
}

.ranges li {
	&:hover {
		background-color: $primary;
		border: 1px solid $primary;
		color: $white;
	}

	&.active {
		background-color: $primary  !important;
		border: 1px solid $primary  !important;
		color: $white;
	}
}

/*Form Wizard*/

.sw-theme-dots>ul.step-anchor>li.active>a {
	color: $primary;

	&:after {
		background: $primary;
	}
}

.sw-theme-default>ul.step-anchor>li {
	&.clickable>a:hover {
		color: $primary  !important;
	}

	>a::after {
		background: $primary;
	}

	&.active>a {
		background: $primary  !important;
	}

	&.done>a::after {
		background: $primary;
	}
}

.form-group {
	&.is-focused.label-placeholder label {
		color: $primary;

		&.control-label {
			color: $primary;
		}
	}

	&.has-error {

		label.control-label,
		.help-block {
			color: $primary;
		}
	}
}


label.control-label {
	color: $primary;
}

.form-group {

	// .checkbox label,
	// .radio label,
	// label {
	// 	color: $primary;
	// }

	&.form-group-sm {
		.form-control {

			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&::-webkit-input-placeholder {
				color: $primary;
			}
		}

		.checkbox label,
		.radio label {
			color: $primary;
		}

		label {
			color: $primary;

			&.control-label {
				color: $primary;
			}
		}
	}

	&.form-group-lg {
		.form-control {

			&::-moz-placeholder,
			&:-ms-input-placeholder,
			&::-webkit-input-placeholder {
				color: $primary;
			}
		}

		.checkbox label,
		.radio label {
			color: $primary;
		}

		label {
			color: $primary;

			&.control-label {
				color: $primary;
			}
		}
	}
}

/*Calendar*/

.fc button {
	background: $primary;
}

/*horizontal menu*/

.horizontalMenu>.horizontalMenu-list>li> {
	ul.sub-menu>li> {

		a:hover,
		ul.sub-menu>li>a:hover {
			color: $primary;
		}
	}

	.horizontal-megamenu .link-list li a:hover {
		color: $primary;
	}
}

@media only screen and (max-width: 991px) {
	.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu li:hover>a {
		color: $primary  !important;
	}
}

.hor-menu .horizontalMenu>.horizontalMenu-list>li>a {
	&.active {
		color: $white;
		background: $primary  !important;
	}

	&:hover:before {
		border-block-end: 2px solid $primary;
	}
}

/*--jvector Map--*/

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
	background: $primary;
}

.ms-choice {
	color: $primary;
}

/*--tabs--*/

.wrapper>h1 span {
	border-block-end: 2px solid $primary;
	color: $primary;
}

.tab_wrapper {
	.controller span {
		background: $primary;
	}

	.content_wrapper .accordian_header.active {
		color: $primary;

		&:after {
			background: $primary;
		}

		.arrow {
			transform: rotate(45deg);
			margin-block-start: 5px;
		}
	}

	&.left_side>ul li.active:after {
		background: $primary;
	}
}

/*tynmce Skins*/

.mce-grid {
	td.mce-grid-cell div:focus {
		border-color: $primary;
	}

	a {

		&:hover,
		&:focus {
			border-color: $primary;
		}
	}
}

.mce-grid-border a {

	&:hover,
	&.mce-active {
		border-color: $primary;
		background: $primary;
	}
}

.mce-primary {
	background-color: $primary;

	&.mce-active {
		background-color: $primary;

		&:hover {
			background-color: $primary;
		}
	}

	&:not(.mce-disabled):active {
		background-color: $primary;
	}
}

.mce-menu-item.mce-disabled:hover {
	background: $primary;
	color: $white  !important;
}

.mce-menu-item-normal.mce-active {
	background-color: $primary;
}

.mce-menu-item {

	&:hover,
	&.mce-selected,
	&:focus {
		background-color: $primary;
	}
}

.mce-textbox {

	&:focus,
	&.mce-focus {
		border-color: $primary;
	}
}

/*richText*/

.richText .richText-editor:focus {
	border-inline-start: $primary solid 5px;
}

/*Rightside menu*/

.slide-menu li a:hover {
	color: $primary;
}

.side-menu__item {

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		color: $primary;
		// background: $white-1;
	}

	&.active .side-menu__icon,
	&:hover .side-menu__icon,
	&:focus .side-menu__icon {
		color: $primary;
	}
}

.slide-menu {
	li.active>a {
		color: $primary;
	}

	a.active {
		color: $primary;
	}
}

.slide-item {

	&.active,
	&:hover,
	&:focus {
		text-decoration: none;
		color: $primary;
	}
}

.side-menu {
	.side-menu__icon {
		color: $primary;
	}
}

.form-control:focus {
	border-color: rgba(70, 127, 207, 0.6);
}

.btn-outline-default {

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
	}

	&:not(:disabled):not(.disabled) {

		&:active:focus,
		&.active:focus {
			box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
		}
	}
}

.show>.btn-outline-default.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.1);
}

.selectgroup-input:focus+.selectgroup-button {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.form-range {

	&::-webkit-slider-thumb:focus,
	&::-ms-thumb:focus {
		box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 2px rgba(70, 127, 207, 0.25);
	}
}

.btn-blue {

	&:focus,
	&.focus {
		box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
	}

	&:not(:disabled):not(.disabled) {

		&:active:focus,
		&.active:focus {
			box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
		}
	}
}

.show>.btn-blue.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5);
}

.link-overlay-bg {
	background: rgba(70, 127, 207, 0.8);
}

.app-sidebar .side-menu__item.active {
	color: $primary;
}

.clock-presenter {
	background: $primary  !important;
}

.resp-vtabs .first-sidemenu li {
	&.resp-tab-active {
		&:before {
			content: '';
			position: absolute;
			width: 4px;
			height: 100%;
			inset-inline-start: 0;
			inset-block: 0;
			background: $primary;
		}
	}

	&.active {
		border-inline-start: 4px solid $primary  !important;
		background: rgb(70, 127, 207) !important;
		color: $white;
		border-block-end: 0 !important;
	}

	&.resp-tab-active {
		color: $white;
	}
}

.page-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 1.5rem 0;
	-ms-flex-wrap: wrap;
	justify-content: space-between;
	border-radius: 4px;
	position: relative;
	min-height: 50px;
}

.page-title {
	margin: 0;
	font-size: 25px;
	font-weight: 600;
	line-height: 2.5rem;
	position: relative;
}

.page-title-icon {
	color: #cbd2dc;
	font-size: 1.25rem;
}

.page-subtitle {
	font-size: 0.8125rem;
	color: #6e7687;
	margin-inline-start: 2rem;
	position: relative;

	a {
		color: inherit;
	}
}

.page-options {
	margin-inline-start: auto;
}

.page-breadcrumb {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	background: none;
	padding: 0;
	margin-inline: 0;
	margin-block-start: 1rem;
	margin-block-end: 0;
	font-size: 0.875rem;
}

.page-description {
	margin-inline: 0;
	margin-block-start: 0.25rem;
	margin-block-end: 0;
	color: #6e7687;

	a {
		color: inherit;
	}
}

.page-single {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
}

@media (min-width: 768px) {
	.page-breadcrumb {
		margin-inline: 0;
		margin-block-start: -0.5rem;
		margin-block-end: 0;
	}
}

.page-breadcrumb .breadcrumb-item {
	color: #cbd2dc;

	&.active {
		color: #6c757d !important;
	}
}

.features {
	overflow: hidden;

	h2 {
		font-weight: 600;
		margin-block-end: 12px;
		text-align: center;
		font-size: 2.2em;
	}

	h3 {
		font-size: 24px;
		font-weight: 600;
	}

	span {
		color: #43414e;
		display: block;
		font-weight: 400;
		text-align: center;
	}
}

.feature {
	.feature-svg {
		width: 25%;
	}

	.feature-svg3 {
		width: 12%;
	}
}

.feature {
	.border {
		position: relative;
		display: inline-block;
		width: 2.5em;
		height: 2.5em;
		line-height: 2.5em;
		vertical-align: middle;
		padding-block-start: 0px;
		border-radius: 50%;
		color: $white;
	}

	p {
		margin-block-end: 0;
	}
}

.page {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100vh;
	overflow: clip;
}

.page-main {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.fade {
	transition: opacity 0.15s linear;

	&:not(.show) {
		opacity: 0;
	}
}

.social {
	i {
		font-size: 40px;
	}

	.social-icon i {
		font-size: 14px;
	}
}

.instagram {
	background: $pink;
	width: 100%;
}

.linkedin {
	background: $info;
	width: 100%;
}

.twitter {
	background: $blue;
	width: 100%;
}

.facebook {
	background: $purple;
	width: 100%;
}

.statistics-box {
	position: relative;
	padding-inline-start: 80px;
	text-align: end;
	padding-block-start: 14px;
	min-height: 80px;

	.ico {
		&.small {
			font-weight: 900;
			font-size: 42px;
		}

		position: absolute;
		inset-block-start: 0px;
		inset-inline-start: 0px;
		width: 80px;
		height: 80px;
		font-size: 70px;
		line-height: 80px;
		text-align: center;
	}

	h2 {
		font-weight: 600 !important;
	}
}

.bg-account {
	.page {
		position: relative;
	}

	.page-content {
		z-index: 10;
	}
}

.sw-theme-dots {
	> ul.step-anchor > li:focus,
	.nav-item {
		background: transparent !important;
	}
}

.profile-cover {
	width: 100%;

	.cover {
		position: relative;
		border: 10px solid $white;
		border-radius: 7px;

		.inner-cover {
			overflow: hidden;
			height: auto;

			img {
				border: 1px solid transparent;
				text-align: center;
				width: 100%;
			}

			.cover-menu-mobile {
				position: absolute;
				inset-block-start: 10px;
				inset-inline-end: 10px;

				button i {
					font-size: 17px;
				}
			}
		}

		ul.cover-menu {
			padding-inline-start: 150px;
			position: absolute;
			overflow: hidden;
			inset-inline-start: 1px;
			inset-block-end: 0px;
			float: left;
			width: 100%;
			margin: 0px;
			background: none repeat scroll 0% 0% $black-3;

			li {
				display: block;
				float: left;
				margin-block-end: 0px;
				padding: 0px 10px;
				line-height: 40px;
				height: 40px;
				-moz-transition: all 0.3s;
				-o-transition: all 0.3s;
				-webkit-transition: all 0.3s;
				transition: all 0.3s;

				&:hover {
					background-color: rgba(0, 0, 0, 0.44);
				}

				&.active {
					background-color: rgba(0, 0, 0, 0.64);
				}

				a {
					color: $white;
					display: block;
					height: 40px;
					line-height: 40px;
					text-decoration: none;

					i {
						font-size: 18px;
					}
				}
			}
		}
	}

	.profile-body {
		margin-inline: auto;
		margin-block-start: 0;
		margin-block-end: 10px;
		position: relative;
	}

	.profile-timeline {
		padding: 15px;
	}
}

.profile-card {
	.widget-img {
		position: absolute;
		width: 64px;
		height: 64px;
		inset-block-start: -32px;
		inset-inline-start: 50%;
		margin-inline-start: -32px;
	}

	.widget-bg {
		position: absolute;
		inset-block-start: 0;
		inset-inline-start: 0;
		min-width: 100%;
		min-height: 100%;
	}

	.widget-body {
		padding-block-start: 50px;
		padding-inline: 15px;
		padding-block-end: 15px;
		position: relative;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.fade {
		transition: none;
	}
}

.collapse:not(.show) {
	display: none;
	background: $white;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
	.collapsing {
		transition: none;
	}
}

.notifyimg {
	color: $white;
	float: left;
	height: 40px;
	line-height: 44px;
	margin-block-end: 10px;
	text-align: center;
	vertical-align: middle;
	width: 40px;
	border-radius: 50%;
}

.block {
	display: block !important;
}

.user-semi-title {
	font-size: 12px;
}

.box-shadow-0 {
	box-shadow: none;
}

.defaultheader {
	.input-icon-addon {
		color: $default-color !important;
	}

	.form-control.header-search::placeholder {
		color: #c7c5ca !important;
	}
}

.sparkline {
	display: inline-block;
	height: 2rem;
}

.ie-warning,
.login-content,
.login-navigation {
	text-align: center;
}

.pt-inner {
	text-align: center;

	.pti-footer > a {
		text-align: center;
	}
}

.social-links li a {
	background: #f8f8f8;
	border-radius: 50%;
	color: #cbd2dc;
	display: inline-block;
	height: 1.75rem;
	width: 1.75rem;
	line-height: 1.75rem;
	text-align: center;
}

@-webkit-keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.32;
	}
}

@keyframes status-pulse {
	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: 0.32;
	}
}

.status-icon {
	content: "";
	width: 0.5rem;
	height: 0.5rem;
	display: inline-block;
	background: currentColor;
	border-radius: 50%;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	margin-block-end: 0.375rem;
	vertical-align: middle;
}

.status-animated {
	-webkit-animation: 1s status-pulse infinite ease;
	animation: 1s status-pulse infinite ease;
}

.chips {
	margin-inline: 0;
	margin-block-start: 0;
	margin-block-end: -0.5rem;
}

.team i {
	margin-inline-start: 10px;
	float: right;
}

.chips .chip {
	margin-block-start: 0;
	margin-inline-end: 0.5rem;
	margin-block-end: 0.5rem;
	margin-inline-start: 0;
}

.chip {
	display: inline-block;
	height: 2rem;
	line-height: 2rem;
	font-size: 0.875rem;
	font-weight: 500;
	color: #6e7687;
	padding: 0 0.75rem;
	border-radius: 1rem;
	background-color: #e8ebf9;
	transition: 0.3s background;

	.avatar {
		float: left;
		margin-block: 0;
		margin-inline-end: 0.5rem;
		margin-inline-start: -0.75rem;
		height: 2rem;
		width: 2rem;
		border-radius: 50%;
	}
}

a.chip:hover {
	color: $white;
	text-decoration: none;
}

.emp-tab table {
	margin-block-end: 0;
	border: 0;
}

.stamp {
	color: $white;
	background: $gray;
	display: inline-block;
	min-width: 2rem;
	height: 2rem;
	padding: 0 0.25rem;
	line-height: 2rem;
	text-align: center;
	font-weight: 600;
}

.stamp-md {
	min-width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
}

.stamp-lg {
	min-width: 3rem;
	height: 3rem;
	line-height: 3rem;
}

.example {
	padding: 1.5rem;
	font-size: 0.9375rem;
	background: transparent;
}

.example-bg {
	background: #f9f9f9;
}

.example-column {
	margin: 0 auto;

	> .card:last-of-type {
		margin-block-end: 0;
	}
}

.example-column-1 {
	max-width: 20rem;
}

.example-column-2 {
	max-width: 40rem;
}

.colorinput {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.colorinput-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.colorinput-color {
	display: inline-block;
	width: 1.75rem;
	height: 1.75rem;
	border: 1px solid rgba(107, 122, 144, 0.3);
	color: $white;
	box-shadow: 0 1px 2px 0 $black-05;
	border-radius: 4px;

	&:before {
		content: "";
		opacity: 0;
		position: absolute;
		inset-block-start: 0.25rem;
		inset-inline-start: 0.25rem;
		height: 1.25rem;
		width: 1.25rem;
		transition: 0.3s opacity;
		background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E")
			no-repeat center (center / 50%) 50%;
	}
}

.colorinput-input:checked ~ .colorinput-color:before {
	opacity: 1;
}

svg {
	-ms-touch-action: none;
	touch-action: none;
}

.mcs-horizontal-example {
	overflow-x: auto;
	white-space: nowrap;

	.item {
		display: inline-block;
	}
}

.hover15 .card {
	position: relative;

	&::before {
		position: absolute;
		inset-block-start: 50%;
		inset-inline-start: 50%;
		z-index: 2;
		display: block;
		content: "";
		width: 0;
		height: 0;
		background: $white-2;
		border-radius: 100%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		opacity: 0;
	}

	&:hover::before {
		-webkit-animation: circle 0.75s;
		animation: circle 0.75s;
	}
}

@-webkit-keyframes circle {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 1;
	}

	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}

@keyframes circle {
	0% {
		opacity: 1;
	}

	40% {
		opacity: 1;
	}

	100% {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}

ul li ul:hover {
	visibility: visible;
	opacity: 1;
	display: block;
}

ul {
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}

	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}

	to {
		background-position: 0 0;
	}
}

.btn-close {
	float: right;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.5;
	color: $default-color;
	text-shadow: 0 1px 0 $white;
	opacity: 0.5;
	transition: 0.3s color;

	&:hover,
	&:focus {
		color: $default-color;
		text-decoration: none;
		opacity: 0.75;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.fixed-top {
	position: fixed;
	inset-block-start: 0;
	inset-inline: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	inset-inline: 0;
	inset-block-end: 0;
	z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
	.sticky-top {
		position: -webkit-sticky;
		position: sticky;
		inset-block-start: 0;
		z-index: 1020;
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable {
	&:active,
	&:focus {
		position: static;
		width: auto;
		height: auto;
		overflow: visible;
		clip: auto;
		white-space: normal;
	}
}

body.fixed-header .page {
	padding-block-start: 4.5rem;
}

@media (min-width: 1600px) {
	body.aside-opened .page {
		margin-block-end: 22rem;
	}
}

.content-heading {
	font-weight: 400;
	margin-inline: 0;
	margin-block-start: 2rem;
	margin-block-end: 1.5rem;
	font-size: 1.25rem;
	line-height: 1.25;

	&:first-child {
		margin-block-start: 0;
	}
}

.aside {
	position: fixed;
	inset-block: 0;
	inset-inline-end: 0;
	width: 22rem;
	background: $white;
	border-inline-start: 1px solid rgba(107, 122, 144, 0.3);
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	z-index: 100;
	visibility: hidden;
	box-shadow: 0 5px 20px $black-1;
}

.dropgroupicons {
	.dropdown-menu {
		width: 60px;
	}

	width: 50px;
}

@media (min-width: 1600px) {
	body.aside-opened .aside {
		visibility: visible;
	}
}

.aside-body {
	padding: 1.5rem;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	overflow: auto;
}

.center-block {
	margin-block-end: auto;
	margin-inline-start: auto;
	float: inherit !important;
}

.tracking-tight {
	letter-spacing: -0.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: 0.05em !important;
}

.leading-none {
	line-height: 1 !important;
	padding-block-start: 11px;
}

.leading-tight {
	line-height: 1.25 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 3 !important;
}

.o-auto {
	overflow: auto !important;
}

.o-hidden {
	overflow: hidden !important;
}

@media (max-width: 480px) {
	.list-media .info {
		.text-end {
			display: none;
		}

		padding-block-end: 15px;
	}

	.worldh {
		height: 180px !important;
	}

	.m-portlet {
		display: none;
	}

	.searching.mt-3 {
		margin-block-start: 0.6rem !important;
	}

	.tabs-menu ul li {
		width: 100%;
		margin: 15px;
	}

	#smartwizard .nav-link {
		padding: 22px !important;
	}

	.cal1 .clndr .clndr-table tr {
		height: 50px !important;
	}

	.tabs-menu1 ul li {
		width: 100%;
		margin: 15px;

		a {
			width: 100%;
		}
	}

	.wizard-card .nav-pills > li {
		text-align: center;
		padding: 9px !important;
	}

	.form-control.form-select.w-auto {
		display: none;
	}

	.mail-inbox .badge {
		margin: 0;
	}

	.header-brand {
		line-height: 2.5rem !important;
	}

	.jumbotron .display-3 {
		font-size: 2.5rem;
	}

	.mail-option .hidden-phone {
		display: none;
	}

	.construction .input-group {
		width: 100% !important;
	}
}

@media (max-width: 988px) {
	.note-btn-group .btn {
		font-size: 13px !important;
	}

	.note-toolbar {
		display: block !important;
	}
}

@media (max-width: 360px) {
	#smartwizard .nav-link {
		padding: 15px !important;
	}
}

@media (max-width: 480px) {
	.app-header .header-brand {
		min-width: auto;
		margin-inline-start: 2px;
	}

	.app-header1 .header-brand {
		min-width: auto;
	}

	.about-img {
		margin: -11px -15px !important;
	}

	.chat .dropdown-menu li {
		padding: 7px;
		text-align: start;
	}
}

@media (max-width: 767px) {
	.card-tabs {
		display: block !important;
	}

	.header-brand {
		line-height: 2.7rem;
	}

	.header .input-icon.mt-2 {
		margin-block-start: 5px !important;
	}

	.footer .privacy {
		text-align: center !important;
	}

	.shop-dec .col-md-6 {
		&.pe-0 {
			padding-inline-end: 0.75rem !important;
		}

		&.ps-0 {
			padding-inline-start: 0.75rem !important;
		}
	}

	.float-end.shop-pagination,
	.shop-count {
		float: none !important;
	}
}

@media (max-width: 768px) {
	.nav-tabs .nav-link {
		width: 100%;
	}

	.page-subtitle {
		display: none;
	}

	.ticket-card {
		.col-md-1 {
			width: 100%;
			display: block;
		}

		img {
			display: block;
			text-align: Center;
			margin: 0 auto;
		}
	}

	.dataTables_wrapper {
		.dataTables_info,
		.dataTables_paginate {
			margin-block-start: 10px !important;
		}
	}

	div.dataTables_wrapper div.dataTables_paginate ul.pagination {
		justify-content: center !important;
	}

	.page-title {
		line-height: 1.5rem;
		font-size: 20px;
	}

	.carousel-caption {
		display: none;
	}

	.demo-gallery > ul > li {
		width: 100% !important;
	}

	ul.inbox-pagination li span {
		display: none;
	}
}

@media (max-width: 375px) {
	.page-title {
		font-size: 20px !important;
	}

	.worldh {
		height: 170px !important;
	}
}

@media (min-width: 320px) and (max-width: 480px) {
	.Revenue {
		font-size: 0.69rem !important;
	}

	.card-counter i {
		font-size: 3rem !important;
	}
}

@media (min-width: 481px) and (max-width: 528px) {
	.clock-presenter {
		width: 60px !important;
		height: 60px !important;
	}

	.countdown span:first-child {
		font-size: 25px;
		font-weight: 500;
	}
}

@media (min-width: 320px) {
	.Revenue1 {
		font-size: 0.8rem !important;
	}
}

@media (max-width: 320px) {
	.Revenue {
		font-size: 1rem !important;
	}
}

@media (max-width: 360px) {
	.worldh {
		height: 190px !important;
	}

	.countdown {
		font-size: 9px;
	}

	#timer-countup,
	#timer-countinbetween,
	#timer-countercallback {
		font-size: 18px;
	}
}

@media (max-width: 360px) {
	.Revenue {
		font-size: 0.65rem !important;
	}
}

@media (max-width: 990px) {
	.header-toggler {
		margin-block-start: 20px;
		margin-inline-end: 6px;
		margin-block-end: 0;
		margin-inline-start: 6px;
	}

	.nav-tabs .nav-link {
		width: 100%;
	}

	form.convFormDynamic button.submit {
		margin: 4px !important;
	}

	.messages-list .media {
		padding: 9px !important;
	}

	.nav-tabs {
		z-index: 98;
	}

	#smartwizard-3 .nav-tabs {
		z-index: 8;
	}

	.footer {
		.privacy {
			text-align: center !important;
			margin-block-end: 10px;
		}

		.social {
			float: none;
		}
	}
}

.z-index-10 {
	z-index: 10 !important;
}

@media (max-width: 1024px) {
	.app-header .dropdown-toggle::after {
		display: none;
	}
}

.tilebox-one i {
	font-size: 62px;
}

.reg {
	text-align: center;
	font-size: 50px;
	color: #2e1170;
	float: right;
}

ul li .legend-dots {
	width: 1rem;
	height: 1rem;
	border-radius: 100%;
	display: inline-block;
	vertical-align: text-bottom;
	margin-block-end: 0.5rem;
}

.legend li {
	padding: 5px;
	float: left;
}

.visitor-list {
	p {
		font-size: 12px;
	}

	i {
		font-size: 20px;
	}
}

.sales-relative {
	position: relative;
}

@media (min-width: 320px) and (max-width: 1024px) {
	.sales {
		padding-block-end: 7px;
	}
}

@-webkit-keyframes indeterminate {
	0% {
		inset-inline-start: -35%;
		inset-inline-end: 100%;
	}

	100%,
	60% {
		inset-inline-start: 100%;
		inset-inline-end: -90%;
	}
}

@keyframes indeterminate {
	0% {
		inset-inline-start: -35%;
		inset-inline-end: 100%;
	}

	100%,
	60% {
		inset-inline-start: 100%;
		inset-inline-end: -90%;
	}
}

@-webkit-keyframes indeterminate-short {
	0% {
		inset-inline-start: -200%;
		inset-inline-end: 100%;
	}

	100%,
	60% {
		inset-inline-start: 107%;
		inset-inline-end: -8%;
	}
}

@keyframes indeterminate-short {
	0% {
		inset-inline-start: -200%;
		inset-inline-end: 100%;
	}

	100%,
	60% {
		inset-inline-start: 107%;
		inset-inline-end: -8%;
	}
}

@-webkit-keyframes loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes loader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.list.d-flex img {
	width: 60px;
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: white;
	border-radius: 5px;
}

#copy {
	background: none;
	color: white;
	font-weight: bold;
	padding: 8px 25px;
	border: 0;
}

.user-button {
	padding: 3px 2px;
	border-radius: 3px;
	font-size: 17px;

	i {
		border-radius: 50%;
		font-size: 15px;
		width: 30px;
		height: 30px;
		line-height: 2;
	}
}

.bd-example {
	position: relative;
	padding: 1rem;
	margin: 1rem -1rem;
	border-inline-width: 0;
	border-block-start-width: 0.2rem;
	border-block-end-width: 0;
}

.more-less {
	float: right;
	color: #212121;
}

.wrap {
	box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px $black-2, 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
}

a {
	&:focus,
	&:hover,
	&:active {
		outline: 0;
		text-decoration: none;
	}
}

.link-overlay {
	position: relative;

	&:hover .link-overlay-bg {
		opacity: 1;
	}
}

.link-overlay-bg {
	position: absolute;
	inset-block: 0;
	inset-inline: 0;
	display: -ms-flexbox;
	display: flex;
	color: $white;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.25rem;
	opacity: 0;
	transition: 0.3s opacity;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	&:before,
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

/*-----Back to Top-----*/

#back-to-top {
	color: $white;
	position: fixed;
	inset-block-end: 20px;
	inset-inline-end: 20px;
	z-index: 99;
	display: none;
	text-align: center;
	-o-border-radius: 2px;
	z-index: 1000;
	height: 50px;
	width: 50px;
	background-repeat: no-repeat;
	background-position: center;
	transition: background-color 0.1s linear;
	-moz-transition: background-color 0.1s linear;
	-webkit-transition: background-color 0.1s linear;
	-o-transition: background-color 0.1s linear;
	border-radius: 12px;

	i {
		padding-block-start: 15px;
		font-size: 16px;
	}

	&:hover {
		background: linear-gradient(to bottom right, $white, $white) !important;
	}
}

#back-to-top {
	background: $primary !important;

	&:hover {
		background: $primary-02 !important;
		color: $primary;
		border: 2px solid $primary-04;
	}
}

/*-----Back to Top-----*/

@media (max-width: 380px) {
	.tab-pane .profile-content .media-body {
		margin-block-start: 0px;
		font-size: 12px;
		margin-inline-start: 8px !important;
	}

	.footer-payments a {
		color: #cbd2dc;
		text-decoration: none;
		font-size: 1.5rem !important;
	}
}

@media (max-width: 480px) {
	.ah-actions {
		float: none !important;
		text-align: center;
		display: inline-block;
	}

	.tab-pane .profile-content .media-body {
		margin-block-start: 0 !important;

		.btn-square {
			margin-block-start: 6px;
		}
	}
}

@media (max-width: 480px) and (min-width: 360px) {
	.clock-presenter {
		margin-block-start: 0 !important;
		margin-inline-end: 5px !important;
		margin-block-end: 45px !important;
		margin-inline-start: 5px !important;
		width: 60px !important;
		height: 60px !important;
		border-radius: 50%;
		float: left;
		margin: 0 20px;
	}

	.countdown span:first-child {
		font-size: 25px !important;
	}

	.example {
		padding: 1rem;
	}
}

@media (max-width: 480px) and (min-width: 320px) {
	.countdown {
		font-size: 11px;
	}

	#timer-countup,
	#timer-countinbetween,
	#timer-countercallback {
		font-size: 18px;
	}
}

@media (max-width: 480px) {
	.page-header {
		.input-group {
			margin-block-start: 15px;
			margin-block-end: 5px;
		}

		.breadcrumb {
			padding-inline-start: 0;
		}
	}

	.app-sidebar__toggle,
	.app-header .dropdown {
		border-inline-start: 0 !important;
	}

	.sidenav-toggled .app-sidebar__user .avatar-xl {
		width: 3rem !important;
		height: 3rem !important;
		line-height: 2rem;
		font-size: 1rem;
		margin-block-end: 0;
	}

	.sidebar-mini.sidenav-toggled .app-sidebar {
		.user-button {
			display: inline-block !important;
		}

		.user-info {
			display: block !important;
			margin-block-start: 6px;
		}
	}

	.chat {
		.justify-content-start,
		.justify-content-end {
			margin-block-end: 35px !important;
		}

		.sort,
		.clock {
			display: none !important;
		}
	}

	.construction h4 {
		line-height: 1.6;
	}

	.nav-link.icon.full-screen-link {
		display: none !important;
	}
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	width: 50px;
	height: 100%;
	padding-inline-end: 10px;
	padding-block-start: 19px;
	cursor: pointer;
	text-align: end;
}

.message-feed.right {
	text-align: end;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}

.ms-body {
	background: $white;
	border-radius: 7px;
}

#ms-menu-trigger {
	user-select: none;
	position: absolute;
	inset-inline-start: 0;
	inset-block-start: 0;
	width: 50px;
	height: 100%;
	padding-inline-end: 10px;
	padding-block-start: 24px;
	cursor: pointer;
	text-align: end;
}

.message-feed.right {
	text-align: end;
}

#ms-menu-trigger,
.toggle-switch {
	-webkit-user-select: none;
	-moz-user-select: none;
}

.rotation {
	-webkit-animation: rotation 2s infinite linear;
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
	}
}

.overflow-hidden {
	overflow: hidden;
}

.user-profile .user-pro-body .u-dropdown {
	color: $white;
}

.job-box-filter {
	label {
		width: 100%;
	}

	select.input-sm {
		display: inline-block;
		max-width: 120px;
		margin: 0 5px;
		border: 1px solid rgba(107, 122, 144, 0.3);
		border-radius: 2px;
		height: 34px;
		font-size: 15px;
	}

	label input.form-control {
		max-width: 200px;
		display: inline-block;
		border: 1px solid rgba(107, 122, 144, 0.3);
		border-radius: 2px;
		margin-inline-start: 5px;
		font-size: 15px;
	}

	padding: 12px 15px;
	border-block-end: 1px solid rgba(107, 122, 144, 0.3);
}

.job-box {
	display: inline-block;
	width: 100%;
	padding: 0px;
}

.job-box-filter {
	a.filtsec {
		margin-block-start: 8px;
		display: inline-block;
		margin-block-end: 15px;
		padding: 4px 10px;
		transition: all ease 0.4s;
		background: #edf0f3;
		border-radius: 50px;
		font-size: 13px;
		color: #81a0b1;
		border: 1px solid #e2e8ef;

		&.active {
			color: $white;
			background: #16262c;
			border-color: #16262c;
		}

		&:hover,
		&:focus {
			color: $white;
			background: #07b107;
			border-color: #07b107;
		}

		&:hover i,
		&:focus i {
			color: $white;
		}
	}

	h4 i {
		margin-block-end: 10px;
	}
}

.GradientlineShadow {
	-webkit-filter: drop-shadow(0 -8px 4px $black-2);
	filter: drop-shadow(0 -8px 4px $black-2);
}

.donutShadow {
	-webkit-filter: drop-shadow((-1px) 0px 2px rgb(206, 209, 224));
	filter: drop-shadow((-1px) 0px 2px rgb(206, 209, 224));
}

.isActive {
	.search-label {
		transform: translateX(246px);
	}

	svg,
	&.full svg {
		stroke-dashoffset: -65;
		stroke-dasharray: 141.305 65;
		transform: translateX(0);
	}
}

.full {
	.search-label {
		transform: translateX(246px);
	}

	svg {
		stroke-dashoffset: 0;
		stroke-dasharray: 64.6 206.305;
		transform: translateX(0);
	}
}

#drag-1,
#drag-2 {
	width: 25%;
	height: 100%;
	min-height: 6.5em;
	margin: 10%;
	background-color: #29e;
	color: $white;
	border-radius: 0.75em;
	padding: 4%;
	user-select: none;
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px);
}

.lockscreen {
	background: inherit;
	width: 300px;
	height: 280px;
	overflow: hidden;

	&:before {
		content: "";
		width: 350px;
		height: 400px;
		background: inherit;
		position: absolute;
		inset-inline-start: -25px;
		inset-inline-end: 0;
		inset-block-start: -25px;
		inset-block-end: 0;
		box-shadow: inset 0 0 0 200px $white-4;
		filter: blur(1px);
	}
}

.service i {
	font-size: 23px;
}

.sparkline_area canvas,
.sparkline_area2 canvas,
.sparkline_area3 canvas {
	position: relative;
	inset-block-end: -3px;
}

.countlist {
	li {
		padding-block: 0;
		padding-inline-end: 30px;
		padding-inline-start: 0;
		font-size: 14px;
		text-align: start;
	}

	h4 {
		margin: 0;
		font-weight: 400;
		line-height: 20px;
	}

	li {
		&:last-child {
			padding: 0;
		}

		small {
			font-size: 12px;
			margin: 0;
		}
	}
}

@media (max-width: 320px) {
	.countlist li {
		padding-block: 0;
		padding-inline-end: 20px;
		padding-inline-start: 0;
	}
	.rating-stars .rating-stars-container .rating-star {
		font-size: 30px;
	}
	.construction .font {
		font-size: 2.5rem !important;
	}
	.footer-payments a {
		color: #cbd2dc;
		text-decoration: none;
		font-size: 1.2rem !important;
	}
}
.country-Flag {
	width: 1.7rem;
	height: 1.2rem;
	line-height: 0.1rem;
	font-size: 0.75rem;
}
.Flag {
	width: 2rem;
	height: 1.5rem;
}
.country-flag1 {
	padding-block-end: 4px;
}
.clock-presenter {
	width: 60px;
	height: 60px;
	border-radius: 50%;
	float: left;
	margin: 0 20px;
	background: #7e6ee6;
	border-radius: 7px;
}
#campaign {
	width: 105% !important;
	height: 107px !important;
	inset-block-end: -15px;
	position: relative;
	inset-inline-start: -10px;
}
.search-element {
	.Search-icon {
		position: absolute;
		inset-block-start: 23px;
		inset-inline-end: 15px;
	}

	.form-group {
		&.is-empty {
			display: -webkit-inline-box;
			margin-block-end: 0;

			&.form-control::placeholder {
				color: #ccc !important;
			}

			.form-control::-webkit-input-placeholder {
				color: #cbd2dc !important;
			}
		}

		&.is-focused {
			display: -webkit-inline-box;
			margin-block-end: 0;
			box-shadow: none !important;
			border-color: inherit !important;
		}
	}
}

@media (max-width: 768px) {
	.header .form-inline.search-wizard .btn {
		margin-block-start: 5px;
	}

	.search-element .form-group.is-empty {
		display: unset;
		margin-block-start: 4px;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.9);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.9);
		box-shadow: 0 0 0 0 rgba(34, 192, 60, 0.7);
	}

	70% {
		-moz-box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
		box-shadow: 0 0 0 10px rgba(34, 192, 60, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
		box-shadow: 0 0 0 0 rgba(34, 192, 60, 0);
	}
}

.opacity {
	opacity: 0.5;
}

.earnings canvas {
	width: 250px;
}

.horizontal-dropdown {
	.dropdown-menu {
		position: absolute;
		transform: translate3d(-142px, 62px, 0px) !important;
		inset-block-start: 0px;
		inset-inline-start: 0px;
		will-change: transform;
	}

	.dropdown-menu-arrow.dropdown-menu-end::before {
		inset-inline-start: auto !important;
		inset-inline-end: 12px !important;
	}
}

.search-show .search-element input.form-control {
	width: 100% !important;
	display: block;
}

@media (max-width: 495px) {
	.nested-media {
		.media {
			display: block;
		}

		.media-body {
			padding-block-start: 22px;
			margin-inline-start: 0px;
		}
	}
}

.nested-media .list-unstyled li {
	border-block-end: 0;
}

@media (max-width: 992px) {
	.profile-cover .cover ul.cover-menu {
		padding-inline-start: 0px;
	}
}

.page-single .justify-content-center .card {
	box-shadow: 0 1px 15px 1px rgba(99, 99, 101, 0.5) !important;
}

.circle-tile {
	margin-block-end: 15px;
	text-align: center;
}

.circle-tile-heading {
	border: 3px solid $white-8;
	border-radius: 100%;
	color: $white;
	height: 80px;
	margin-block-start: 0;
	margin-inline: auto;
	margin-block-end: -40px;
	position: relative;
	transition: all 0.3s ease-in-out 0s;
	width: 80px;

	i {
		line-height: 80px;
	}
}

.circle-tile-content {
	padding-block-start: 50px;
	border-radius: 3px;
	margin-block-end: 1.5rem;
}

.circle-tile-number {
	font-size: 26px;
	font-weight: 600;
	line-height: 1;
	padding-inline: 0;
	padding-block-start: 14px;
	padding-block-end: 18px;
}

.circle-tile-description {
	text-transform: uppercase;
}

.circle-tile-footer {
	background-color: rgba(0, 0, 0, 0.08);
	color: $white-5;
	display: block;
	padding: 5px;
	transition: all 0.3s ease-in-out 0s;

	&:hover {
		background-color: $black-2;
		color: $white-5;
		text-decoration: none;
	}
}

.page-single .form-control:focus {
	border-color: rgba(107, 122, 144, 0.3) !important;
}

#external-events {
	.fc-event {
		margin: 10px 0;
		cursor: pointer;
		padding: 2px;
	}

	p {
		margin: 1.5em 0;
		font-size: 11px;

		input {
			margin: 0;
			vertical-align: middle;
		}
	}
}

#action_menu_btn {
	position: absolute;
	inset-inline-end: 36px;
	inset-block-start: 21px;
	color: $white;
	cursor: pointer;
	font-size: 20px;
}

.action_menu {
	z-index: 1;
	position: absolute;
	padding: 15px 0;
	background-color: $white;
	color: #646367;
	inset-block-start: 40px;
	inset-inline-end: 15px;
	display: none;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

// profile page styles start

.profile-bg {
	background-repeat: no-repeat !important;
	background-position: top !important;
	border-radius: 5px;
}

.profile-img {
	position: absolute;
	inset-block-start: -50px;
	inset-inline-start: 25px;
	border: 2px solid $white;
	border-radius: 8px;
}

.write-comment {
	position: relative;

	img {
		position: absolute;
		inset-block-start: 3px;
	}
}

.post-date {
	position: absolute;
	inset-inline-end: 20px;
	inset-block-end: 90px;
}

.contact-search {
	position: relative;

	input {
		&::placeholder {
			color: $black-3;
			font-size: 13px;
			font-weight: 600;
		}
	}

	.btn {
		position: absolute;
		inset-block-start: 11px;
		inset-inline-end: 10px;

		i {
			font-size: 13px !important;
		}
	}
}

// profile page styles end

.post-links {
	margin-block-end: 0;
	font-size: 12px;
	padding-inline-start: 0;

	&:after,
	&:before {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}

	> li {
		float: left;
		list-style: none;

		+ li:before {
			color: #999;
			content: "\25cf";
			padding: 0 8px;
		}

		> a {
			text-decoration: none;
			color: #70768e;

			&:hover {
				color: #70768e;
			}
		}
	}

	&.no-separator > li + li {
		margin-inline-start: 12px;

		&:before {
			content: normal;
		}
	}
}

.post-comments {
	font-size: 12px;
	padding-inline-start: 0;

	&:after,
	&:before {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}

.post-links + .post-comments {
	margin-block-start: 10px;
}

.post-comments {
	> li {
		padding: 10px;
		border-block-start: 1px solid rgba(107, 122, 144, 0.3);
		list-style: none;

		&:after,
		&:before {
			content: " ";
			display: table;
		}

		&:after {
			clear: both;
		}

		&:last-child {
			padding-block-end: 0;
		}
	}

	p:last-child {
		margin-block-end: 0;
	}

	.avatar {
		margin-block-start: 1px;
	}

	.author {
		margin-block-start: 3px;
		margin-block-end: 2px;
		color: $blue;
		margin-inline-start: 15px;
	}

	.comment-body {
		overflow: auto;
	}

	h6.author > small {
		font-size: 11px;
	}
}

.post-user {
	position: relative;

	&:after,
	&:before {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}

	img {
		border: 3px solid $white;
	}
}

.event > footer {
	margin-inline: -20px;
	margin-block-start: 20px;
	margin-block-end: 0;
	padding: 10px 20px;
	border-end-start-radius: 4px;
	border-end-end-radius: 4px;
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.event-heading {
	font-weight: 600;
	margin-inline-start: 15px;
	font-size: 15px;
	margin-block-start: 4px;

	> small {
		font-weight: 600;
		margin-inline-start: 15px;
		font-size: 15px;
		margin-block-start: 4px;
	}
}

.activities p {
	margin-inline-start: 15px;
	font-size: 0.75rem !important;
}

.event .post-comments {
	margin-inline-start: -13px;
	margin-block-end: -13px;
}

.userprofile {
	width: 100%;
	float: left;
	clear: both;

	.userpic {
		height: 100px;
		width: 100px;
		clear: both;
		margin: 0 auto;
		display: block;
		border-radius: 100%;
		box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		position: relative;

		.userpicimg {
			height: auto;
			width: 100%;
			border-radius: 100%;
		}
	}
}

.username {
	font-weight: 400;
	font-size: 20px;
	line-height: 20px;
	color: #535254;
	margin-block-start: 18px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	p {
		color: $white;
		font-size: 13px;
		line-height: 15px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.settingbtn {
	height: 30px;
	width: 30px;
	border-radius: 30px;
	display: block;
	position: absolute;
	inset-block-end: 0px;
	inset-inline-end: 0px;
	line-height: 30px;
	vertical-align: middle;
	text-align: center;
	padding: 0;
	box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.userprofile.small {
	width: auto;
	clear: both;
	margin: 0px auto;

	.userpic {
		height: 40px;
		width: 40px;
		margin-block: 0;
		margin-inline-end: 10px;
		margin-inline-start: 0;
		display: block;
		border-radius: 100%;
		box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-webkit-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		-ms-box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, 0.15);
		position: relative;
		float: left;
	}

	.textcontainer {
		float: left;
		max-width: 100px;
		padding: 0;
	}

	.userpic .userpicimg {
		min-height: 100%;
		width: 100%;
		border-radius: 100%;
	}
}

.userprofile1transparent {
	/*background: url(../../assets/img/news/img02.jpg) no-repeat top center;*/
	content: "";
}

.userprofile {
	&.social .username {
		color: #7a7d7d;
		font-size: 22px;

		+ p {
			color: #888a8a;
			opacity: 0.8;
		}
	}

	&.small .username {
		font-weight: 400;
		font-size: 16px;
		line-height: 21px;
		color: $default-color;
		margin: 0px;
		float: left;
		width: 100%;

		p {
			color: $white;
			font-size: 13px;
			float: left;
			width: 100%;
			margin: 0;
		}
	}
}

.memberblock {
	width: 100%;
	float: left;
	clear: both;
	margin-block-end: 15px;
}

.member {
	width: 100%;
	float: left;
	background: $white;
	border: 1px solid #e6eaea;
	padding: 0px;
	position: relative;
	overflow: hidden;
	text-decoration: none !important;
}

.memmbername {
	inset-block-end: -30px;
	line-height: 30px;
	padding: 5px;
	color: $default-color;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: center;
	width: 100%;
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.member {
	&:hover .memmbername {
		inset-block-end: 0;
	}

	img {
		width: 100%;
	}
}

.statistics-info .counter-icon {
	i {
		font-size: 1.2rem;
	}

	margin-block-end: 1rem;
	display: inline-flex;
	width: 4rem;
	height: 4rem;
	padding: 1.3rem 1.4rem;
	border-radius: 50%;
	text-align: center;
}

.service .item-box i {
	font-size: 30px;
}

#basicwizard .nav-tabs .nav-link {
	padding: 0.8rem;
}

div {
	&[data-acc-content] {
		display: none;
	}

	&[data-acc-step]:not(.open) {
		background: #f9f9f9;

		h5 {
			color: #74767c;
		}

		.bg-primary {
			background: #ccc !important;
		}
	}
}

.horizontal-dropdown .form-inline .form-control:foucs {
	box-shadow: 0;
}

@media (max-width: 575.98px) {
	.header-brand .header-brand-img.main-logo {
		display: none;
	}

	.nav-link.icon {
		width: 0;
		padding-block-start: 25px;
		padding-inline-end: 0;
		padding-block-end: 27px;
		padding-inline-start: 0;
	}

	.navsearch i {
		margin-inline-start: 0;
	}

	.app-header .header-brand {
		margin-block-end: 0;
		min-width: 0;
	}
}

@media (max-width: 992px) {
	.icon-iconfont .border-right {
		border-inline-end: 0 !important;
	}
}

@media (max-width: 480px) {
	.siderbar-tabs .tabs-menu ul li {
		width: auto;
		margin: 0;
	}

	.header-rightmenu .nav-link {
		padding-inline-start: 0.2rem;
		padding-inline-end: 0.2rem;
	}

	.iconfont .float-start {
		float: none !important;
	}

	.hor-topheader.app-header .header-rightmenu {
		margin-inline-start: auto !important;
	}
}

.dash1-user img {
	width: 35%;
	height: 100%;
}

.app-content .breadcrumb {
	font-size: 0.9rem;
	font-weight: 500;
}

.op1 {
	opacity: 0.1;
}

.op2 {
	opacity: 0.2;
}

.op3 {
	opacity: 0.3;
}

.op4 {
	opacity: 0.4;
}

.op5 {
	opacity: 0.5;
}

.projects-list h5 {
	font-size: 1.1rem;
	// color: $default-color;
}

.dash2 img {
	width: 45%;
}

.mh-0 {
	min-height: 0 !important;
}

.left-side {
	h2.city {
		font-size: 1.5rem;
		font-weight: 500;
		margin-inline: 0;
		margin-block-start: 0;
		margin-block-end: 20px;
		position: relative;
		color: $default-color;

		&:after {
			content: "";
			position: absolute;
			width: 20px;
			height: 2px;
			background-color: $black;
			inset-inline-start: 0;
			inset-block-end: -11px;
		}
	}

	span {
		&.currently-weather {
			text-transform: uppercase;
			font-weight: 500;
			font-size: 1rem;
		}

		&.temperature {
			font-size: 3.8em;
			font-weight: 500;
			margin-block-start: 1em;
			display: block;
			line-height: 1em;
			color: $default-color;
		}
	}
}

.Search-icon i {
	color: #cbd2dc !important;
	font-size: 15px;
}

@media (max-width: 1024px) {
	body {
		&.search-show:before,
		&.sidebar-show:before {
			content: "";
			position: fixed;
			inset-inline-start: 0;
			inset-inline-end: 0;
			width: 100%;
			height: 100%;
			background-color: $black;
			opacity: 0.6;
			z-index: 891;
		}

		&.search-show {
			.header {
				z-index: 892;
			}

			overflow: hidden;
		}

		&.sidebar-show {
			overflow: hidden;
		}
	}
}

@media (max-width: 992px) {
	.search-show .search-element {
		display: block;
		inset-inline-start: 0;
		inset-block-start: 0;
		width: 100%;
		padding: 15px;
		position: absolute !important;
		z-index: 1000;
		inset-inline-end: 30px !important;
		background: $primary;
	}

	.header-form .form-inline .search-element {
		display: none;
	}

	body.search-show .header-form .form-inline .search-element {
		display: block;
	}

	.header-form {
		.search-show .search-element {
			display: block;
			inset-inline-start: 0;
			inset-block-start: 0;
			inset-inline-end: 0;
			width: 100%;
		}

		.form-inline .search-element {
			position: absolute;
			z-index: 892;
			display: none;
		}

		.nav-link.nav-link-lg div {
			display: none;
		}

		.form-inline .search-element {
			.form-control {
				float: left;
				border-radius: 0px;
				width: 100%;
				border-radius: 7px;
				margin-block-start: 0 !important;
			}

			.btn {
				margin-block-start: 1px;
				border-start-start-radius: 0;
				border-start-end-radius: 3px;
				border-end-end-radius: 3px;
				border-end-start-radius: 3px;
			}
		}
	}

	.form-inline .form-control {
		&::-webkit-input-placeholder {
			color: #cbd2dc;
		}

		background: rgb(225, 225, 225);
		border: 1px solid rgb(225, 225, 225);
		color: #374254;
	}

	.search-element .Search-icon {
		inset-block-start: 25px !important;
		inset-inline-end: 30px !important;
	}
}

@media (max-width: 575.98px) {
	body.search-show .header .form-inline .search-element {
		display: block;
	}

	.defaultheader {
		display: none;
	}

	.weather-icon {
		inset-inline-end: -15px !important;
	}
}

@media (max-width: 320px) {
	.app-sidebar__toggle {
		padding: 6px 12px !important;
		font-weight: 300;
	}

	.nav-link.icon.siderbar-link {
		margin-block-end: 0;
	}
}

.sparkline_bar1.w-100 canvas {
	width: 100% !important;
}

@media (min-width: 321px) and (max-width: 480px) {
	.week-sales .w-6.h-6 {
		width: 1.2rem !important;
		height: 1.2rem !important;
		margin-block-start: 1rem;
	}
}

@media (max-width: 320px) {
	.week-sales .w-6.h-6 {
		width: 1rem !important;
		height: 1rem !important;
		margin-block-start: 1rem;
	}

	.app-header .nav-link {
		padding-inline-start: 0.2rem;
		padding-inline-end: 0.2rem;
	}

	.navsearch {
		margin-inline-start: 10px;
	}
}

@media (min-width: 645px) and (max-width: 767px) {
	.app .hor-toggle.animated-arrow {
		inset-inline-start: 11% !important;
	}
}

.horizontal-nav .mega-dropdown-menu {
	border: 0;
	margin: -2px;
	width: 100%;
}

.relative {
	position: relative;
}

.owl-theme {
	&:before {
		content: "";
		display: block;
		inset-inline-start: 0;
		background: linear-gradient(to left, transparent 0%, #f0f3f7 100%);
		inset-block-end: 0px;
		position: absolute;
		width: 100px;
		height: 100%;
		z-index: 10;
	}

	&:after {
		content: "";
		display: block;
		inset-inline-end: 0;
		background: linear-gradient(to right, transparent 0%, #f0f3f7 100%);
		inset-block-end: 0px;
		position: absolute;
		width: 100px;
		height: 100%;
		z-index: 10;
	}
}

.resolved-complaints {
	position: relative;
	inset-block-end: -13px;
	inset-inline-start: -4px;
	height: 150px;
}

@media (min-width: 991px) {
	.hor-topheader.app-header {
		.header-brand1 {
			margin-block-end: 0;
		}

		.header-brand.header-brand2 {
			min-width: auto;
			margin-block-end: 0;
			margin: 0 auto;
			text-align: center;
		}
	}

	.mobile-logo {
		display: none;
	}

	.header-form .form-inline {
		margin-block-end: 0 !important;
		padding-inline-start: 10px;
	}
}

body.search-show .header .header-form.header-form2 .form-inline .search-element.search-element1 {
	display: block;
}

.search-show .search-element.search-element1 {
	display: block;
	inset-inline-start: 0;
	inset-block-start: 0;
	width: 100%;
	padding: 15px;
	position: fixed !important;
	z-index: 1000;
	inset-inline-end: 0;
}

body {
	&.search-show:before,
	&.sidebar-show:before {
		content: "";
		position: fixed;
		inset-inline-start: 0;
		inset-inline-end: 0;
		width: 100%;
		height: 100%;
		background-color: $black;
		opacity: 0.6;
		z-index: 891;
	}

	&.search-show {
		.header {
			z-index: 892;
		}

		overflow: hidden;
	}

	&.sidebar-show {
		overflow: hidden;
	}
}

.search-element.search-element1 .Search-icon {
	position: absolute;
	inset-block-start: 23px;
	inset-inline-end: 28px;
}

.bn-news li .fa {
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	border-radius: 50%;
	text-align: center;
}

.slider-text {
	display: none;
}

.side-tab-body .tab-pane {
	.custom-control-label {
		&::after,
		&::before {
			margin-block-start: 0px;
		}
	}

	.custom-control-labe {
		font-size: 13px;
	}
}

@media (max-width: 1023px) {
	.horizontalMenu > .horizontalMenu-list > li > a {
		padding-inline: 0.5rem !important;
		padding-block: 1.1rem !important;
	}
}

@media (max-width: 1200px) {
	.horizontal-left-icon,
	.horizontal-right-icon {
		display: none;
	}
}

@media (max-width: 768px) {
	.demo_changer i {
		margin-block-start: 9px !important;
	}
}

@media (max-width: 320px) {
	.ui-datepicker .ui-datepicker-calendar th {
		padding: 4px 0;
		letter-spacing: normal;
	}

	.work-progress {
		h4 {
			font-size: 0.85rem;
		}

		h3 {
			font-size: 1.2rem;
		}
	}

	.cal1 .clndr .clndr-controls .month {
		width: 33%;
		text-align: center;
		font-size: 12px;
		margin-inline-start: 7px;
	}

	.countdown span {
		&:first-child {
			font-size: 18px !important;
		}

		&:last-child {
			font-size: 12px;
		}
	}

	.chat {
		.video-icon,
		.call-icon {
			display: none !important;
		}
	}

	.construction {
		text-align: center !important;
	}
}

@media (max-width: 568px) {
	.navresponsive-toggler {
		span {
			margin-block-start: 10px;
		}

		padding: 2px;
	}

	.responsive-navbar.navbar .navbar-collapse .icon.navsearch {
		i {
			line-height: 20px;
		}

		padding: 7px 10px !important;
		margin: 2px;
		display: block;
		margin-block-start: 14px;
	}
}

.d-flex.p-3.border-block-start:first-child {
	border-block-start: 0 !important;
}

.body-horizontal-left-icon .horizontalMenu > .horizontalMenu-list > li:last-child ul.sub-menu {
	inset-inline-end: 0;
}

svg {
	overflow: hidden;
	vertical-align: middle;
}

.horizontalMenu > .horizontalMenu-list > li > {
	ul.sub-menu > li > a:hover,
	.horizontal-megamenu .link-list li a:hover {
		color: $primary;
	}
}

.feature .fa-lg .fa-stack-1x,
.facility .fa-lg .fa-stack-1x {
	font-size: 25px;
}

.imagescroll {
	ul {
		overflow: hidden;
		width: 2150px;
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
	}

	li {
		margin: 0 3px;
		float: left;

		img {
			width: auto;
			padding: 0;
			height: 200px;
		}
	}
}

.bootstrap-touchspin-down {
	border-start-end-radius: 0;
	border-end-end-radius: 0;
}

.bootstrap-touchspin-up {
	border-start-start-radius: 0;
	border-end-start-radius: 0;
}

/*----- Custom-styles -----*/

/*-----weather icons-----*/

.weather-icon {
	position: absolute;
	inset-inline-end: -35px;
	inset-block-start: 44%;
	width: 70%;
	transform: translateY(-50%);

	img {
		width: 100%;
		height: 100%;
	}
}

.card .weather-icon svg {
	display: inline-block;
	width: 100%;
	height: auto;
}

.w-icon i {
	font-size: 2rem;
}

.feature .icon {
	position: relative;
	display: inline-block;
	width: 3em;
	height: 3em;
	line-height: 3em;
	vertical-align: middle;
	border-start-start-radius: 4px;
	border-end-start-radius: 4px;
	color: $white;
}

/*-----weather icons-----*/

/*----- spinners -----*/

.spinner3 {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	text-align: center;
	-webkit-animation: sk-rotate 2s infinite linear;
	animation: sk-rotate 2s infinite linear;
}

.dot1 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	inset-block-start: 0;
	border-radius: 100%;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	inset-block-start: 0;
	border-radius: 100%;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
	inset-block-start: auto;
	inset-block-end: 0;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes sk-rotate {
	100% {
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.spinner4 {
	margin: 100px auto !important;
	width: 70px;
	text-align: center;

	> div {
		width: 18px;
		height: 18px;
		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.sk-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;

	.sk-child {
		width: 100%;
		height: 100%;
		position: absolute;
		inset-inline-start: 0;
		inset-block-start: 0;

		&:before {
			content: "";
			display: block;
			margin: 0 auto;
			width: 15%;
			height: 15%;
			border-radius: 100%;
			-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
			animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
		}
	}

	.sk-circle2 {
		-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
		transform: rotate(30deg);
	}

	.sk-circle3 {
		-webkit-transform: rotate(60deg);
		-ms-transform: rotate(60deg);
		transform: rotate(60deg);
	}

	.sk-circle4 {
		-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	.sk-circle5 {
		-webkit-transform: rotate(120deg);
		-ms-transform: rotate(120deg);
		transform: rotate(120deg);
	}

	.sk-circle6 {
		-webkit-transform: rotate(150deg);
		-ms-transform: rotate(150deg);
		transform: rotate(150deg);
	}

	.sk-circle7 {
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	.sk-circle8 {
		-webkit-transform: rotate(210deg);
		-ms-transform: rotate(210deg);
		transform: rotate(210deg);
	}

	.sk-circle9 {
		-webkit-transform: rotate(240deg);
		-ms-transform: rotate(240deg);
		transform: rotate(240deg);
	}

	.sk-circle10 {
		-webkit-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
		transform: rotate(270deg);
	}

	.sk-circle11 {
		-webkit-transform: rotate(300deg);
		-ms-transform: rotate(300deg);
		transform: rotate(300deg);
	}

	.sk-circle12 {
		-webkit-transform: rotate(330deg);
		-ms-transform: rotate(330deg);
		transform: rotate(330deg);
	}

	.sk-circle2:before {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	.sk-circle3:before {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	.sk-circle4:before {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}

	.sk-circle5:before {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}

	.sk-circle6:before {
		-webkit-animation-delay: -0.7s;
		animation-delay: -0.7s;
	}

	.sk-circle7:before {
		-webkit-animation-delay: -0.6s;
		animation-delay: -0.6s;
	}

	.sk-circle8:before {
		-webkit-animation-delay: -0.5s;
		animation-delay: -0.5s;
	}

	.sk-circle9:before {
		-webkit-animation-delay: -0.4s;
		animation-delay: -0.4s;
	}

	.sk-circle10:before {
		-webkit-animation-delay: -0.3s;
		animation-delay: -0.3s;
	}

	.sk-circle11:before {
		-webkit-animation-delay: -0.2s;
		animation-delay: -0.2s;
	}

	.sk-circle12:before {
		-webkit-animation-delay: -0.1s;
		animation-delay: -0.1s;
	}
}

@-webkit-keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.sk-cube-grid {
	width: 40px;
	height: 40px;
	margin: 100px auto;

	.sk-cube {
		width: 33%;
		height: 33%;
		float: left;
		-webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
		animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
	}

	.sk-cube1 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}

	.sk-cube2 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}

	.sk-cube3 {
		-webkit-animation-delay: 0.4s;
		animation-delay: 0.4s;
	}

	.sk-cube4 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}

	.sk-cube5 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}

	.sk-cube6 {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}

	.sk-cube7 {
		-webkit-animation-delay: 0s;
		animation-delay: 0s;
	}

	.sk-cube8 {
		-webkit-animation-delay: 0.1s;
		animation-delay: 0.1s;
	}

	.sk-cube9 {
		-webkit-animation-delay: 0.2s;
		animation-delay: 0.2s;
	}
}

@-webkit-keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	}

	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1);
	}
}

@keyframes sk-cubeGridScaleDelay {
	0%,
	70%,
	100% {
		-webkit-transform: scale3D(1, 1, 1);
		transform: scale3D(1, 1, 1);
	}

	35% {
		-webkit-transform: scale3D(0, 0, 1);
		transform: scale3D(0, 0, 1);
	}
}

.sk-folding-cube {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
	-webkit-transform: rotateZ(45deg);
	transform: rotateZ(45deg);

	.sk-cube {
		float: left;
		width: 50%;
		height: 50%;
		position: relative;
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);

		&:before {
			content: "";
			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 0;
			width: 100%;
			height: 100%;
			-webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
			animation: sk-foldCubeAngle 2.4s infinite linear both;
			-webkit-transform-origin: 100% 100%;
			-ms-transform-origin: 100% 100%;
			transform-origin: 100% 100%;
		}
	}

	.sk-cube2 {
		-webkit-transform: scale(1.1) rotateZ(90deg);
		transform: scale(1.1) rotateZ(90deg);
	}

	.sk-cube3 {
		-webkit-transform: scale(1.1) rotateZ(180deg);
		transform: scale(1.1) rotateZ(180deg);
	}

	.sk-cube4 {
		-webkit-transform: scale(1.1) rotateZ(270deg);
		transform: scale(1.1) rotateZ(270deg);
	}

	.sk-cube2:before {
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
	}

	.sk-cube3:before {
		-webkit-animation-delay: 0.6s;
		animation-delay: 0.6s;
	}

	.sk-cube4:before {
		-webkit-animation-delay: 0.9s;
		animation-delay: 0.9s;
	}
}

@-webkit-keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}

	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}

	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

@keyframes sk-foldCubeAngle {
	0%,
	10% {
		-webkit-transform: perspective(140px) rotateX(-180deg);
		transform: perspective(140px) rotateX(-180deg);
		opacity: 0;
	}

	25%,
	75% {
		-webkit-transform: perspective(140px) rotateX(0deg);
		transform: perspective(140px) rotateX(0deg);
		opacity: 1;
	}

	90%,
	100% {
		-webkit-transform: perspective(140px) rotateY(180deg);
		transform: perspective(140px) rotateY(180deg);
		opacity: 0;
	}
}

.spinner5 {
	margin: 100px auto;
	width: 50px;
	height: 40px;
	text-align: center;
	font-size: 10px;

	> div {
		height: 100%;
		width: 6px;
		display: inline-block;
		-webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	.rect2 {
		-webkit-animation-delay: -1.1s;
		animation-delay: -1.1s;
	}

	.rect3 {
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
	}

	.rect4 {
		-webkit-animation-delay: -0.9s;
		animation-delay: -0.9s;
	}

	.rect5 {
		-webkit-animation-delay: -0.8s;
		animation-delay: -0.8s;
	}
}

@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}

	20% {
		-webkit-transform: scaleY(1);
	}
}

@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}

	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}

.spinner {
	width: 40px;
	height: 40px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.spinner-lg {
	width: 100px;
	height: 100px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}

	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}

	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.spinner1 {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
}

.spinner1-lg {
	width: 100px;
	height: 100px;
	position: relative;
	margin: 100px auto;
}

.double-bounce1 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}

	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.spinner2 {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}

.cube1 {
	width: 15px;
	height: 15px;
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
	width: 15px;
	height: 15px;
	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}

	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}

	75% {
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}

	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes sk-cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}

	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}

	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}

	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}

	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}

	5% {
		transform: scale(1.1);
	}

	39% {
		transform: scale(0.85);
	}

	45% {
		transform: scale(1);
	}

	60% {
		transform: scale(0.95);
	}

	100% {
		transform: scale(0.9);
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}

	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	100% {
		transform: rotate(1800deg);
	}
}

/*----- spinners -----*/

/*---------- IE ----------*/

*::-ms-backdrop,
html.fullscreenie {
	width: 100%;
}

*::-ms-backdrop,
html.fullscreenie .app-content {
	overflow: scroll;
	overflow-x: hidden;
	height: 100vh;
}

*::-ms-backdrop,
html.fullscreenie .content-area {
	overflow: scroll;
	overflow-x: hidden;
	height: 100vh;
}

*::-ms-backdrop,
.mail-chats li.chat-persons {
	height: 100%;
}

*::-ms-backdrop,
.product .card-body {
	height: 100% !important;
}

*::-ms-backdrop,
.card .card-img-top {
	height: 220px;
}

*::-ms-backdrop,
.list-group.list-lg-group.list-group-flush .media-body {
	display: block;
}

*::-ms-backdrop,
.media-body {
	display: block !important;
}

*::-ms-backdrop,
.page-header {
	padding: 8px 20px;
}

*::-ms-backdrop,
.page-header .btn-sm,
.btn-group-sm > .btn {
	line-height: 2;
}

*::-ms-backdrop,
.horizontalMenucontainer {
	flex-direction: column;
}

*::-ms-backdrop,
body.app {
	flex-direction: column;
}

*::-ms-backdrop,
body.boxed {
	background-attachment: inherit !important;
}

/*---------- IE ----------*/

/*----- New pages styles -----*/
.blog-label {
	position: absolute;
	inset-block-end: 15px;
	inset-inline-start: 15px;
}
.footer-container-main {
	&.blog-footer .icons-bg {
		justify-content: space-between;
	}
	.icons-bg {
		display: flex;
		align-items: center;
	}
}
.footer-container-main .more-btn {
	color: #707070 !important;

	&:hover {
		color: $primary !important;
	}
}
.d-f-ai-c {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
.icons-bg .social .social-icon {
	transition: all ease 0.3s;
}
.social .social-icon i {
	font-size: 13px;
}
.footer .social ul li a {
	width: 30px;
	height: 30px;
	display: block;
	border: 1px solid $border;
	line-height: 30px;
	border-radius: 50%;
	color: $default-color;

	&:hover {
		background-color: $primary;
		color: $white;
	}
}
.icons-bg .social .social-icon:hover {
	background-color: $primary !important;
	color: $white !important;
	fill: $white;
}
.form-floating .form-control {
	background: none !important;
}
.card-pay .tabs-menu {
	border-radius: 5px;
	overflow: hidden;

	li {
		width: 33.3%;
		display: block;

		a {
			padding: 0.7rem 1rem;
			background: #f3f4f7;
			display: block;
			text-align: center;

			&.active {
				background: $primary !important;
				color: $white;
				border-radius: 2px;
				text-align: center;
			}
		}
	}
}
.your-order {
	border-block-end: #eaedf100;
}
.cart-img {
	height: 55px !important;
	width: 58px;
	border-radius: 5px !important;
}
.tabel-border {
	border-block-start: 1px solid #eaedf1 !important;
}
.ckbox span,
.rdiobox span {
	margin-inline-start: 5px;
}
.main-parent {
	position: relative;
}
.main-parent .main-child {
	position: absolute;
	inset-block-start: 0px;
	inset-inline-end: 10px;
	height: 100%;
}
/*----- filemanager styles start -----*/
.w-icn {
	width: 1.45rem !important;
	color: $primary;
	fill: $primary;
}
.w-inner-icn {
	width: 1rem !important;
	color: #495584;
}
.fileprogress .progress-bar {
	height: 5px;
	width: 35%;
	background-color: $primary;
	animation: grow 0.6s linear 0.3s;
}
.nav1.nav-column .nav-link.active {
	color: $white !important;
	background-color: $primary;
}
.nav1.nav-column .nav-link.active svg {
	fill: $white !important;
}
.nav1.nav-column .nav-link svg {
	transition: all ease 0.3s;
}
.nav1.nav-column .nav-item1 {
	padding: 0px;
	margin-block-start: 5px;
}
.nav1.nav-column .nav-link.active {
	color: $white !important;
	background-color: $primary;
}
.nav1.nav-column .nav-link {
	border-inline-end: none !important;
	width: 100%;
	padding: 6px;
	border-radius: 5px;
	&:hover {
		fill: $primary;
	}
}
.file-manger-icon img {
	height: 70px;
	width: 70px;
}
/*----- filemanager styles end -----*/
.owl-nav button {
	display: block;
	font-size: 1.3rem !important;
	line-height: 2em;
	border-radius: 50%;
	width: 3rem;
	height: 3rem;
	text-align: center;
	background: $white !important;
	opacity: 0.5 !important;
	border: 1px solid #e8ebf3 !important;
	z-index: 9;
	box-shadow: 0 4px 15px rgb(67 67 67 / 15%);
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
}
.owl-nav .owl-prev {
	position: absolute;
	inset-block-start: 50%;
	inset-inline-start: -25px;
	margin-block-start: -1.65em;
}
.owl-nav .owl-next {
	position: absolute;
	inset-block-start: 50%;
	inset-inline-end: -25px;
	margin-block-start: -1.65em;
}
.attached-file-container .attached-file-image {
	position: relative;
}
.attached-file-container .attached-file-image .icons li {
	margin: 0 auto;
	display: inline-block;
}
.attached-file-container:hover .attached-file-image .icons {
	padding: 10px;
	opacity: 1;
}
.attached-file-container .attached-file-image .icons {
	padding: 0px;
	list-style: none;
	opacity: 0;
	inset-inline: 0;
	inset-block-end: 0px;
	text-align: center;
	border-end-start-radius: 5px;
	border-end-end-radius: 5px;
	position: absolute;
	z-index: 1;
	transition: all ease 0.3s;
	display: block;
	margin: 0 auto;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 100%);
}
.attached-file-container .attached-file-image .image-pic {
	position: absolute;
	inset-inline: 0;
	inset-block-start: 0px;
	color: $white;
	font-size: 17px;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent 100%);
	padding-block-start: 9px;
	padding-inline-end: 0;
	padding-block-end: 20px;
	padding-inline-start: 15px;
	border-radius: 5px;
}
.rating-stars {
	width: 100%;
	text-align: center;
	font-size: 30px;
}
.block {
	display: block !important;
}
.jq-star {
	width: 100px;
	height: 100px;
	display: inline-block;
	cursor: pointer;
}
.jq-star-svg {
	padding-inline-start: 3px;
	width: 100%;
	height: 100%;
}
/*----- Country selector modal -----*/
.btn-country {
	border: 1px solid #ededf5;
	box-shadow: none !important;
	text-align: justify !important;
	font-size: 15px !important;
	border-radius: 10px !important;
	padding: 0.8rem !important;
	box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;

	&:hover {
		border: 1px solid $primary !important;
		box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
	}
}
.btn-country.active {
	border: 1px solid $primary !important;
}
.btn-check:checked + .btn-country,
.btn-country.active,
.btn-country:active {
	border: 1px solid $primary !important;
}
.country-selector img {
	width: 28px;
	height: 20px;
	border-radius: 20%;
}
.country-selector {
	padding: 0.3rem;
}
.country-selector .nav-link {
	color: #495046;
}
.drop-heading {
	padding: 1rem;
	color: #8f9daf;
}
.header-right-icons .dropdown-menu a {
	padding: 12px;
}
.open-file {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 1;
	inset-inline: 0;
	inset-block-start: 0;
}
.dropdown-menu-arrow.dropdown-menu-end:before,
.dropdown-menu-arrow.dropdown-menu-end:after {
	inset-inline-start: auto;
	inset-inline-end: 18px;
}
.dropdown-menu-arrow:before {
	content: "";
	width: 13px;
	height: 13px;
	background: $white;
	position: absolute;
	inset-block-start: -7px;
	inset-inline-end: 28px;
	transform: rotate(45deg);
	border-block-start: 1px solid #e9edf4;
	border-inline-start: 1px solid #e9edf4;
}
.table > :not(:first-child) {
	border-block-start: 0px solid currentColor !important;
}
.sidebar .tab-content > .tab-pane {
	padding: 0 !important;
}
@media (min-width: 1400px) {
	body.layout-boxed .app-sidebar .side-header {
		inset-inline: auto;
	}
}
@media (min-width: 1280px) {
	.container {
		max-width: 1140px;
	}
}
.notification {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		inset-block: 15px;
		width: 3px;
		background: #467fcf4f;
		inset-inline-start: 20%;
		margin-inline-start: -1px;
	}

	.notification-time {
		position: absolute;
		inset-block-start: 30px;
		inset-inline-start: -7px;
		width: 18%;
		text-align: end;

		.date {
			line-height: 16px;
			font-size: 13px;
			margin-block-end: 4px;
			color: #8f8fb1;
			display: block;
			font-weight: 500;
		}
		.time {
			display: block;
			font-weight: 500;
			line-height: 24px;
			font-size: 21px;
			color: #8f8fb1;
		}
		.date,
		.time {
			display: block;
			font-weight: 500;
		}
	}
	.notification-icon {
		inset-block-start: 41px;
		inset-inline-start: 15%;
		position: absolute;
		width: 10%;
		text-align: center;

		a {
			text-decoration: none;
			width: 18px;
			height: 18px;
			display: inline-block;
			border-radius: 50%;
			background: $white;
			line-height: 10px;
			color: $white;
			font-size: 14px;
			border: 3px solid $primary;
			transition: border-color 0.2s linear;
		}
	}
}
@media (max-width: 767px) {
	.notification .notification-body {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}
.notification-time-date {
	color: #8f8fb1;
	font-size: 16px;
	font-weight: 500;
}
.notification .notification-body {
	box-shadow: 2px 2px 5px rgb(0 0 0 / 9%);
	margin-inline-start: 25%;
	margin-inline-end: 18%;
	background: $white;
	position: relative;
	padding: 14px 20px;
	border-radius: 6px;

	&:before {
		content: "";
		display: block;
		position: absolute;
		border: 10px solid transparent;
		border-inline-end-color: $white;
		inset-inline-start: -20px;
		inset-block-start: 25px;
	}
}
.media {
	display: flex;
	align-items: flex-start;
	zoom: 1;
	overflow: hidden;

	img {
		width: 40px;
		height: 40px;
	}
}
.avatar-md {
	width: 2.5rem;
	height: 2.5rem;
	line-height: 2.5rem;
	font-size: 1rem;
}
.media-body {
	zoom: 1;
	overflow: hidden;
	width: auto;
	-ms-flex: 1;
	flex: 1;
}
.notify-time {
	position: absolute;
	inset-block: auto;
	inset-inline-start: auto;
	inset-inline-end: 25px;
}
.notification > li {
	position: relative;
	min-height: 50px;
	padding: 15px 0;
}
.settings-icon {
	border-radius: 10px;
	padding: 11px 15px;
	font-size: 20px;
}
.avatar-icons {
	content: "";
	inset-block-start: 48px;
	inset-inline-end: -4px;
	width: 23px;
	height: 23px;
	border-radius: 50%;
	position: absolute;
	line-height: initial;
	cursor: pointer;
	i {
		line-height: 1.6;
	}
}
.avatar .avatar-icons i {
	font-size: 0.74rem;
	padding-block-start: 2px;
	display: block;
}
.avatar-badges {
	content: "";
	inset-block-start: -4px;
	inset-inline-end: -4px;
	width: 15px;
	height: 15px;
	position: absolute;
	cursor: pointer;
}
.apexcharts-yaxis-title text,
.apexcharts-xaxis-title text,
.apexcharts-yaxis-texts-g text,
.apexcharts-xaxis-texts-g text {
	fill: #7a8089;
}
.apexcharts-tooltip-marker {
	margin-inline-end: 10px;
}
.light-mode.app {
	&.bg-account {
		.header-brand-img.main-logo {
			display: block;
			margin: 0 auto;
		}

		.header-brand-img.darklogo {
			display: none;
		}
	}
}
.apexcharts-legend-marker {
	margin-inline-end: 3px !important;
}
.apexcharts-menu-item {
	font-size: 10px !important;
}
.panel.price h3 {
	padding: 20px 0;
	margin-block-end: 0;
	color: $white;
}
@media (max-width: 992px) {
	.horizontal-content .container,
	.hor-header .container {
		max-width: none;
	}
}
.hor-header .container.main-container div:first-child {
	align-items: center !important;
}
.accordion-button:not(.collapsed) {
	color: $primary !important;
	background-color: #e7f1ff;
}
.apexcharts-toolbar {
	z-index: 0 !important;
	padding-block-start: 0 !important;
	padding-inline-end: 0 !important;
	padding-block-end: 2px !important;
	padding-inline-start: 6px !important;
}
.legend {
	width: 8px;
	height: 8px;
	display: inline-block;
	border-radius: 100%;
	margin-inline-end: 5px;
	margin-block-start: 6px;
}
/*----- Sales activities -----*/
.timeline-label {
	position: relative;

	&:before {
		content: "";
		position: absolute;
		inset-inline-start: 4px;
		width: 1px;
		inset-block-start: 1px;
		inset-block-end: 0px;
		background-color: $border;
	}
}
.sales-activity {
	position: relative;
}
.sales-activity:before {
	content: "";
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: $teal;
	inset-block-start: 7px;
	inset-inline-start: 1px;
}
.sales-activity:nth-child(2):before {
	background-color: $danger;
}
.sales-activity:nth-child(3):before {
	background-color: $purple;
}
.sales-activity:nth-child(4):before {
	background-color: $blue;
}
/*----- crypto styles -----*/
.bitcoin svg {
	width: 36px;
	height: 36px;
}
.recent-activity svg {
	width: 35px;
	height: 35px;
	fill: #60bcc3;
	margin-block-start: 3px;
}
.btn.btn-sm.btn-primary.active {
	border-radius: 2px;
}
@media (max-width: 360px) {
	#litcoinChart,
	#bitcoinChart,
	#enthereumChart {
		.apexcharts-canvas {
			width: 50px !important;
		}
	}
}
/*----- crypto styles end -----*/

.news-image img {
	width: 150px;
	height: 65px;
	border-radius: 6px;
}
.amount-drop-down {
	border: 1px solid $border;
}
.form-floating input {
	&.form-control {
		&::placeholder {
			color: $white;
		}
	}
}
.form-floating input:focus::placeholder {
	color: $black-2;
}
.resp-tab-content-active {
	display: none;
	padding: 6px;
}
/*latest timeline */
.latest-timeline .timeline {
	list-style-type: none;
	position: relative;
	padding: 0;

	&:before {
		content: " ";
		background: $border;
		display: inline-block;
		position: absolute;
		inset-inline-start: 10px;
		border: 1px dashed $black-1;
		height: 100%;
		z-index: 400;
	}
	.event-text {
		margin: 20px 0;
		padding-inline-start: 2rem;

		&:before {
			content: " ";
			background: white;
			display: inline-block;
			position: absolute;
			border-radius: 50%;
			border: 3px solid $purple;
			inset-inline-start: 5px;
			width: 12px;
			height: 12px;
			z-index: 400;
		}
		&:first-child:before {
			border: 3px solid $secondary;
		}
		&:nth-child(2):before {
			border: 3px solid $info;
		}

		&:nth-child(3):before {
			border: 3px solid $pink;
		}
		&:nth-child(4):before {
			border: 3px solid $orange;
		}
		&:last-child:before {
			border: 3px solid $danger;
		}
	}
}
.activity {
	position: relative;
	border-inline-start: 1px solid $border;
	margin-inline: 20px;

	.img-activity {
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 34px;
		border-radius: 50%;
		position: absolute;
		inset-inline-start: -20px;
		box-shadow: 0 0 0 0.5px #f8f9fa;
	}
	.item-activity {
		margin-inline-start: 40px;
		margin-block-end: 20px;
	}
}
.step-anchor {
	z-index: 1;
}
.bg-account.app .header-brand-img.darklogo {
	display: none;
	margin: 0 auto;
}
.dataTables_length .form-select.form-select-sm.select2 {
	margin-inline: 0.5rem;
}
.sorting_1 .custom-control.custom-checkbox .custom-control-label::before {
	inset-block-start: 15px !important;
}
.fc-toolbar-chunk {
	display: flex;
	align-items: center;
}
@media (max-width: 991px) {
	.attached-file-image img {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.notify-time {
		display: none;
	}
}
@media (max-width: 767px) {
	.notification:before {
		display: none;
	}
	.notification .notification-time .date {
		display: none;
	}
	.notification .notification-time .time {
		display: none;
	}
	.notification .notification-icon {
		display: none;
	}
	.notification .notification-body {
		margin-inline-start: 0 !important;
		margin-block-end: 0 !important;
	}
}
.handle-counter {
	overflow: hidden;
	display: flex;
	justify-content: center;
}
.handle-counter {
	.counter-minus,
	.handle-counter .counter-plus {
		float: left;
		text-align: center;
		border-radius: 1px;
	}
	.counter-minus {
		border-start-start-radius: 3px;
		border-start-end-radius: 0;
		border-end-end-radius: 0;
		border-end-start-radius: 3px;
	}
	.btn {
		padding: 9px 10px !important;
	}
	.counter-plus {
		border-start-start-radius: 0;
		border-start-end-radius: 3px;
		border-end-end-radius: 0;
		border-end-start-radius: 3px;
	}
	.btn-white {
		border: 1px solid $border !important;
	}
	input {
		float: left;
		text-align: center;
		height: 48px;
		border-radius: 0;
		width: 70px;
		border: 1px solid #ededf5;
		border-inline: 0 !important;
		outline: none;
	}
}
@media (max-width: 991px) {
	.responsive-navbar .profile-1 .nav-link {
		padding-block-start: 3px !important;
		padding-inline-end: 9px !important;
		padding-block-end: 0 !important;
		padding-inline-start: 0 !important;
	}
}
@media (max-width: 576px) {
	.card-pay .tabs-menu li {
		width: 100%;
	}
}
.fonts {
	border: 1px solid rgba(107, 122, 144, 0.3);
	border-radius: 4px;
	&:hover {
		color: $primary !important;
	}
}
.apexcharts-legend.apexcharts-align-left.apx-legend-position-top {
	inset-inline-start: -13px !important;
}
/*----- maps styels start -----*/
.mapael .map {
	margin-block-end: 10px;
	position: relative;
}
@media (min-width: 1200px) {
	.mapcontainer4 .map {
		width: 700px;
		margin: 0 auto;
	}
	.mapcontainer .map,
	.mapcontainer1 .map {
		width: 400px;
		margin: 0 auto;
		height: 400px;
	}
}
/*----- maps styels end -----*/
.product-grid6 {
	overflow: hidden;

	.product-image6 {
		overflow: hidden;
		text-align: center;

		img {
			width: 100%;
			height: auto;
			transition: all 0.5s ease 0s;
		}
	}
	.price .discount-price {
		color: #8c8ea9;
		font-size: 15px;
		font-weight: 400;
		text-decoration: line-through;
		display: inline-block;
	}
	.product-content {
		text-align: center;
		transition: all 0.5s ease 0s;
		padding-block-start: 15;
		padding-inline: 0;
		padding-block-end: 0;
	}
}
.offcanvas-header h5 {
	font-weight: 600;
}
/*----- about page styles start -----*/
.about-icons {
	height: 40px;
	width: 40px;
}
/*----- about page styles end -----*/
/*----- faq page styles start -----*/
.faq-avatar {
	z-index: 1;
	margin-block-end: 1.1rem !important;
}
/*----- faq page styles end -----*/
/*----- file attachments styles start -----*/
.file-image-1 {
	width: 100px;
	height: 100px;
	display: inline-table;
	margin: 20px;
	position: relative;
	border: 1px solid $border;
	border-radius: 5px;

	&:hover {
		.icons {
			opacity: 1;
			inset-block-end: 33px;
		}
	}
	.product-image img {
		width: 100px;
		height: 100px;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.icons {
		padding: 0;
		margin: 0;
		list-style: none;
		opacity: 0;
		inset-inline-end: 7px;
		inset-block-start: 6px;
		text-align: center;
		position: absolute;
		inset-block-end: 0;
		z-index: 1;
		transition: all 0.5s ease 0s;
		text-align: end;

		li a {
			font-size: 12px;
			line-height: 28px;
			text-align: center;
			height: 25px;
			width: 25px;
			margin: 4px 1px;
			padding: 0;
			border-radius: 50px;
			display: block;
			position: relative;
			transition: all 0.3s ease-in-out;
			color: $white;
		}
	}
	.file-name-1 {
		position: absolute;
		inset-inline: 0;
		font-size: 12px;
		text-align: center;
		font-weight: 500;
		margin-block-end: 10px;
		margin-block-start: 10px;
	}
}
.file-image-md {
	width: 150px;
	height: 150px;

	.product-image img {
		width: 150px;
		height: 150px;
		background-size: cover;
		background-repeat: no-repeat;
	}
}
/*----- file attachments styles end -----*/
/*----- morris chart styels -----*/
#morrisBar8 svg text,
#morrisBar9 svg text {
	font-size: 4px !important;
	font-weight: 500 !important;
}
/*----- morris chart styels end -----*/
/*----- data tables styles start -----*/
.data-table5 {
	.select2-container {
		width: 60px !important;
	}
}
/*----- data tables styles end -----*/
/*----- toast sstyels start -----*/
.background-none {
	background: none;

	&.btn-close {
		color: inherit;
	}
}
/*----- toast sstyels end -----*/
/*----- e-commerce dashboard styels -----*/
.circle-icon {
	height: 40px;
	width: 40px;
	position: absolute;
	inset-block-start: 40px;
	border-radius: 5px;
	inset-inline-end: 20px;
}
.circle-icon i {
	line-height: 2.5 !important;
}
.visitors-icon {
	height: 40px;
	width: 40px;
	position: absolute;
	inset-block-start: 20px;
	border-radius: 5px;
	inset-inline-end: 20px;
}
.valuable-customers {
	.list-group-item {
		padding: 0;
		border: 0;
		margin-block-end: 0.7rem;
	}
}
.visitors-icon i {
	line-height: 2.3 !important;
	margin-inline-start: 14px;
}
/*----- search page styles start -----*/
.users img {
	max-width: none !important;
}
/*----- search page styles end -----*/
.toast {
	background-color: $white;
}
.note-editor.note-frame.panel.panel-default {
	.modal .modal-header {
		.btn-close {
			font-size: 16px !important;
		}
	}
}
div.dt-button-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
}
div.dt-button-collection {
	position: absolute;
	z-index: 2001;
}
#file-datatable_wrapper .dropdown-menu {
	padding: 0 !important;
}
div.dt-button-collection div.dropdown-menu {
	display: block;
	z-index: 2002;
	min-width: 100%;
	border: 1px solid $border;
	.dropdown-item {
		background: $white;
		color: $default-color;
		&:hover {
			background-color: $primary-02;
			color: $primary;
		}
	}
}
div.dt-button-info {
	position: fixed;
	padding: 10px;
	inset-block-start: 50%;
	inset-inline-start: 50%;
	width: 400px;
	margin-block-start: -100px;
	margin-inline-start: -200px;
	background-color: $white;
	border: 1px solid #ededf5;
	box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
	border-radius: 3px;
	text-align: center;
	z-index: 21;
}
/*----- Buynow modal styels start -----*/
/* licenses */
.buynow {
	.money-bag,
	.free-img {
		margin: 0 auto;
		display: block;
		margin-block-start: -20px;
	}

	.dropdown-item {
		display: block;
		width: 100%;
		padding: 0.45rem 0.8rem;
		clear: both;
		font-weight: 400;
		color: $default-color;
		text-align: inherit;
		white-space: nowrap;
		background-color: transparent;
		border: 0;
		font-size: 15px;
		&:hover {
			background-color: $primary-02;
			color: $primary;
		}
	}
	.dropdown-menu {
		border-radius: 5px !important;
		background-color: $white;
		border: 1px solid #ffffff8a;
		box-shadow: 0 0px 90px rgb(155 153 153 / 48%) !important;
	}
	.card-block {
		border: 1px solid rgb(231 229 245 / 11%) !important;
		box-shadow: 0 0.15rem 1.75rem 0 rgb(255 255 255 / 6%);
		inset-inline-start: 0;
		inset-block-start: 0;
		inset-block-end: 0;
		background: #6a1cbb69;
		border-radius: 7px;
		width: 100%;
		height: 100%;
		position: absolute;
	}

	@media screen and (max-width: 560px) {
		.card-block {
			margin: 0 auto;
			text-align: center;
			width: 100%;
			height: 100%;
		}
		.demo-screen-skin.bg-image-style .row {
			margin: 7px;
		}
	}
	@media (max-width: 480px) {
		.card .btn {
			display: initial !important;
		}
	}
	&.modal .btn-close:focus {
		box-shadow: none;
	}
	.modal-content {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		pointer-events: auto;
		background-color: #000000a8;
		background-clip: padding-box;
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.5rem;
		outline: 0;
	}

	.modal-dialog .image-top {
		margin-block-start: -10px !important;
	}
	.modal-dialog .card-block {
		background: #6a1cbbbf;
	}
	.regular-license {
		z-index: 9;
	}
	.border-0 {
		border: 0px !important;
	}
	&.modal .modal-header {
		padding: 10px 20px;
	}
	@media (max-width: 480px) {
		.modal-dialog .modal-body {
			margin: 15px;
		}
	}
	.modal-dialog .btn-close {
		position: absolute;
		float: right;
		margin-inline-end: auto;
		inset-inline-end: 33px;
		inset-block-start: 30px;
		filter: invert(1);
		font-size: 12px;
		padding: 6px;
		border: 1px solid $black;
		border-radius: 5px;
		line-height: 0;
	}
	.lh-xs {
		line-height: 0.75 !important;
		margin-block-start: 6px;
	}
	.license-view {
		text-align: center;
		text-decoration: underline;
	}
	.license-view a {
		padding: 10px;
		border-radius: 5px;
	}
	.license-view a:hover {
		padding: 10px;
		background-color: rgb(255 255 255 / 12%);
		border-radius: 5px;
	}
	.bg-image-style {
		background-image: url(../img/bg3.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		inset-block-start: 0;
		inset-inline-start: 0;
		inset-inline-end: 0;
		position: relative;
		border-radius: 0px;
	}
	.bg-image-style::before {
		content: "";
		background-color: #ffffffa6;
		width: 100%;
		height: 100%;
		inset-inline-start: 0%;
		inset-block-start: 0;
		position: absolute;
	}
	.modal-backdrop {
		background-color: #000000b8;
	}
	.card-body.imag-list {
		overflow: visible;
	}
	.imag-list {
		position: relative;
		content: "";
		inset-inline-start: 0;
		background-repeat: no-repeat !important;
		background-position: center !important;
		background-size: cover !important;
		width: 100%;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		z-index: 1;
	}
	.imag-list::before {
		content: "";
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		inset-block-start: 0;
		inset-inline-start: 0;
		inset-block-start: 0;
		border-radius: 5px;
		position: absolute;
		background: #6a1cbb82;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.modal-content {
		z-index: 0;
		border: 0px #000 solid !important;
	}
	.modal-content::before {
		content: "";
		content: "";
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		inset-block-start: 0;
		inset-inline-start: 0;
		inset-inline-end: 0;
		border-radius: 0.5rem;
		position: absolute;
		background: #000000b3;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
	.Licenses-img {
		border: 1px solid #ffffff2e;
		border-radius: 5px;
	}
}
/*----- Buynow modal styels end -----*/
.divider {
    height: 1px;
    margin: 30px 0;
    overflow: hidden;
    background-color: $border;
}
::selection {
	background-color: $primary;
	color: $white;
}

@media(max-width: 370px) {
	.flatpickr-calendar.inline {
		inset-inline-start: -43px;
	}
}

.language-img {
	width: 20px;
	height: 15px;
	margin-block-end: 1px;
}

.toast-container {
	position: relative;
}

.uploaded-pic-likes {
	.avatar-list.avatar-list-stacked {
		.avatar {
			margin-block-end: 0 !important;
		}
	}
}

.nav-dropdown.navbar-expand-lg {
	.collapse.navbar-collapse {
		.navbar-nav {
			.nav-item, .nav-link {
				display: block;
			}
		}
	}
}