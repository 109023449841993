/*----- Global Loader -----*/
#global-loader {
	position: fixed;
	z-index: 50000;
	inset: 0;
	background: $white;
	margin: 0 auto;
	text-align: center;
	img {
		position: absolute;
		inset-inline: 0;
		text-align: center;
		inset-block-start: 45%;
		margin: 0 auto;
		-webkit-animation: rotation 2s infinite linear;
	}
}

/*----- Global Loader -----*/

/*-----  Loader -----*/

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}
.dimmer {
	position: relative;
	.loader {
		display: none;
		margin: 0 auto;
		position: absolute;
		inset-block-start: 50%;
		inset-inline: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&.active {
		.loader {
			display: block;
		}
		.dimmer-content {
			opacity: .04;
			pointer-events: none;
		}
	}
}
.lds-heart {
	position: relative;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;
	margin: 100px auto;
	div {
		inset-block-start: 23px;
		inset-inline-start: 19px;
		position: absolute;
		width: 26px;
		height: 26px;
		animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
		&:after {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
		}
		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
			inset-inline-start: -17px;
			border-start-start-radius: 50%;
			border-start-end-radius: 0;
			border-end-end-radius: 0;
			border-end-start-radius: 50%;
		}
		&:after {
			inset-block-start: -17px;
			border-radius: 50% 50% 0 0;
			border-start-start-radius: 50%;
			border-start-end-radius: 50%;
			border-end-end-radius: 0;
			border-end-start-radius: 0%;
		}
	}
}
.lds-ring {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 100px auto;
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 51px;
		height: 51px;
		margin: 6px;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}
.lds-hourglass {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 100px auto;
	&:after {
		content: " ";
		display: block;
		border-radius: 50%;
		width: 0;
		height: 0;
		margin: 6px;
		box-sizing: border-box;
		animation: lds-hourglass 1.2s infinite;
	}
}

/*-----  Loader -----*/

