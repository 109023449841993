/*------ Card -------*/

.card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: $white;
	background-clip: border-box;
	border: 1px solid $border;
	box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
	border-radius: 5px;
	margin-block-end: 20px;
	width: 100%;

	> {
		hr {
			margin-inline: 0;
		}

		.list-group {
			&:first-child .list-group-item:first-child {
				border-start-start-radius: 3px;
				border-start-end-radius: 3px;
			}

			&:last-child .list-group-item:last-child {
				border-end-start-radius: 7px;
				border-end-end-radius: 7px;
			}
		}
	}

	&.card-collapsed {
		.card-header {
			border-block-end: 0;
		}
	}
}

.card-subtitle {
	margin-block-start: -0.75rem;
	margin-block-end: 0;
}

.card-text:last-child {
	margin-block-end: 0;
}

.card-link {
	&:hover {
		text-decoration: none;
	}

	+.card-link {
		margin-inline-start: 1.5rem;
	}
}

.card-header+.list-group .list-group-item:first-child {
	border-block-start: 0;
}

.card-footer {
	padding: 1.5rem;
	background-color: rgba(0, 0, 0, 0.03);
	border-block-start: 1px solid $border;
	color: $default-color;

	&:last-child {
		border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
	}
}

.card-header-tabs {
	margin-inline: -0.75rem;
	margin-block-end: -1.5rem;
	border-block-end: 0;
	margin: -1.25rem 0;
	line-height: 2rem;
}

.card-header-pills {
	margin-inline: -0.75rem;
	margin: -.75rem 0;
}

.card-img-overlay {
	position: absolute;
	inset: 0;
	padding: 1.25rem;
	background-color: $black-4;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
}

.card-img {
	width: 100%;
	border-radius: calc(3px - 1px);
}

.card-img-top,
.card-img-bottom {
	width: 100%;
}

.card-deck {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;

	.card {
		margin-block-end: 0.75rem;
	}
}

@media (min-width: 576px) {
	.card-deck {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;
		margin-inline: -0.75rem;

		.card {
			display: -ms-flexbox;
			display: flex;
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-inline: 0.75rem;
			margin-block-end: 0;
		}
	}
}

.card-group {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;

	>.card {
		margin-block-end: 0.75rem;
	}
}

@media (min-width: 576px) {
	.card-group {
		-ms-flex-flow: row wrap;
		flex-flow: row wrap;

		>.card {
			-ms-flex: 1 0 0%;
			flex: 1 0 0%;
			margin-block-end: 0;

			+.card {
				margin-inline-start: 0;
				border-inline-start: 0;
			}

			&:first-child {
				border-start-end-radius: 0;
				border-end-end-radius: 0;

				.card-img-top,
				.card-header {
					border-start-end-radius: 0;
				}

				.card-img-bottom,
				.card-footer {
					border-end-end-radius: 0;
				}
			}

			&:last-child {
				border-start-start-radius: 0;
				border-end-start-radius: 0;

				.card-img-top,
				.card-header {
					border-start-start-radius: 0;
				}

				.card-img-bottom,
				.card-footer {
					border-end-start-radius: 0;
				}
			}

			&:only-child {
				border-radius: 3px;

				.card-img-top,
				.card-header {
					border-start-start-radius: 3px;
					border-start-end-radius: 3px;
				}

				.card-img-bottom,
				.card-footer {
					border-end-end-radius: 3px;
					border-end-start-radius: 3px;
				}
			}

			&:not(:first-child):not(:last-child):not(:only-child) {
				border-radius: 0;

				.card-img-top,
				.card-img-bottom,
				.card-header,
				.card-footer {
					border-radius: 0;
				}
			}
		}
	}
}

.card-columns .card {
	margin-block-end: 1.5rem;
}

@media (min-width: 576px) {
	.card-columns {
		-webkit-column-count: 3;
		-moz-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 1.25rem;
		-moz-column-gap: 1.25rem;
		column-gap: 1.25rem;
		orphans: 1;
		widows: 1;

		.card {
			display: inline-block;
			width: 100%;
		}
	}
}

.card-blog-overlay .card-header {
	border-block-end: 1px solid rgba(107, 122, 144, 0.3);
}

.card-blog-overlay1 .card-footer,
.card-blog-overlay2 .card-footer {
	border-block-start: 1px solid $border;
}

.card-blog-overlay:before,
.card-blog-overlay1:before,
.card-blog-overlay2:before {
	content: '';
	display: block;
	position: absolute;
	background: $black-5;
	width: 100%;
	height: 100%;
	inset-inline-end: 0;
	inset-block-start: 0;
}

.card-blog-overlay {
	background: url('../images/photos/thumb3.jpg');
	background-size: cover;
	border-radius: 7px;
}

.card-blog-overlay1 {
	background: url('../images/photos/thumb4.jpg');
	background-size: cover;
	border-radius: 7px;
}

.card-blog-overlay2 {
	background: url('../images/photos/22.jpg');
	background-size: cover;
	border-radius: 7px;
}

.card .card {
	box-shadow: none;
}

@media print {
	.card {
		box-shadow: none;
		border: none;
	}
}

.card-body {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	margin: 0;
	padding: 20px;
	position: relative;

	+.card-body {
		border-block-start: 1px solid rgba(107, 122, 144, 0.3);
	}

	> :last-child {
		margin-block-end: 0;
	}
}

@media print {
	.card-body {
		padding: 0;
	}
}

.card-body-scrollable {
	overflow: auto;
}

.card-footer,
.card-bottom {
	padding: 1rem 1.5rem;
	background: none;
}

.card-header {
	background: none;
	padding: 20px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	border-start-start-radius: 4px !important;
	border-start-end-radius: 4px !important;
	border-block-end: 1px solid $border;

	> .card-title {
		margin-block-end: 0px;
	}

	&.border-0+.card-body {
		padding-block-start: 0;
	}
}

@media print {
	.card-header {
		display: none;
	}
}

.card-title {
	font-weight: 600;
	font-size: 1rem;
	line-height: 1;
	margin-block-end: 5px;
	text-transform: capitalize;

	a {
		color: inherit;
	}

	&:only-child {
		margin-block-end: 0;
	}

	small {
		color: #757b88;
		font-size: 12px;
		display: block;
		margin: -.7rem 0 1rem;
		line-height: 1.1;
		font-weight: 400;
		margin-block-end: 0;
		text-transform: uppercase;
	}
}

.card-subtitle {
	color: #757b88;
	font-size: 10px;
	display: block;
	margin: -.7rem 0 1rem;
	line-height: 1.1;
	font-weight: 500;
	margin-block-end: 0;
	text-transform: uppercase;
}

.card-table {
	margin-block-end: 0;

	tr {

		td:first-child,
		th:first-child {
			padding-inline-start: 1.5rem;
		}

		td:last-child,
		th:last-child {
			padding-inline-end: 1.5rem;
		}
	}
}

.card-body+.card-table {
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.card-profile .card-header {
	height: 5rem;
	background-size: cover;
	border: 0;
}

.profie-img .flex-md-row img {
	width: 60px;
}

.card-profile-img {
	max-width: 6rem;
	margin-block-end: 1rem;
	border: 3px solid $white;
	border-radius: 100%;
	box-shadow: 0 1px 1px $black-1;
}

.card-body+.card-list-group {
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.card-list-group .list-group-item {
	border-inline: 0;
	border-radius: 0;
	padding-inline: 1.5rem;

	&:last-child {
		border-block-end: 0;
	}

	&:first-child {
		border-block-start: 0;
	}
}

.card-header-tabs .nav-item {
	margin-block-end: 1px;
}

.card-aside {
	-ms-flex-direction: row;
	flex-direction: row;
}

.card-aside-column {
	min-width: 5rem;
	width: 30%;
	-ms-flex: 0 0 30%;
	flex: 0 0 30%;
	background: no-repeat center/cover;
}

.card-value {
	font-size: 1rem;
	line-height: 3.4rem;
	height: 3.4rem;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 400;

	i {
		vertical-align: middle;
	}
}

.card-chart-bg {
	height: 4rem;
	margin-block-start: -1rem;
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.card-options {
	margin-inline-start: auto;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-order: 100;
	order: 100;
	margin-inline-end: -.5rem;
	color: #cbd2dc;
	-ms-flex-item-align: center;
	align-self: center;

	a:not(.btn) {
		&:hover {
			text-decoration: none;
			color: #6e7687;
		}

		i {
			font-size: 1rem;
			vertical-align: middle;
		}
	}

	.dropdown-toggle:after {
		display: none;
	}
}

.stretch-card {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: stretch;
	justify-content: stretch;

	>.card {
		width: 100%;
		min-width: 100%;
	}
}

.card-img-absolute {
	position: absolute;
	inset-block-start: 0;
	inset-inline-end: 0;
	height: 100%;
}

.card-collapsed {
	> :not(.card-header):not(.card-status) {
		display: none;
	}

	.card-options-collapse i:before {
		content: '\e92d';
	}
}

.card-fullscreen {
	.card-options-fullscreen i:before {
		content: '\e992';
	}

	.card-options-remove {
		display: none;
	}
}

.card-map {
	height: 15rem;
	background: #e9ecef;
}

.card-map-placeholder {
	background: no-repeat center;
}

.card-tabs {
	display: -ms-flexbox;
	display: flex;
}

.card-tabs-bottom .card-tabs-item {
	border: 0;

	&.active {
		border-top-color: $white;
	}
}

.card-tabs-item {
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: block;
	padding: 1.4rem 1.5rem;
	color: inherit;
	overflow: hidden;
}

a.card-tabs-item {
	background: #fafbfc;

	&:hover {
		text-decoration: none;
		color: inherit;
	}

	&:focus {
		z-index: 1;
	}

	&.active {
		background: $white;
		border-bottom-color: $white;
	}
}

.card-status {
	position: absolute;
	inset-block-start: -1px;
	inset-inline: 0px;
	height: 3px;
	border-start-start-radius: 7px;
	border-start-end-radius: 7px;
	border-end-end-radius: 0;
	border-end-start-radius: 0;
	background: rgba(0, 40, 100, 0.12);
}

.card-status-left {
	inset-inline-end: auto;
	inset-block-end: 0;
	height: auto;
	width: 3px;
	border-start-start-radius: 3px;
	border-start-end-radius: 0;
	border-end-end-radius: 0;
	border-end-start-radius: 3px;
}

.card-icon {
	width: 3rem;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: center;
}

.card-fullscreen {
	position: fixed;
	inset: 0;
	z-index: 1058;
	margin: 0;
}

.card-alert {
	border-radius: 0;
	margin-inline: -1px;
	margin-block-start: -1px;
	margin-block-end: 0;
}

.card-category {
	font-size: 16px;
	padding: 3px;
	text-align: center;
	font-weight: 500;
	letter-spacing: .05em;
	margin-inline: 0;
	margin-block-start: 0;
	margin-block-end: 0.5rem;
	background: #6e84a317;
}

.card-counter i {
	font-size: 2rem;
}

.card-blog-overlay6 {
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: rgba(96, 82, 159, 0.8);
		width: 100%;
		height: 100%;
		inset-inline-end: 0;
		inset-block-start: 0;
	}

	background: url(../images/media/media5.jpg);
	background-size: cover;

	i {
		font-size: 3rem;
	}
}

.card-counter2 i {
	font-size: 4.5em;
}

.card-profile {
	.nav-pills .nav-link {
		font-size: .875rem;
		font-weight: 500;
		padding: .75rem 1rem;
		transition: all .15s ease;
		border: 1px solid $border;
	}

	.avatar {
		font-size: 1rem;
		display: inline-flex;
		width: 48px;
		height: 48px;
		color: $white;
		border-radius: 50%;
		align-items: center;
		justify-content: center;
	}

	.card {
		border: 1px solid rgba(107, 122, 144, 0.3) !important;
	}

	.nav-link {
		display: block !important;
		width: 90%;
	}
}

.card-pricing {
	z-index: 1;

	h1 {
		font-size: 2.5rem !important;
		padding: 15px 30px;
	}
}

.card-pricing2 h1,
.card-pricing3 h1,
.card-pricing4 h1 {
	font-size: 2.5rem !important;
	padding: 15px 0;
}

.card-pricing2 {
	z-index: 1;
	border: 2px solid $info;
}

.card-pricing3 {
	z-index: 1;
	border: 2px solid $warning;
}

.card-pricing4 {
	z-index: 1;
	border: 2px solid $success;
}

.card-pricing .list-unstyled li,
.card-pricing2 .list-unstyled li,
.card-pricing3 .list-unstyled li,
.card-pricing4 .list-unstyled li {
	padding: .5rem 0;
	border-block-end: 0 !important;
}

.card-bgimg {
	background: url(../images/photos/bg.jpg) center center;
	background-size: cover;
	position: relative;
	border-radius: 7px;

	&:before {
		opacity: 0.9;
		border-radius: 7px;
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		inset-inline: 0;
		display: block;
		inset-block-start: 0;
		z-index: 1;
		border-radius: 7px;
	}

	.card-body {
		z-index: 10;
		border-inline-end: 1px solid $white-1;
	}
}

.card-options a:not(.btn) {
    margin-inline-start: 0.5rem;
    display: inline-block;
    min-width: 1rem;
}
/*------ Card -------*/