.header-search {
    min-width: 18rem !important;
}

.header {
    background: $white;
    padding-block-start: .65rem;
    padding-block-end: .65rem;
    border-block-end: 1px solid #eaedf1;
}

@media print {
    .header {
        display: none;
    }
}

.header {
    .dropdown-menu {
        margin-block-start: 0;
    }

    .mega-menu {
        width: 350px;
    }

    .nav-tabs {
        margin: 0;
    }
}

.header-brand {
    .header-brand-img {
        &.logo-3 {
            display: block;
        }

        &.logo-4 {
            display: none;
        }
    }
}

.header-right-icons {
    a {
        padding-block-start: 9px;
    }

    .dropdown-menu a {
        padding: 12px;
    }

    .profile-1 a {
        padding-block-start: 4px;
    }
}

.header-brand {
    color: inherit;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.6rem;
}

.app-header .header-brand {
    color: inherit;
    margin-inline-end: 0;
    font-size: 1.25rem;
    white-space: nowrap;
    font-weight: 600;
    padding: 0;
    transition: .3s opacity;
    line-height: 2.9rem;
    min-width: 200px;
    text-align: center;

    .header-brand-img {
        margin-inline-end: 0;
    }
}

.header-brand:hover {
    color: inherit;
    text-decoration: none;
}

.header-brand-img {
    line-height: 2rem;
    vertical-align: middle;
    width: auto;
    height: 2.5rem;
}

.header-avatar {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    vertical-align: bottom;
    border-radius: 50%;
}

.header-btn {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1rem;
}

@media (max-width:991.98px) {
	.app-header {
		.demo-icon.nav-link.icon {
			margin-block: 0 !important;
		}
	}

	.news-ticker {
		inset-block-start: 73px !important;
	}
}


@media (max-width: 991px) {

    .header .form-inline {
        margin-inline-end: -2px;
    }

    .sidebar-mini.sidenav-toggled {
        .side-header {
            display: none;
        }
    }

    .header.hor-header {
        position: fixed;
        border-block-end: 1px solid $border;
        width: 100%;
        z-index: 999;
        inset-block-start: 0;
    }

    .header.hor-header {
        .header-brand-img {
            &.light-logo {
                display: none;
            }

            &.darklogo {
                display: none;
                margin: 0 auto;
            }

            &.main-logo {
                display: block;
            }
        }

        .header-brand1 {
            width: 100%;
            margin: 0 auto;
            position: absolute;
            inset-inline: 0;
            text-align: center;
            margin-block-start: 0 !important;
        }
    }

    .header-brand-img {
        margin-inline-start: 1.5rem;
        margin: 3px auto;
    }

    .side-header {
        display: none !important;
    }

    .header {
        border-block-end: 1px solid $white-2;
        padding-block: 0.65rem;
    }

    .sidebar-mini .responsive-navbar .navbar-collapse,
    .responsive-navbar .navbar-collapse {
        margin-block-start: 74px !important;
    }

    .responsive-navbar .navbar-collapse {
        padding: 0px 10px;
        position: fixed;
        width: 100%;
        background: $white;
        margin-block-start: 41px;
        z-index: 999;
        box-shadow: 0 12px 11px -3px rgb(104 113 123 / 5%);
        inset-inline: 0;
        inset-block-start: 0;
    }

    .navresponsive-toggler span {
        margin-block-start: 10px;
        margin-block-end: 5px;
        text-align: center;
        position: relative;
        color: #656e81;
    }

    .app-header.header .header-brand-img.darklogo {
        display: none;
    }

    .sidebar-mini .app-header .main-logo {
        display: block;
    }

    .header {
        .header-right-icons .nav-link.icon .header-badge {
            position: absolute;
            inset-block-start: 3px;
            inset-inline-end: 0px;
            display: block !important;
            padding: 3px 5px !important;
            font-size: 10px;
            border-radius: 50px;
            line-height: 1.1;
        }

        // .dropdown.shopping-cart .dropdown-menu {
        //     min-width: 25rem;
        // }
    }

    .responsive-navbar .header-search .input-group {
        position: relative;
    }

    .navbar.responsive-navbar .header-search .form-control {
        line-height: 1.45;
    }

    .responsive-navbar {
        .collapse.show .dropdown-menu.show {
            display: block !important;
        }

        .dropdown-menu-arrow.dropdown-menu-end:before {
            display: none;
        }

        .dropdown-menu.dropdown-menu-start.show,
        .dropdown-menu.dropdown-menu-end.show {
            inset-inline-end: 0 !important;
            inset-block-start: 3.9rem !important;
            border-start-end-radius: 0;
            border-start-start-radius: 0;

            .input-group-text {
                padding: 0.65rem 0.75rem;
            }
        }

        .collapse.show .dropdown-menu.show {
            inset-inline: 3% !important;
        }

        .dropdown {
            position: initial;
        }
    }
}

@media (max-width: 992px) and (min-width: 768px) {
    .animated-arrow {
        cursor: pointer;
        padding-block-start: 25px !important;
        padding-inline-end: 35px !important;
        padding-block-end: 16px !important;
        padding-inline-start: 0 !important;

        margin-block-start: 11px;
        margin-inline-end: 0;
        margin-block-end: 0;
        margin-inline-start: 15px;
    }
}

@media (min-width: 768px) {
    .app-content .side-app {
        padding-block-start: 0px !important;
    }
}

.header {
    .form-inline {
        .form-control {
            border-color: rgba(225, 225, 225, 0.1);
            padding-inline-start: 20px;
            width: 220px;
            padding-inline-end: 40px;
            background: rgba(225, 225, 225, 0.3);
            color: $black  !important;
        }

        .btn {
            border-radius: 0;
            padding-block-start: 5px;
            padding-inline-end: 15px;
            padding-block-end: 6px;
            padding-inline-start: 15px;

            border: 2px solid transparent;
            box-shadow: none;
            border-end-end-radius: 3px;
            border-start-end-radius: 3px;
            background: transparent;
            color: $white;
            margin-inline-start: -56px;
            font-size: 15px;
        }
    }

    .dropdown-menu {
        padding: 0;
        border: 0;
    }
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .header .form-inline .search-element {
        position: absolute;
        inset-block-start: 8px;
        inset-inline: 10px;
        z-index: 892;
        display: none;
    }

    body.search-show .header .form-inline .search-element {
        display: block;
    }

    .header .navsearch i {
        font-size: 20px;
        color: $white;
    }

    .search-element .form-control {
        float: left;
        width: 100% !important;
        height: 40px;
        margin: 0 auto;
        background: $white  !important;
        color: $black;
    }

    .search-show .search-element {
        display: block;
        inset-inline: 0 !important;
        inset-block-start: 0 !important;
        width: 100%;
        padding: 10px 13px;
    }

    .header {
        .form-inline .form-control::-webkit-input-placeholder {
            color: #76839a;
        }

        .navsearch i {
            font-size: 20px;
            color: $white;
            padding-block-start: 5px;
        }

        .form-inline .btn {
            color: #46494a !important;
            padding-block-start: 8px;
            padding-inline-end: 15px;
            padding-block-end: 6px;
            padding-inline-start: 15px;
        }
    }
}

.animated-arrow span {

    &:before,
    &:after {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .animated-arrow span {

        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }

    .animated-arrow span {

        &:before,
        &:after {
            cursor: pointer;
            height: 2px;
            width: 17px;
            background: $default-color;
            position: absolute;
            display: block;
            content: '';
        }
    }
}

.header-style .header-brand {
    margin-inline-start: 0 !important;
}

@media only screen and (max-width: 991px) {

    .app-header {
        box-shadow: none !important;
        position: fixed !important;
    }

    .page {
        position: inherit;
        background: none;
    }
    
    .page-main {
        z-index: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .header .form-inline .form-control {
        width: 200px;
    }
}

.hor-header {
    .header-brand1 {
        color: inherit;
        font-size: 1.25rem;
        white-space: nowrap;
        font-weight: 600;
        padding: 0;
        transition: .3s opacity;
        line-height: 2.8rem;
        margin-block-start: 2px;
    }
}

.header-right-icons {
    .nav-link.icon:hover {
        background: none;
        border-radius: 50px;
    }

    .profile-user:hover {
        box-shadow: none;
    }
}

@media (max-width: 480px) {

    .app-header .header-brand,
    .app-header1 .header-brand {
        min-width: auto;
    }

    .header-right-icons .dropdown .header-toggler {
        margin-block-start: 9px;
    }
}


.header {
    .notifications {
        .dropdown-menu {
            min-width: 19rem;
        }
    }

    .dropdown.message {
        .dropdown-menu {
            min-width: 19rem;
        }
    }

    .dropdown.notifications,
    .dropdown.message {
        .dropdown-item {
            padding: 1rem;
        }
    }

    .dropdown-menu {
        box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
        border: 1px solid $border  !important;
        inset-block-start: 3.8rem !important;

        .badge {
            padding: 3px 6px;
        }

        .dropdown-item {
            border-block-end: 1px solid #eaedf1;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .profile-1 {
        .dropdown-menu {
            inset-block-start: 3.5rem !important;
        }
    }

    .dropdown-item {
        color: $default-color;
    }

    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }

        span {
            font-size: 12px;
            color: #76839a;
        }
    }

    .dropdown-menu {
        box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);
        border: 1px solid $border;

        .badge {
            padding: 3px 6px;
        }

        .dropdown-item {
            border-block-end: 1px solid #eaedf1;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .message-menu .wd-90p {
        width: 90%;
    }

    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;

            .dropdown-icon {
                font-size: 16px;
                color: $primary;
                position: relative;
                padding-inline-start: 0.25rem;

                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    inset-block-start: 5px;
                    inset-block-end: 0;
                    inset-inline-end: 3px;
                    border-radius: 50%;
                    background: rgba($primary, 0);
                }
            }
        }
    }
}

.responsive-navbar {

    .notifications-menu,
    .message-menu {
        h5 {
            font-size: 13.5px;
            color: $default-color;
            font-weight: 500;
        }

        span {
            font-size: 12px;
            color: #76839a;
        }
    }

    .dropdown-menu {
        box-shadow: 0 4px 25px 0 rgb(168 180 208 / 30%);
        border: 1px solid $border;

        .badge {
            padding: 4px 8px;
        }

        .dropdown-item {
            border-block-end: 1px solid #eaedf1;

            &:last-child {
                border-block-end: 0;
            }
        }
    }

    .message-menu .wd-90p {
        width: 90%;
    }

    .profile-1 {
        .dropdown-item {
            padding: .75rem 1.75rem !important;

            .dropdown-icon {
                font-size: 16px;
                color: $primary;
                position: relative;

                &::after {
                    content: '';
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    inset-block-start: 5px;
                    inset-block-end: 0;
                    inset-inline-end: 3px;
                    border-radius: 50%;
                    background: rgba($primary, 0);
                }
            }
        }
    }
}

.dropdown .avatar.profile-user {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
}

@media only screen and (max-width: 460px) {
    .profile-cover__img .profile-img-1>img {
        margin-inline-start: -29px;
    }
}

.app-header,
.hor-header {
    .responsive-navbar {

        .notifications-menu,
        .message-menu {

            .dropdown-item h5,
            .dropdown-item span {
                white-space: normal !important;
            }
        }

        .notifications-menu .wd-80p {
            width: 80%;
        }
    }

    .profile-1 {
        .dropdown-item {
            white-space: normal;
        }
    }
}

.header {
    .header-dropdown-list {
        height: 300px;
    }

    .notifications-menu {
        height: 320px;
    }

    .message-menu-scroll {
        height: 290px;
    }
}

.dropdown-footer {
    padding: 10px 15px;
    border-start-start-radius: 0;
    border-start-end-radius: 0;
    border-end-end-radius: 10px;
    border-end-start-radius: 10px;
}

.shopping-cart,
.notifications,
.message {
    .ps__rail-y {
        height: 234px !important;
    }

    .ps__thumb-y {
        height: 118px !important;
        background-color: transparent;
    }

    .ps__rail-y:hover>.ps__thumb-y,
    .ps__rail-y:focus>.ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: transparent;
    }

    .header-dropdown-list {
        .wd-50p {
            width: 50% !important;
        }
    }
}


@media (min-width: 1165px) {
    .main-header-center .form-control {
        width: 275px;
    }
}

@media (min-width: 992px) {

    .header .main-header-center {
        position: relative;
        padding-inline-start: 0px;
        align-items: center;
    }

    .main-header-center {
        .form-control {
            height: 40px;
            border-radius: 4px;
            background: $white;
            border: 1px solid $border;
            padding: 1rem 1.03rem;

            &::placeholder {
                color: #e4e4e6;
            }
        }

        .btn {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 5px;
            background-color: transparent;
            height: 40px;
            color: #b4bdce;
            transition: none;
            font-size: 16px;
        }
    }

    .app-header .container-fluid.main-container div:first-child {
        align-items: center !important;
    }

}

.header .main-header-center .dropdown-toggle {
    cursor: auto;
}

@media (min-width:992px) {
    .responsive-navbar {
        display: initial;
        flex-basis: inherit;
        flex-wrap: inherit;
    }

    .responsive-navbar .navbar-collapse {
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        background: transparent;
    }



    .header {
        .header-right-icons .nav-link.icon .header-badge {
            position: absolute;
            inset-block-start: -3px;
            inset-inline-end: 0px;
            display: block !important;
            padding: 3px 5px !important;
            font-size: 10px;
            border-radius: 50px;
            line-height: 1.1;
        }

        .dropdown.shopping-cart .dropdown-menu {
            min-width: 25rem;
        }
    }

    .header-right-icons .nav-link.icon {
        margin: 5px;
        font-family: inherit !important;
        color: #656e81 !important;
        padding: 9px !important;
        text-align: center;
        height: 2.5rem;
        font-size: 18px;
        position: relative;
    }
}

.light-layout {
    display: none;
}


.pulse-danger {
    display: block;
    position: absolute;
    inset-block-start: 7px;
    inset-inline-end: 8px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #ee335e;
}

.pulse-danger:before {
    content: "";
    inset-block-start: -4px;
    inset-inline-end: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(238, 51, 94, 0.8);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
    animation: pulse 2s infinite;
}

.pulse {
    display: block;
    position: absolute;
    inset-block-start: 7px;
    inset-inline-end: 8px;
    inset-inline-start: 22px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #22c03c;
}

.pulse:before {
    content: "";
    inset-block-start: -4px;
    inset-inline-end: -4px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    background: rgba(34, 192, 60, 0.6);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
    animation: pulse 1s linear infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

.shopping-cart .dropdown-menu .header-dropdown-list.message-menu .dropdown-item {
	align-items: inherit !important;
	position: relative;
    padding: 1rem;
}

@media (max-width: 360px) {
    .responsive-navbar .theme-layout.nav-link-bg.layout-setting {
        display: none;
    }
}


.app-header .container-fluid.main-container div:first-child {
    align-items: center !important;
}

@media (max-width: 575px) {
    .responsive-navbar .pulse {
        inset-block-start: 11px;
        inset-inline-start: 14px;
    }
}


@media (max-width: 992px) {
    .responsive-navbar {
        .pulse {
            inset-block-start: 11px;
        }

        .pulse-danger {
            inset-block-start: 13px;
            inset-inline-end: 10px;
        }
    }
}

