/*----Pricing Tables----*/

.price {
	.list-group-item {
		border-block-end: 1px solid rgba(250, 250, 250, 0.5);
	}
	.panel-footer {
		border-block-end: 0px;
		background-color: $white;
	}
	&.panel-color>.panel-body {
		background-color: $white;
	}
}
.pt-inner {
	text-align: center;
	.pti-header {
		padding-block-start: 45px;
		padding-inline: 10px;
		padding-block-end: 70px;
		color: $white;
		position: relative;
		border: 1px solid rgba(107, 122, 144, 0.3);
		border-block-end: 0;
		>h2 {
			margin: 0;
			line-height: 100%;
			font-weight: 100;
			font-size: 50px;
			color: $white;
			small {
				letter-spacing: 0;
				vertical-align: top;
				font-size: 16px;
				font-weight: 100;
			}
		}
		.ptih-title {
			background-color: $black-1;
			padding-block-start: 8px;
			padding-inline: 10px;
			padding-block-end: 9px;
			margin: 0 -10px;
			position: absolute;
			width: 100%;
			inset-block-end: 0;
			border-block-end: 0;
			text-transform: uppercase;
		}
	}
	.pti-body .ptib-item {
		&:not(:last-child) {
			border-block-end: 1px solid #eee;
		}
		padding: 15px 0;
		font-weight: 500;
	}
	.pti-footer {
		padding: 20px 0;
		border-block-start: 0 !important;
	}
}
/*----Pricing Tables----*/