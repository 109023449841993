/*------Drop Downs-------*/

.dropup,
.dropright,
.dropdown,
.dropleft {
	position: relative;
}

.dropdown-toggle {
	&::after {
		display: inline-block;
		width: 0;
		height: 0;
		margin-inline-start: 0.255em;
		vertical-align: 0.255em;
		content: "";
		border-block-start: 0.3em solid;
		border-inline: 0.3em solid transparent;
		border-block-end: 0;
	}

	&:empty::after {
		margin-inline-start: 0;
	}
}

.dropdown-menu-end {
	inset-inline-end: 0;
	inset-inline-start: auto;
}

.dropup {
	.dropdown-menu {
		inset-block-start: auto;
		inset-block-end: 100%;
		margin-block-start: 0;
		margin-block-end: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-inline-start: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-block-start: 0;
			border-inline: 0.3em solid transparent;
			border-block-end: 0.3em solid;
			margin-inline-start: 3px;
		}

		&:empty::after {
			margin-inline-start: 0;
		}
	}
}

.dropright {
	.dropdown-menu {
		inset-block-start: 0;
		inset-inline-end: auto;
		inset-inline-start: 100%;
		margin-block-start: 0;
		margin-inline-start: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-inline-start: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-block: 0.3em solid transparent;
			border-inline-end: 0;
			border-inline-start: 0.3em solid;
		}

		&:empty::after {
			margin-inline-start: 0;
		}
	}
}

.dropleft {
	.dropdown-menu {
		inset-block-start: 0;
		inset-inline-end: 100%;
		inset-inline-start: auto;
		margin-block-start: 0;
		margin-inline-end: 0.125rem;
	}

	.dropdown-toggle {
		&::after {
			display: inline-block;
			width: 0;
			height: 0;
			margin-inline-start: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-inline-end: 0.3em solid;
			border-block: 0.3em solid transparent;
		}

		&::before {
			display: none;
			width: 0;
			height: 0;
			margin-inline-end: 0.255em;
			vertical-align: 0.255em;
			content: "";
			border-block: 0.3em solid transparent;
			border-inline-end: 0.3em solid;
		}

		&:empty::after {
			margin-inline-start: 0;
		}
	}
}

.dropdown-menu {

	&[x-placement^="top"],
	&[x-placement^="right"],
	&[x-placement^="bottom"],
	&[x-placement^="left"] {
		inset-inline-end: auto;
		inset-block-end: auto;
	}
}

.dropdown-divider {
	height: 0;
	margin: 0.5rem 0;
	overflow: hidden;
	border-block-start: 1px solid #e4e4e4;
}

.dropdown-item {
	display: block;
	width: 100%;
	padding: 0.5rem 1rem;
	font-size: small;
	clear: both;
	font-weight: 400;
	color: #212529;
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}

.drop-icon-wrap {
	text-align: center;

	li {
		display: block;
	}

	.drop-icon-item {
		display: inline-block;
		padding-block: 10px;
		text-align: center;
		color: #020202;
		text-transform: capitalize;
		width: 50%;
		float: left;
	}
}

.drop-icon-item i {
	font-size: 20px;
	padding-block-end: 5px;
	color: #a7a8c6;
}

.drop-icon-wrap .drop-icon-item {

	&:hover,
	&:focus {
		text-decoration: none;
		background-color: #f9f9f9;
	}
}

.dropdown-item {

	&:hover,
	&:focus {
		color: $primary;
		background-color: #f9fcff;
	}
	&:active {
		background-color: $white !important;
	}
}

.dropdown-item {
	&.active, &:active {
	  color: $white;
	  text-decoration: none;
	  background-color: $primary;
	}
  }

.dropdown-item {
	&.user {
		font-size: 16px;
		padding: 5px;
	}

	&:hover,
	&:focus,
	&.active,
	&:active {
		text-decoration: none;
	}

	&.disabled,
	&:disabled {
		color: $white;
		background-color: transparent;
	}
}

.dropdown-menu.show {
	display: block;
	margin: 0;
	border: 1px solid $border;
	box-shadow: 0 3px 9px 0 rgb(104 113 123 / 10%);
	border-radius: 4px;
}

.dropdown-header {
	display: block;
	padding: 0.5rem 1.5rem;
	margin-block-end: 0;
	font-size: 0.875rem;
	color: $gray;
	white-space: nowrap;
}

.dropdown-item-text {
	display: block;
	padding: 0.25rem 1.5rem;
	color: #212529;
}

.dropdown-toggle-split {
	padding-inline: 0.5625rem;

	&::after {
		margin-inline-start: 0;
	}
}

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
	margin-inline-start: 0;
}

.dropleft .dropdown-toggle-split::before {
	margin-inline-end: 0;
}

.dropdown {
	display: block;
}

.dropdown-menu {
	position: absolute;
	inset-block-start: 100%;
	inset-inline-start: 0;
	z-index: 98;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin-inline: 0;
	margin-block-start: 2px;
	margin-block-end: 0;
	list-style: none;
	font-size: 14px;
	background-color: $white;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;
}

.dropdown-item {
	color: #6e84a3;
}

.dropdown-menu-arrow.dropdown-menu-end {

	&:before,
	&:after {
		inset-inline-start: auto;
		inset-inline-end: 12px;
	}
}

.dropdown-toggle {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;

	&:after {
		vertical-align: 0.155em;
		color: #828994;
	}

	&:empty:after {
		margin-inline-start: 0;
	}
}

.dropdown-icon {
	color: #757b88;
	margin-inline-end: .5rem;
	margin-inline-start: -.5rem;
	width: 1em;
	display: inline-block;
	text-align: center;
	vertical-align: -1px;
}

ul.dropdown-menu li.dropdown ul.dropdown-menu {
	position: absolute !important;
	width: 100% !important;
}

.dropdown-menu {
	>li>a {
		display: block;
		padding: 0.5rem 1.5rem;
		clear: both;
		font-weight: 500;
		line-height: 1.428571429;
		color: #74829c;
		white-space: nowrap;
		font-size: 13px;

		&:hover,
		&:focus {
			text-decoration: none;
			background-color: $primary-01;
		}
	}

	.divider {
		height: 1px;
		margin: 9px 0;
		overflow: hidden;
		background-color: rgba(107, 122, 144, 0.3);
	}

	.dropdown-plus-title {
		width: 100% !important;
		color: #6b6f80 !important;
		padding: 6px 12px !important;
		font-weight: 500 !important;
		border: 0 solid #d4cfe3 !important;
		border-block-end-width: 1px !important;
		cursor: pointer !important;
	}
}

ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
	padding-block: 10px !important;
	line-height: 20px !important;
}

.dropdown-menu-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 15px;
	border-block-end: 1px solid rgba(107, 122, 144, 0.3);
}

.dropdown-demo .dropdown-menu {
	position: static;
	float: none;
}

.dropdown-menu-header label {
	margin-block-end: 0;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #6b6f80;
}

.dropdown-media-list {
	.media {

		&:hover,
		&:focus {
			background-color: #f8f9fa;
		}

		padding: 12px 15px;
	}

	img {
		border-radius: 100%;
	}

	.media-body {
		margin-inline-start: 15px;

		> {
			div {
				display: flex;
				align-items: center;
				justify-content: space-between;

				p {
					margin-block-end: 0;
					font-weight: 500;
					color: #6b6f80;
					font-size: 14px;
				}

				span {
					font-size: 12px;
				}
			}

			p {
				font-size: 14px;
				margin-block-end: 0;
			}
		}
	}
}

.dropdown-list-footer {
	padding: 10px 15px;
	text-align: center;
	font-size: 12px;
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.dropdown-media-list {
	padding: 0;
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu .dropdown-plus-title {
		padding-block-start: 15px !important;
	}
}

@media (min-width: 768px) {
	ul.dropdown-menu li.dropdown ul.dropdown-menu {
		width: auto !important;
	}
}

.drop-icon-wrap .drop-icon-item .drop-font {
	font-size: 12px;
}

.dropdown-toggle {
	&.btn-primary, &.btn-secondary, &.btn-warning, &.btn-success, &.btn-info, &.btn-danger, &.btn-warning, &.btn-facebook, &.btn-twitter, &.btn-youtube, &.btn-github, &.btn-instagram, &.btn-vimeo ,&.btn-google {
		&:after {
			color: $white !important;
		}
	}
}

/*------Drop Downs-------*/