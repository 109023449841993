/*-----Footer -----*/

.aside-footer {
	padding: 1rem 1.5rem;
	border-block-start: 1px solid rgba(107, 122, 144, 0.3);
}

.footer {
	border-block-start: 1px solid $border;
	font-size: 0.875rem;
	padding: 1.25rem 0;
	background-color: $white;
	box-shadow: 0 2px 10px rgba(168, 180, 208, .1);

	.social {
		ul li {
			float: left;
			padding: 7px;
		}

		margin: 0 auto;
	}
}

@media print {
	.footer {
		display: none;
	}
}

.top-footer {
	width: 100%;
	line-height: 1.25rem;
	opacity: 1;
	transform: translate(0px, 0px);
	font-size: 14px;
	flex: 0 0 auto;

	h6 {
		font-size: 14px;
		margin-block-end: 1rem;
		font-weight: 600;
	}

	p {
		color: #aab0b9;
	}

	a {
		color: #aab0b9;
		line-height: 2;
	}

	p:hover {
		color: $primary;
	}

	a address {
		color: #aab0b9;
		margin-block-end: 0;
		line-height: 2;

		&:hover {
			color: $primary;
		}
	}

	img {
		width: 80px;
		border: 1px solid $border;
		padding: 2px;
		height: 80px;

		&:hover {
			color: $border;
			opacity: .6;
		}
	}

	a:hover {
		color: $primary;
	}

	.list-unstyled li {
		border-block-end: 0 !important;
	}
}

.footer-payments {
	padding-inline-start: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;

	li {
		&:not(:last-child) {
			margin-inline-end: 1.5rem;
		}

		display: inline-block;
	}

	a {
		color: #aab0b9;
		text-decoration: none;
		font-size: 2rem;

		&:hover {
			color: $primary;
			opacity: .9;
		}
	}

}

.main-footer {
	padding-block: 20px;
	padding-inline-end: 30px;
	padding-inline-start: 280px;
	font-size: 14px;
	font-weight: 400;
	background-color: $white;
	border-block-start: 1px solid #e6eaea;
}

.footer-social-list {
	li {
		&:not(:last-child) {
			margin-inline-end: 1.5rem;
		}

		display: inline-block;
	}

	a {
		color: #aab0b9;
		text-decoration: none;
		font-size: 1rem;

		&:hover {
			color: $primary;
		}
	}

	padding-inline-start: 0;
	list-style: none;
	margin: 0;
	line-height: 1.25rem;
}

@media (max-width: 992px) {
	.footer-social-list {
		text-align: center;
		margin-block-start: 1rem;
	}

	.footer1 {
		text-align: center;
	}
}

/*-----Footer -----*/